import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';
import { IInputProps } from './input.types';

export const StyledInput = styled.input<IInputProps>`
  width: 100%;
  background-color: ${palette.white};
  font-size: ${px2Rem(16)};
  color: ${palette.charcoal[500]};
  border: solid 1px ${palette.charcoal[300]};
  padding: ${px2Rem(18)} ${px2Rem(18)} ${px2Rem(18)} ${px2Rem(12)};

  ::placeholder {
    color: ${palette.charcoal[400]};
    font-size: ${px2Rem(14)};
  }
  &:focus {
    border-color: ${palette.blue[500]};
    box-shadow: 0 0 0 ${px2Rem(1)} ${palette.blue[500]};
    outline: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ errorMessage }) =>
    errorMessage &&
    `
    border-color: ${palette.error[500]};
    box-shadow: 0 0 0 ${px2Rem(1)} ${palette.error[500]};
    `}
  ${({ prefix }) => prefix && `text-indent: ${px2Rem(10)};`}
`;

export const StyledPrefixSpan = styled.span`
  width: 0;
  z-index: 2;
  float: left;
  position: relative;
  top: ${px2Rem(19)};
  left: ${px2Rem(10)};
  color: ${palette.charcoal[500]};
  font-size: ${px2Rem(16)};
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  margin: 10px 0;
`;

export const StyledLabel = styled.label`
  font-size: ${px2Rem(16)};
  color: ${palette.charcoal[500]};
  margin-bottom: ${px2Rem(6)};
  font-weight: 500;
`;
