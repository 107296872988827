import { useEffect, useState } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import { CHECKOUT_LEASE_TERM_QUERY } from 'screens/Checkout/graphQL/queries';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@apollo/client';
import { getFormattedCostSummaries } from 'utils/getFormattedCostSummaries';
import { MU_SWAP_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { IUseCheckoutLeaseTerms } from './types';

const useCheckoutLeaseTerms = ({
  onSuccess,
  checkoutToken,
}: IUseCheckoutLeaseTerms) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [merchantName, setMerchantName] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [productName, setProductName] = useState('');
  const [variantId, setVariantId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [costSummaries, setCostSummaries] = useState<any>([]);
  const [initialCostSummaryId, setInitialCostSummaryId] = useState<
    string | undefined
  >();
  const [costSummaryId, setCostSummaryId] = useState('');
  const [formattedMonthlyPrice, setFormattedMonthlyPrice] =
    useState<string>('');
  const [itemId, setItemId] = useState<string | undefined>();
  const [merchantFailureUrl, setMerchantFailureUrl] = useState<
    string | undefined
  >();

  const {
    loading,
    error: checkoutLeaseTermQueryError,
    data,
  } = useRayloQuery(CHECKOUT_LEASE_TERM_QUERY, {
    variables: { token: checkoutToken },
    skip: !checkoutToken,
  });

  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          swapCheckoutItem: { errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        Sentry.captureMessage(errors?.[0]?.message);
      } else {
        onSuccess();
      }
    },
  });

  useEffect(() => {
    if (data?.checkout) {
      const { checkout } = data;
      const { items = [] } = checkout;
      const item = items[0] || {};
      const { displayName: merchant, id: checkoutMerchantId } =
        checkout.merchant || {};
      const { displayName: title, images = [], id = '' } = item.variant || {};
      const { url } = images[0] || {};
      const initialPlanId = item.costSummary?.id;
      const { availableCostSummaries = [] } = item;

      setInitialCostSummaryId(initialPlanId);
      setItemId(item.id);
      setProductName(title);
      setImageUrl(`${url}?trim=color&fm=webp`);
      setVariantId(id);
      setMerchantName(merchant);
      setMerchantId(checkoutMerchantId);
      setCostSummaryId(initialPlanId);
      setMerchantFailureUrl(checkout.redirectUrl);

      const plansWithNoInsurance = availableCostSummaries.filter(
        (p: any) => !p.includesInsurance,
      );
      const formattedCostSummariesWithoutInsurance =
        getFormattedCostSummaries(plansWithNoInsurance);
      setCostSummaries(formattedCostSummariesWithoutInsurance);
    }
  }, [data]);

  useEffect(() => {
    if (costSummaryId && costSummaries) {
      const price = costSummaries.find(
        ({ id }: { id: string }) => costSummaryId === id,
      )?.formattedMonthlyPrice;
      setFormattedMonthlyPrice(price);
    }
  }, [costSummaryId, costSummaries]);

  const handleCostSummarySelect: any = (id: string) => {
    setCostSummaryId(id);
  };

  const handlePageSubmit = () => {
    if (initialCostSummaryId === costSummaryId) {
      onSuccess();
    } else {
      swapCheckoutItem({
        variables: {
          merchantId,
          variantId,
          costSummaryId,
          checkoutToken,
          itemId,
        },
      });
    }
  };

  return {
    itemId,
    productName,
    variantId,
    imageUrl,
    merchantName,
    costSummaries,
    costSummaryId,
    loading,
    formattedMonthlyPrice,
    checkoutLeaseTermQueryError,
    onSuccess,
    formSubmitted,
    merchantFailureUrl,
    setItemId,
    setFormSubmitted,
    setProductName,
    setVariantId,
    setImageUrl,
    handleCostSummarySelect,
    handlePageSubmit,
  };
};

const [CheckoutLeaseTermsProvider, useCheckoutLeaseTermsContext] = constate(
  useCheckoutLeaseTerms,
);
export { CheckoutLeaseTermsProvider, useCheckoutLeaseTermsContext };
