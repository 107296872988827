import { formattedMonthlyPriceSubCopy } from 'components/RadioButtonBoxGroup/RadioButtonBoxGroup';

interface IFormattedCostSummary {
  id: string;
  formattedMonthlyPrice: string;
  termLength: number;
}

export const getFormattedCostSummaries = (
  costSummaries: [],
): IFormattedCostSummary[] => {
  const planData = costSummaries.map((plan: any) => {
    const formattedMonthlyPrice: string =
      plan?.recurring?.totalAmount?.valueAfterTax?.formattedValue;
    return {
      id: plan.id,
      formattedMonthlyPrice,
      termLength: plan.termLength,
      label:
        plan.termLength === 1
          ? 'Monthly rolling'
          : `${plan.termLength} months lease`,
      formattedMonthlyPriceSubCopy:
        plan.termLength === 1 ? formattedMonthlyPriceSubCopy : undefined,
    };
  });

  planData.sort((a, b) => a.termLength - b.termLength); // Sort by termLength in ascending order

  return planData;
};
