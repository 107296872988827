import { px2Rem } from './../../utils/px2Rem';
import { palette } from './../../colors';
import styled from 'styled-components';
import { IRadioBox, IRadioButtonBox } from './RadioButtonBox.types';
import { CSS_FONT_WEIGHT } from '../../constants';

export const StyledRadioDisplayBox = styled.div<IRadioBox>`
  ${({
    active,
    medium,
    large,
    borderColor,
    activeBorderColor,
    textColor,
    hoverBorderColor,
    optionUnavailable,
    isColumn,
    textAlignment,
  }) => `
    cursor: pointer;
    border: ${px2Rem(1)} solid ${borderColor ?? palette.charcoal[300]};
    display: flex;
    height: ${large ? px2Rem(85) : medium ? px2Rem(80) : px2Rem(68)};
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: ${px2Rem(20)};
    font-size: ${px2Rem(14)};
    border-radius: ${px2Rem(4)};
    color: ${textColor ?? palette.charcoal[500]};
    &:hover {
      border-color: ${hoverBorderColor ?? palette.charcoal[400]};
    };

    ${
      textAlignment &&
      `
      text-align: ${textAlignment};
    `
    };

    ${
      isColumn &&
      `
      p {
        text-align: center;
      };
    `
    };

    & h6 {
      padding: 0;
      margin: 0;
    };

    ${
      optionUnavailable &&
      `
      background: linear-gradient(to bottom right, transparent calc(50% - 1px),  ${palette.charcoal[200]} calc(50% - 1px), ${palette.charcoal[200]} 50%, transparent 50%);
      border: ${px2Rem(1)} solid ${palette.charcoal[200]};
    `
    };

    ${
      active &&
      `
      border: ${px2Rem(2)} solid ${activeBorderColor ?? palette.blue[500]};
      padding: ${px2Rem(18)};
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
      &:hover {
        border-color: ${activeBorderColor ?? palette.blue[500]};
      };
    `
    };
  `}
`;

export const StyledRadioDisplayInner = styled.div<{ $layout: IRadioButtonBox['layout'] }>`
  ${({ $layout }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: ${px2Rem($layout === 'column' ? 4 : 8)};
    ${$layout === 'column' && 'flex-direction: column;'}
  `}
`;

export const StyledRadioDisplayLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2Rem(4)};
`;

export const StyledRadioDisplayMeta = styled.div<{ $layout: IRadioButtonBox['layout'] }>`
  ${({ $layout }) => `
    display: flex;
    flex-direction: column;
    align-items: ${$layout === 'column' ? 'center' : 'flex-end'};
    ${$layout === 'column' && `gap: ${px2Rem(4)};`}
  `}
`;

export const StyledRadioDisplayPrice = styled.div`
  display: flex;
  align-items: flex-end;
`;
