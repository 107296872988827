import React, { useState, useEffect } from 'react';

// NAVIGATION
import { Modal } from 'components/common/Modal';

import { AccountFooter } from 'components/Account/AccountFooter';
import { PaymentSummary } from 'components/Account/PaymentSummary';

import useRayloQuery from 'utils/useRayloQuery';

import { Helmet } from 'react-helmet';
import { LeaseStatus } from 'components/Account/LeaseStatus';
import { useParams } from 'react-router';

import Container from 'elements/Container';
import { secondaryColors } from 'styles/variables/colors';
import ReferAFriend from 'components/ReferAFriend';
import { px2Rem } from 'utils/px2Rem';
import DeviceLeaseProfileCard from 'components/DeviceLeaseProfileCard';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import InsuranceBanner from 'components/InsuranceBanner';
import { DirectDebitSummary } from 'components/Account/DirectDebitSummary';
import BackArrowLink from 'components/BackArrowLink';
import { YourTechStatus } from '../YourTechStatus';
import { track } from 'integrations/segment/events';
import {
  IParseOrder,
  parseOrder,
} from 'screens/Account/utils/parseOrder.helper';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import {
  GET_CUSTOMER_DEVICE,
  IGetCustomerDeviceOrder,
} from './graphql/deviceProfile.graphql';
import { Copy } from '@raylo-tech/raylopay-ui';

export const DeviceProfile = () => {
  const { orderId } = useParams() as unknown as { orderId: string };

  const [parsedOrder, setParsedOrder] = useState<
    null | IParseOrder | undefined
  >(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  const {
    data: { customer },
    loading,
  } = useRayloQuery(GET_CUSTOMER_DEVICE);

  const isInArrears = customer?.orders.some((order: IGetCustomerDeviceOrder) =>
    checkInArrears(order.items[0].subscription),
  );

  useEffect(() => {
    if (customer && customer.orders) {
      const order = customer.orders.find(
        (order: IGetCustomerDeviceOrder) => order.id === orderId,
      );
      if (!order) return;

      setParsedOrder(parseOrder(order) as unknown as IParseOrder);
      setSubscriptionId(order?.items[0]?.subscription?.id);
    }
  }, [customer, orderId]);

  useEffect(() => {
    if (parsedOrder && !trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'lease details',
        in_arrears:
          parsedOrder.status === YourTechStatus.arrears ? 'Yes' : 'No',
        when: 'on page load',
        url: window.location.href,
      });

      setTrackedAccountViewedEvent(true);
    }
  }, [parsedOrder, trackedAccountViewedEvent]);

  if (loading || !parsedOrder) {
    return <Modal dataTestId="loading-modal" visible text="Loading..." />;
  }

  const DeviceDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding={isMobile ? '16px' : '0'}
      dataTestId="device-profile"
    >
      <Container
        maxWidth={px2Rem(1120)}
        padding="0"
        styles={{
          alignSelf: 'center',
        }}
      >
        <Spacer height={24} />
        <BackArrowLink text="Account overview" linkLocation="/account" />
        <Spacer height={24} />
        <Container alignCenter padding="0">
          <Header level={2} fontSize={isMobile ? 18 : 24}>
            {parsedOrder.model}
          </Header>
        </Container>
        <Spacer height={24} />
        <DeviceLeaseProfileCard
          dataTestId="device-profile-card"
          parsedOrder={parsedOrder}
          subscriptionId={subscriptionId}
          hasDeviceInArrears={isInArrears}
        />
      </Container>
    </Container>
  );

  const InsuranceDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
      dataTestId="insurance-details"
    >
      <Spacer height={48} />
      <Container alignCenter padding="0">
        <Header level={2} fontSize={isMobile ? 18 : 24}>
          Insurance
        </Header>
      </Container>
      <Spacer height={24} />
      <Container
        maxWidth={px2Rem(1120)}
        padding="0"
        styles={{
          alignSelf: 'center',
        }}
      >
        <InsuranceBanner orderId={orderId} />
      </Container>
    </Container>
  );

  const PaymentInfo = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
      dataTestId="payment-info"
    >
      <Spacer height={24} />
      <div style={{ margin: px2Rem(24), textAlign: 'center' }}>
        <Header level={2} fontSize={isMobile ? 18 : 24}>
          Payment Overview
        </Header>
        <Spacer height={8} />
        <Copy fontSize={14}>
          Failed direct debits can take one working day to show. Refunds due
          will be paid automatically within 5 working days.
        </Copy>
      </div>
      <Container
        maxWidth={px2Rem(1120)}
        boxShadow={!isMobile}
        padding="0"
        styles={{
          alignSelf: 'center',
        }}
      >
        <PaymentSummary subscription={parsedOrder.subscription} />
      </Container>
      {!isMobile && <Spacer height={24} />}
    </Container>
  );

  const DirectDebitDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
      dataTestId="direct-debit-details"
    >
      <Spacer height={48} />
      <Container alignCenter padding="0">
        <Header level={2} fontSize={isMobile ? 18 : 24}>
          Direct Debit details
        </Header>
      </Container>
      <Spacer height={24} />
      <Container
        maxWidth={px2Rem(1120)}
        boxShadow={!isMobile}
        padding="0"
        styles={{
          alignSelf: 'center',
        }}
      >
        <DirectDebitSummary subscription={parsedOrder.subscription} />
      </Container>
      {!isMobile && <Spacer height={24} />}
    </Container>
  );

  return (
    <>
      <Helmet>
        <title>Overview - Raylo</title>
      </Helmet>
      <LeaseStatus
        key={parsedOrder.id}
        customer={customer}
        order={parsedOrder}
        isInArrears={isInArrears}
      />
      <DeviceDetails />
      {parsedOrder.insurance && <InsuranceDetails />}
      {parsedOrder.subscription && <PaymentInfo />}
      {parsedOrder.subscription && <DirectDebitDetails />}
      <ReferAFriend dataTestId="refer-a-friend" />
      <AccountFooter />
    </>
  );
};
