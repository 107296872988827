import { IStepInsuranceContent } from './types';
import {
  Button,
  RayloModal,
  SectionMessageBase,
  SectionMessageWithIcon,
  BulletList,
  TextLink,
  RadioSelectBorderBox,
  Typography,
} from '@raylo-tech/raylopay-ui';
import { primaryColors } from 'styles/variables/colors';
import { useState } from 'react';
import { Icon } from 'elements';
import { px2Rem } from 'utils/px2Rem';
import 'styles/modal.css';

const bulletItems = ['Loss & theft', 'Accidental damage', 'Liquid damage'];

export const StepInsuranceContent = ({
  onRadioSelect,
  insuranceTotal,
  onSubmit,
  onShowPolicyInfo,
  merchantPrice,
}: IStepInsuranceContent) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [insuranceSelected, setInsuranceSelected] = useState<boolean | null>(
    null,
  );

  const handleInsuranceChange = (value: boolean) => {
    setInsuranceSelected(value);
    onRadioSelect?.(value);
  };

  const handleNext = () => {
    if (insuranceSelected) {
      onSubmit();
      return;
    }
    setModalOpen(true);
  };

  return (
    <div
      data-testid="step-insurance-updated"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: px2Rem(32),
      }}
    >
      <Typography variant="h2" bold color="charcoal.500">
        What would you do if your device was{' '}
        <Typography
          variant="h2"
          element="em"
          color="charcoal.400"
          bold
          style={{ fontStyle: 'normal' }}
        >
          damaged, lost or stolen?
        </Typography>
      </Typography>
      <Typography bold color="charcoal.500">
        Adding protection now could help save you money later.
      </Typography>
      <RadioSelectBorderBox
        dataTestId="with-insurance"
        active={insuranceSelected === true}
        onClick={() => handleInsuranceChange(true)}
        label="Recommended"
        header={
          <div>
            <Typography variant="h4" bold element="p">
              Raylo insurance
            </Typography>
            <Typography color="charcoal.400">
              only{' '}
              <Typography color="success.500" bold element="span">
                £{insuranceTotal}/mo
              </Typography>
            </Typography>
          </div>
        }
        body={
          <>
            <BulletList
              items={bulletItems}
              bulletIcon="Success"
              paddingRight={12}
              marginBottom={12}
            />
            <div style={{ width: 'fit-content', paddingTop: px2Rem(16) }}>
              <TextLink
                fontSize={14}
                fontColor={primaryColors.b01}
                onClick={onShowPolicyInfo}
                dataTestId="show-policy-info"
              >
                View Policy Summary
              </TextLink>
            </div>
          </>
        }
      />
      <RadioSelectBorderBox
        dataTestId="without-insurance"
        active={insuranceSelected === false}
        onClick={() => handleInsuranceChange(false)}
        header={
          <Typography variant="h4" bold element="p">
            No insurance cover
          </Typography>
        }
        body={
          <div style={{ display: 'flex', gap: px2Rem(12) }}>
            <Icon name="OrangeAlert" size="24px" />
            <Typography variant="body2" color="charcoal.500">
              Without insurance, the current cost to replace the device if lost
              or stolen will be {merchantPrice}.
            </Typography>
          </div>
        }
      />
      <SectionMessageBase
        content={
          <Typography variant="body2" color="charcoal.500">
            Since it&apos;s tied to your Raylo lease, you won&apos;t be able to
            add insurance later.
          </Typography>
        }
      />
      <Button
        onClick={handleNext}
        disabled={insuranceSelected === null}
        dataTestId="insurance-next-button"
      >
        {insuranceSelected ? 'Add insurance' : 'Next'}
      </Button>
      {modalOpen && (
        <RayloModal
          dataTestId="insurance-policy-modal"
          modalOpen={modalOpen}
          title="Are you sure?"
          handleCloseModal={() => setModalOpen(false)}
          primaryButtonText="Back to insurance"
          primaryButtonOnClick={() => {
            setModalOpen(false);
            setInsuranceSelected(null);
          }}
          secondaryButtonText="Continue with no insurance"
          secondaryButtonOnClick={() => {
            setModalOpen(false);
            onSubmit?.();
          }}
        >
          <Typography color="charcoal.500" style={{ marginBottom: px2Rem(24) }}>
            Raylo insurance is great value and hassle-free. Plus, your monthly
            price stays fixed over the term.
          </Typography>
          <SectionMessageWithIcon
            icon="RayloDesignInfo"
            iconSize={16}
            header="Did you know?"
            bulletList={[
              "Most home insurance policies don't cover loss and theft outside your home.",
              'A claim on your home insurance could raise your premium with high excesses.',
            ]}
          />
        </RayloModal>
      )}
    </div>
  );
};
