import React from 'react';

import * as A from 'styles/account';
import * as B from 'styles/buttons';
import pinkBookIcon from 'public/icon--book--pink.svg';

export const PolicyBanner = () => {
  return (
    <A.PolicyBanner>
      <img className="icon" src={pinkBookIcon} alt="Icon of an open book" />
      <h5 className="heading">
        Have a question about Raylo's insurance policy?
      </h5>
      <p className="body">
        You can download the policy summary to see what is covered under Raylo's
        policy.
      </p>
      <a
        href="https://static.raylo.com/Raylo+Insurance+Policy+Summary.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <B.AccountButton>Download Policy Summary</B.AccountButton>
      </a>
    </A.PolicyBanner>
  );
};
