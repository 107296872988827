import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// NAVIGATION
import * as A from 'styles/account';
import { Modal } from 'components/common/Modal';

import { AccountFooter } from 'components/Account/AccountFooter';
import { DirectDebitSummary } from 'components/Account/DirectDebitSummary';
import { PaymentOverview } from '../../../components/Account/PaymentOverview/PaymentOverview';
import useRayloQuery from 'utils/useRayloQuery';
import { Helmet } from 'react-helmet';
import { track } from 'integrations/segment/events';
import { CUSTOMER_ORDERS } from 'screens/Account/Payments/graphql/customerOrder.graphql';
import { Copy, Header, Spacer } from '@raylo-tech/raylopay-ui';
import BackArrowLink from '../../../components/BackArrowLink';

export const Payments = () => {
  const [orderItem, setOrderItem] = useState({});
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);

  const { orderId } = useParams();

  const {
    data: { customer },
    loading,
  } = useRayloQuery(CUSTOMER_ORDERS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (customer && customer.orders) {
      const order = customer.orders.find((order) => order.id === orderId);
      setOrderItem(order.items[0]);
    }
  }, [customer]);

  useEffect(() => {
    if (!trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'payment history',
        when: 'on page load',
        url: window.location.href,
      });

      setTrackedAccountViewedEvent(true);
    }
  });

  if (loading || !orderItem.id) return <Modal visible text="Loading..." />;

  const showPayments = orderItem?.fulfillmentItem?.dispatchedAt;

  return (
    <A.AccountContainer>
      <Helmet>
        <title>Payments - Raylo</title>
      </Helmet>

      <A.PaymentOverviewBanner>
        <BackArrowLink
          linkLocation={`/account/${orderId}`}
          text={orderItem?.variant.product.displayName}
        />
        <Spacer height={24} />
        <Header
          level={1}
          dataTestId={'payment-history-header'}
          fontSize={24}
          centered
        >
          Payment History
        </Header>
        {!showPayments && (
          <>
            <Spacer height={8} />
            <Copy fontSize={14} textAlign="center">
              Failed direct debits can take one working day to show. Refunds due
              will be paid automatically within 5 working days.
            </Copy>
            <p data-testid="device-dispatch-copy" className="body">
              Your payment overview will be available here shortly after your
              phone has been dispatched
            </p>
          </>
        )}
      </A.PaymentOverviewBanner>
      {showPayments && (
        <PaymentOverview
          dataTestId="payments-overview"
          subscription={orderItem?.subscription}
        />
      )}
      <DirectDebitSummary subscription={orderItem?.subscription} />

      <AccountFooter />
    </A.AccountContainer>
  );
};
