import gql from 'graphql-tag';

export const Checkout = {
  fragments: {
    base: gql`
      fragment CheckoutBase on Checkout {
        id
        token
        termsAndConditionsAcceptedAt
        tradeInTermsAndConditionsAcceptedAt
        initialPaymentMethodRequired
        state
        decision {
          id
          outcome
          outcomeReason
        }
      }
    `,
    mutationBase: gql`
      fragment CheckoutMutationBase on Checkout {
        id
        token
        initialPaymentMethodRequired
        items {
          id
          variant {
            id
            displayName
            optionValues {
              id
              displayName
            }
          }
        }
      }
    `,
    checkoutItemCostSummary: gql`
      fragment CheckoutItemCostSummary on CheckoutItem {
        costSummary {
          id
          currencyCode
          includesInsurance
          termLength
          recurringPeriod
          recurring {
            baseAmount {
              valueAfterTax {
                value
                currencyCode
              }
              valueBeforeTax {
                value
                currencyCode
              }
            }
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
              valueBeforeTax {
                value
                currencyCode
              }
            }
            adjustmentAmount {
              valueAfterTax {
                value
                currencyCode
              }
              valueBeforeTax {
                value
                currencyCode
              }
            }
            insuranceAmount {
              valueAfterTax {
                value
                currencyCode
              }
              valueBeforeTax {
                value
                currencyCode
              }
            }
          }
          merchantPrice {
            valueAfterTax {
              formattedValue
            }
            valueBeforeTax {
              formattedValue
            }
          }
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
              valueBeforeTax {
                value
                currencyCode
              }
            }
          }
        }
      }
    `,
    customerInfo: gql`
      fragment CheckoutCustomerInfo on Checkout {
        customerInfo {
          id
          firstName
          lastName
          email
          dateOfBirth
          mobilePhoneNumber
        }
      }
    `,
    address: gql`
      fragment CheckoutAddress on Checkout {
        address {
          id
          line1
          line2
          line3
          city
          region
          postcode
          country {
            isoAlpha2
          }
        }
      }
    `,
    variantCostSummaries: gql`
      fragment VariantCostSummaries on Variant {
        costSummaries(merchantId: $merchantId) {
          id
          includesInsurance
          termLength
          recurringPeriod
          recurring {
            insuranceAmount {
              valueAfterTax {
                value
              }
            }
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
      }
    `,
    variant: gql`
      fragment CheckoutItemVariant on CheckoutItem {
        variant {
          id
          displayName
          condition
          optionValues {
            id
            displayName
            raw
            optionType {
              id
              slug
            }
          }
          product {
            id
            displayName
            preOrder
            releasedAt
            category {
              displayName
              id
            }
            manufacturer {
              id
              displayName
            }
          }
          images {
            alt
            position
            tag
            title
            url
          }
        }
      }
    `,
    paymentInfo: gql`
      fragment CheckoutPaymentInfo on Checkout {
        paymentInfo {
          id
          bankAccountName
          bankAccountNumber
          bankBranchIdentifier
          directDebitMandateAuthorised
          cardDetails {
            expiryMonth
            expiryYear
            funding
            brand
            last4
          }
        }
      }
    `,
    incomplete: gql`
      fragment CheckoutIncomplete on Checkout {
        incompleteAttributes {
          code
          field
          message
        }
      }
    `,
  },
};
