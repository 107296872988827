import React from 'react';
import * as B from 'styles/buttons';

/**
 * @deprecated This component has been replaced with the `<RadioSelect />` component in `@raylo-tech/raylopay-ui`.
 */
export const RadioButton = ({
  type,
  label,
  name,
  id,
  hex = null,
  checked = false,
  value = 0,
  onClick,
  disabled = false,
  outOfStock,
  dataTestId,
}) => (
  <>
    {
      {
        model: (
          <>
            <B.PhoneModelRadioButton data-testid={dataTestId}>
              <input
                type="radio"
                id={id}
                name={type}
                value={name}
                onChange={onClick}
                checked={checked}
                disabled={disabled}
              />
              <label htmlFor={id}>{label}</label>
            </B.PhoneModelRadioButton>
          </>
        ),
        term: (
          <>
            <B.TermRadioButton>
              <input
                type="radio"
                id={id}
                name={type}
                value={name}
                onChange={onClick}
                checked={checked}
                disabled={disabled}
              />
              <label htmlFor={id}>{label}</label>
            </B.TermRadioButton>
          </>
        ),
        condition: (
          <>
            <B.TermRadioButton>
              <input
                type="radio"
                id={id}
                name={type}
                value={name}
                onChange={onClick}
                checked={checked}
                disabled={disabled}
              />
              <label htmlFor={id}>{label}</label>
            </B.TermRadioButton>
          </>
        ),
        storage: (
          <>
            <B.PhoneStorageRadioButton outOfStock={outOfStock}>
              <input
                type="radio"
                id={id}
                name={type}
                value={name}
                onChange={onClick}
                checked={checked}
                disabled={disabled}
              />
              <label htmlFor={id}>{label}</label>
            </B.PhoneStorageRadioButton>
          </>
        ),

        colour: (
          <>
            <B.PhoneColorRadioButton outOfStock={outOfStock} hex={hex}>
              <label htmlFor={id}>
                <input
                  type="radio"
                  id={id}
                  name={type}
                  value={name}
                  onChange={onClick}
                  checked={checked}
                  disabled={disabled}
                />
                <div className="color-dot" id={id}>
                  <span></span>
                </div>
                <span>{label}</span>
              </label>
            </B.PhoneColorRadioButton>
          </>
        ),

        country: (
          <>
            <B.CountrySelectRadioButton>
              <input
                type="radio"
                id={id}
                name={type}
                readOnly
                onClick={onClick}
                checked={checked}
              />
              <label htmlFor={id}>{label}</label>
            </B.CountrySelectRadioButton>
          </>
        ),

        marketing: (
          <>
            <B.MarketingRadioButton>
              <label htmlFor={id}>
                <input
                  type="radio"
                  id={id}
                  name={type}
                  value={value}
                  checked={checked}
                  onChange={onClick}
                />
                <span>{label}</span>
              </label>
            </B.MarketingRadioButton>
          </>
        ),
      }[type]
    }
  </>
);
