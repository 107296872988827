import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { reset } from 'integrations/segment/events';
import { useAnalyticsContext } from 'utils/AnalyticsProvider';
import { AUTH_CONFIG } from 'utils/constants';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { useCallback, useMemo } from 'react';

const AUTH_SCOPE = 'openid profile email';
const AUTH_AUDIENCE = AUTH_CONFIG.audience;

export const useLoginAuthentication = () => {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    isLoading,
    user,
    isAuthenticated,
    error,
  } = useAuth0();
  const { setUserToken, removeUserToken } = useSharedCustomer();
  const { clearAnalyticsId } = useAnalyticsContext();
  const history = useHistory();

  const returnTo = useMemo(() => {
    let result = '/account';

    if (window.location.search) {
      result =
        new URLSearchParams(window.location.search).get('return-to') ?? result;
    }

    return result;
  }, [window.location.search]);

  const auth0Login = useCallback(() => {
    loginWithRedirect({
      audience: AUTH_AUDIENCE,
      scope: AUTH_SCOPE,
      appState: {
        returnTo,
      },
    } as RedirectLoginOptions);
  }, [loginWithRedirect]);

  const getAuth0Token = async (): Promise<void> => {
    const token = await getAccessTokenSilently({
      audience: AUTH_AUDIENCE,
      scope: AUTH_SCOPE,
    } as GetTokenSilentlyOptions);

    setUserToken(token);
    if (window.location.search) {
      history.push(returnTo);
    }
    if (!token) {
      Sentry.captureMessage('Auth0 token not retrieved');
    }
  };

  const auth0Logout = () => {
    removeUserToken();
    clearAnalyticsId();
    reset();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
      force: true,
    } as LogoutOptions);
  };

  return {
    auth0Login,
    getAuth0Token,
    auth0Logout,
    isLoading,
    error,
    user,
    isAuthenticated,
  };
};
