import {
  CheckoutPhoneSummaryQueryQuery,
  CheckoutPhoneSummaryQueryQueryVariables,
} from 'graphql/generated/graphql';
import { buildProductImageUrl, buildProductURL } from 'utils/productUrlHelpers';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useQuery } from '@apollo/client';
import { QL_SUMMARY } from 'screens/Checkout/graphQL/queries';
import { useMerchantContext } from 'utils/useMerchantContext';

type UseCheckoutOrderSummaryDataProps = {
  checkoutToken: string;
  displayOnly?: boolean;
};

export const useCheckoutOrderSummaryData = ({
  checkoutToken,
}: UseCheckoutOrderSummaryDataProps) => {
  const { checkout: checkoutForChangeProduct } = useCheckoutContext();

  const { merchantId } = useMerchantContext();

  const { data, loading } = useQuery<
    CheckoutPhoneSummaryQueryQuery,
    CheckoutPhoneSummaryQueryQueryVariables
  >(QL_SUMMARY, {
    variables: {
      token: checkoutToken,
      merchantId,
    },
    skip: !merchantId,
  });

  if (loading || !data?.checkout) {
    return {
      checkoutHasLoaded: false as const,
    };
  }

  const checkout = data.checkout;
  const checkoutItem = data.checkout.items[0];
  const costSummary = checkoutItem.costSummary;
  const variant = checkoutItem.variant;
  const variantImageUrl = buildProductImageUrl(variant.images?.[0]?.url ?? '');

  const changeProductURL = buildProductURL({
    checkout: checkoutForChangeProduct,
    termLength: costSummary.termLength,
    slug: variant.slug,
  });

  const onChangeProduct = () => {
    if (changeProductURL) {
      window.location.assign(changeProductURL);
    }
  };

  return {
    checkoutHasLoaded: true as const,
    checkout,
    costSummary,
    variant,
    variantImageUrl,
    changeProductURL,
    onChangeProduct,
  };
};
