import { find } from 'lodash-es';

export const getOptionValue = (variant: any, optionType: string) => {
  const option = find(
    variant.optionValues,
    (o) => o.optionType.slug === optionType,
  );

  return option || { id: null, displayName: null };
};
