import {
  Container,
  RayloModal,
  SectionMessageBase,
} from '@raylo-tech/raylopay-ui';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { primaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

interface IReasonsToStay {
  heading: string;
  bulletPoints: string[];
  displayFooter: boolean;
  onNext: () => void;
  onClose: () => void;
}

export const ReasonsToStayModal = ({
  heading,
  bulletPoints,
  displayFooter,
  onNext,
  onClose,
}: IReasonsToStay) => {
  return (
    <RayloModal
      modalOpen={true}
      handleCloseModal={onClose}
      title="Sorry to hear you’re thinking about cancelling."
      primaryButtonText="Yes, I want to cancel"
      primaryButtonOnClick={onNext}
      secondaryButtonText="Don’t cancel"
      secondaryButtonOnClick={onClose}
    >
      <>
        <Copy fontSize={14} lineHeight={18} color={primaryColors.c01}>
          {heading}
        </Copy>
        {bulletPoints?.length > 0 && (
          <>
            <Spacer height={12} />
            <SectionMessageBase
              content={
                <>
                  {bulletPoints.map((copy: string, i: number) => (
                    <Container flexRow key={`point-${i}`}>
                      <Container fitContent padding="2px 12px 0 0">
                        <Icon
                          name="CheckCircleFill"
                          size={px2Rem(20)}
                          color={uiStateColors.success}
                        />
                      </Container>
                      <Container>
                        <Copy
                          fontSize={14}
                          lineHeight={20}
                          color={primaryColors.c01}
                        >
                          {copy}
                        </Copy>
                      </Container>
                    </Container>
                  ))}
                </>
              }
            />
          </>
        )}
        {displayFooter && (
          <>
            <Spacer height={12} />
            <Copy fontSize={14} lineHeight={18} color={primaryColors.c01}>
              Join over 80,000 customers who have switched to Raylo.{' '}
              <a
                href="https://help.raylo.com/en/articles/3271547-contact-raylo"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>{' '}
              if there is anything we can do to help.
            </Copy>
          </>
        )}
      </>
    </RayloModal>
  );
};
