import { useState } from 'react';
import { RayloModal, SectionMessage } from '@raylo-tech/raylopay-ui';
import Copy from 'elements/Copy';
import Container from 'elements/Container';
import Header from 'elements/Header';
import TextLink from 'elements/TextLink';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';

export const AddTechEligibilityInfo = ({ dataTestId }: ITestDataId) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Container dataTestId={dataTestId} alignCenter>
      <Header level={2} fontSize={24}>
        Add more tech
      </Header>
      <Spacer height={12} />
      <Copy>
        You can't add tech right now.{' '}
        <TextLink onClick={openModal}>Find out more</TextLink>
      </Copy>
      <Spacer height={48} />

      <RayloModal
        dataTestId="add-tech-modal"
        modalOpen={modalOpen}
        title="Sorry, you can't add tech right now..."
        handleCloseModal={closeModal}
      >
        <>
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01} bold>
            But don&apos;t worry we&apos;ll let you know as soon as you can.
          </Copy>
          <Spacer height={24} />
          <SectionMessage
            icon="RayloDesignInfo"
            iconSize={16}
            content={
              <Copy fontSize={14} lineHeight={20} color={primaryColors.c01}>
                We take into account how long you have been a customer and your
                payment history. As soon as you are eligible, we&apos;ll notify
                you via SMS, so you won&apos;t miss out on the latest tech.
              </Copy>
            }
          />
        </>
      </RayloModal>
    </Container>
  );
};
