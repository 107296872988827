export enum CheckoutStep {
  STEP_UPGRADE = 'StepUpgrade',
  STEP_ADDRESS = 'StepAddress',
  STEP_BANK = 'StepBank',
  STEP_SIGN = 'StepSign',
  STEP_LEASE = 'StepLease',
  STEP_TRADE_IN = 'StepTradeIn',
  STEP_INSURANCE = 'StepInsurance',
  STEP_NAME_EMAIL = 'StepNameEmail',
  STEP_MOBILE_JOB = 'StepMobileJob',
  STEP_TERMS = 'StepTerms',
  STEP_EMAIL = 'StepEmail',
  STEP_PERSONAL_DETAILS = 'StepPersonalDetails',
  STEP_ADDRESS_CHECK = 'StepAddressCheck',
  STEP_STRIPE = 'StepStripe',

  // BUSINESS SPECIFIC
  STEP_LEASE_TYPE = 'StepLeaseType',
  STEP_BUSINESS_TYPE = 'StepBusinessType',
}
