import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { captureErrorEvent } from 'utils/errorTracking';
import { activateOptimize } from 'utils/optimize';
import { useCookies } from 'react-cookie';

// HOOKS

// GRAPHQL
import { QL_DECISION } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { Loader } from 'components/common/Loader';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { Modal } from 'components/common/Modal';
import { PlainHeader } from 'components/common/PlainHeader';

import useRayloQuery from 'utils/useRayloQuery';
import { useHistory } from 'react-router';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import routes from 'utils/routes';
import { useErrorNavigator } from 'utils/useErrorNavigator';

export const Decision = () => {
  const history = useHistory();
  const errorNav = useErrorNavigator();

  const { checkoutToken } = useCheckoutContext();
  const [cookies, _setCookie, removeCookie] = useCookies(['checkoutMode']);

  const {
    data: { checkout },
    error,
    loading,
    stopPolling,
  } = useRayloQuery(QL_DECISION, {
    variables: {
      checkoutToken,
    },
    pollInterval: 5000,
  });

  useEffect(() => {
    if (
      checkout &&
      checkout.decision &&
      checkout.decision.outcome !== 'PENDING'
    ) {
      stopPolling();
      history.push(routes(checkout));
    }
  }, [checkout]);

  useEffect(() => {
    if (cookies['checkoutMode']) {
      removeCookie('checkoutMode', { path: '/' });
    }
  }, [cookies, removeCookie]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (
          checkout &&
          checkout.decision &&
          checkout.decision.outcome === 'PENDING'
        ) {
          captureErrorEvent('Decision: timeout - force refer');
          history.push('/checkout/decision/referred');
          stopPolling();
        }
      },
      1000 * 3 * 60,
    );
    return () => clearTimeout(timer);
  }, [checkout, history, stopPolling]);

  useEffect(() => {
    activateOptimize();
  });

  if (error) {
    errorNav('Decision: failure', error);
  }

  if (loading) {
    return <Modal visible text="Loading..." />;
  }

  return (
    <div className="checkout--index">
      <Helmet>
        <title>Please Wait - Raylo</title>
      </Helmet>
      <div className="checkout--header">
        <PlainHeader transparent />
      </div>
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <>
              <SlideDownFadeIn>
                <T.RayloBodyHeading margin="20px">
                  <strong>OK. Great.</strong> We’re running our checks now …
                </T.RayloBodyHeading>
              </SlideDownFadeIn>

              <SlideDownFadeIn>
                <T.RayloBodyType style={{ color: '#FFCEC7', marginBottom: 28 }}>
                  This can take up to two minutes
                </T.RayloBodyType>
              </SlideDownFadeIn>

              <FadeIn>
                <Loader type="quad" />
              </FadeIn>
            </>
          </FadeIn>
        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>
  );
};
