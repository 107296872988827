import styled from 'styled-components';
import colors from './colors';
import { primaryColors, uiStateColors } from './variables/colors';
import { CSS_FONT_FAMILY, CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import { px2Rem } from '../utils/px2Rem';

export const RayloBodyType = styled.p`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${colors.rayloLight};
  line-height: 1.625;

  strong {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  }
`;

export const RayloBodyHeading = styled.h1<{ margin?: string }>`
  font-size: 1.375em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${colors.rayloLight};
  line-height: 1.275;
  margin-bottom: ${(props) => props.margin || '40px'};

  strong {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  }

  em {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    font-style: italic;
  }
`;

export const RayloLink = styled.a<{ inline?: boolean; color?: string }>`
  color: ${(props) => (props.color ? `${props.color}` : `${colors.rayloPink}`)};
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  position: relative;
  white-space: ${(props) => (props.inline ? 'normal' : 'nowrap')};
  cursor: pointer;
  text-decoration: underline;
`;

export const RayloSectionHeader = styled.h1<{ hasSubHeading?: boolean }>`
  font-size: 1.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${colors.rayloLight};
  line-height: 1.93;
  margin: ${(props) => (props.hasSubHeading ? '0' : '0 0 35px')};
  text-transform: uppercase;

  @media (max-width: 540px) {
    font-size: 1.375em;
    margin: ${(props) => (props.hasSubHeading ? '0' : '0 0 20px')};
  }
`;

export const RayloSubHeading = styled.h2`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  color: ${colors.rayloPink};
  line-height: 2.14;
  margin-bottom: 40px;

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

export const RayloAddress = styled.span`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${colors.rayloLight};
  line-height: 1.85;
`;

export const RayloBodyList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-position: inside;
  color: ${colors.rayloLight};
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  line-height: 1.857;
`;

export const RayloWarningText = styled.p`
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: 0.875em;
  color: ${colors.rayloPink};
  line-height: 2.14;
`;

export const AlmostThere = styled.div`
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 1.5rem;
  color: ${colors.rayloLight};
  letter-spacing: -0.26px;
  margin-bottom: 40px;

  .almost {
    font-size: inherit;
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    margin: 0 0 4px;
  }

  .next {
    font-size: 1.25rem;
    margin: 0;
    color: ${colors.rayloPink};
  }
`;

export const Steps = styled.ol`
  margin: 0 0 40px;
  padding: 0 0 0 40px;
  list-style-position: inside;
  list-style-type: none;
  counter-reset: step;

  @media (max-width: 540px) {
    padding: 0;
  }

  p {
    margin-bottom: 15px;
  }

  & > li {
    position: relative;
    color: ${colors.rayloLight};
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875em;
    letter-spacing: 0;
    line-height: 1.725;
    margin-bottom: 28px;

    @media (max-width: 540px) {
      padding-left: 32px;
    }

    strong {
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    }

    &::before {
      position: absolute;
      top: 0;
      left: -40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border: 1px solid ${colors.rayloPink};
      border-radius: 100%;
      color: ${colors.rayloPink};
      counter-increment: step;
      content: counter(step);

      @media (max-width: 540px) {
        left: 0;
        margin-bottom: 8px;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 16px;

      li {
        margin-bottom: 12px;
      }
    }
  }
`;

export const UnnumberedSteps = styled.ul`
  margin: 0 0 40px;
  padding: 0 0 0 40px;
  list-style-position: inside;
  list-style-type: none;
  counter-reset: step;

  @media (max-width: 540px) {
    padding: 0;
  }

  p {
    margin-bottom: 15px;
  }

  & > li {
    position: relative;
    color: ${colors.rayloLight};
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    letter-spacing: 0;
    margin-bottom: 10px;

    @media (max-width: 540px) {
      padding-left: 32px;
    }

    strong {
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 16px;

      li {
        margin-bottom: 12px;
      }
    }
  }
`;

export const PaymentRowLabel = styled.h3`
  font-size: ${px2Rem(16)};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  color: ${primaryColors.c01};
  line-height: 1.6;
  margin: 0 24px 0 0;
`;

export const Payment = styled.div<{ overdue?: boolean }>`
  font-family: ${CSS_FONT_FAMILY.vars.sans};
  font-size: ${px2Rem(16)};
  color: ${(props) =>
    props.overdue ? uiStateColors.error : primaryColors.c01};
  line-height: 1.4;

  @media (max-width: 540px) {
    font-size: 0.8125em;
  }
`;

export const SecuredByStripe = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  img {
    margin-right: 16px;
  }

  p {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875rem;
    color: ${colors.raylo2Light};
    line-height: 1.5;
  }
`;

export const RowLabel = styled.h3`
  font-size: 0.9375em;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  color: #2d2d2d;
  line-height: 1.6;
  margin: 0 24px 0 0;
`;
