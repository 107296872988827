import { useEffect, useState } from 'react';
import useRayloQuery from 'utils/useRayloQuery';
import { QL_INTERCOM } from 'screens/Account/queries';
import { bootIntercom, loadIntercom } from './IntercomUtils';
import constate from 'constate';
import store from 'store';

export const useIntercom = () => {
  const [token, setToken] = useState<string>('');
  const [userInfoSent, setUserInfoSent] = useState<boolean>(false);
  const [widgetIsVisible, setWidgetIsVisible] = useState<boolean>(false);
  const {
    data: { customer },
  } = useRayloQuery(QL_INTERCOM, {
    skip: !token,
  });

  const appId = customer
    ? customer?.messagingDetails?.intercom?.appId
    : process.env.REACT_APP_INTERCOM;

  const init = () => {
    loadIntercom(appId);

    bootIntercom({
      app_id: appId,
      hide_default_launcher: !widgetIsVisible,
    });
  };

  const hideIntercomWidget = () => {
    setWidgetIsVisible(false);
  };

  const showIntercomWidget = () => {
    setWidgetIsVisible(true);
  };

  const userToken = store.get('userToken');

  useEffect(() => {
    init();

    if (userToken && !token) {
      setToken(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: !widgetIsVisible,
    });
  }, [widgetIsVisible]);

  useEffect(() => {
    if (userInfoSent) {
      return;
    }

    if (token && window?.Intercom && customer) {
      const { userHash, userId } = customer.messagingDetails.intercom;
      window.Intercom('update', {
        app_id: appId,
        user_hash: userHash,
        user_id: userId,
        email: customer.email,
      });
      setUserInfoSent(true);
    }
  }, [token, customer]);

  return {
    showIntercomWidget,
    hideIntercomWidget,
  };
};

const [IntercomProvider, useIntercomContext] = constate(useIntercom);
export { IntercomProvider, useIntercomContext };
