import { useState } from 'react';
import { primaryColors } from 'styles/variables/colors';
import {
  Button,
  Copy,
  FormCheckbox,
  Header,
  Spacer,
} from '@raylo-tech/raylopay-ui';
import { Link } from 'components/common/Link/Link';
import { IUserReturnConfirmations } from './UserReturnConfirmations.types';

export const UserReturnConfirmations = ({
  onNext,
  onClose,
  secondaryButtonText,
}: IUserReturnConfirmations) => {
  const [showErrors, setShowErrors] = useState([false, false, false]);
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const validate = () => {
    setShowErrors(checkboxValues.map((value) => !value));

    if (checkboxValues.every((value) => value)) {
      onNext();
      return;
    }
  };

  const updateCheckBoxValues = (index: number) => {
    setCheckboxValues((oldValues) => {
      const newValues = [...oldValues];
      newValues[index] = !newValues[index];
      return newValues;
    });
  };

  return (
    <>
      <Header fontSize={24}>Returning your device</Header>
      <Spacer height={24} />
      <Copy>Confirm the following to avoid charges</Copy>
      <Spacer height={24} />
      <FormCheckbox
        dataTestId="checkbox-0"
        label="I'll use the label Raylo will give me"
        onChange={() => updateCheckBoxValues(0)}
        checked={checkboxValues[0]}
        errorMessage="Confirm you will use the label to continue"
        showError={showErrors[0]}
      />
      <Spacer height={16} />
      <FormCheckbox
        dataTestId="checkbox-1"
        onChange={() => updateCheckBoxValues(1)}
        checked={checkboxValues[1]}
        errorMessage="Confirm you will turn off to continue"
        showError={showErrors[1]}
      >
        <span>
          I&apos;ll turn off{' '}
          <Link
            href="https://help.raylo.com/en/articles/3323583-how-do-i-prepare-my-apple-device-for-return"
            color={primaryColors.b01}
            target="_blank"
          >
            Find My
          </Link>{' '}
          /{' '}
          <Link
            href="https://help.raylo.com/en/articles/4319219-how-do-i-prepare-my-samsung-for-return"
            color={primaryColors.b01}
            target="_blank"
          >
            Android Device Manager
          </Link>
        </span>
      </FormCheckbox>
      <Spacer height={16} />
      <FormCheckbox
        dataTestId="checkbox-2"
        label="I'll package the device securely"
        onChange={() => updateCheckBoxValues(2)}
        checked={checkboxValues[2]}
        errorMessage="Confirm you will package securely to continue"
        showError={showErrors[2]}
      />
      <Spacer height={24} />
      <Copy>
        Next you&apos;ll be taken to our returns platform to choose your return
        option and get your label
      </Copy>
      <Spacer height={24} />
      <Button onClick={validate} dataTestId="form-primary-button">
        Choose return option
      </Button>
      <Spacer height={24} />
      <Button onClick={onClose} secondary dataTestId="form-secondary-button">
        {secondaryButtonText}
      </Button>
    </>
  );
};
