import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { IStyledFormBase } from './FormBase.types';

export const StyledFormBase = styled.div<IStyledFormBase>`
  background-color: ${({ isMobile }) =>
    `${isMobile ? primaryColors.w01 : secondaryColors.c05}`};
  height: 100vh;
  width: 100vw;
`;
