import * as React from 'react';
import { memo } from 'react';

const MostPopularIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path d="M3.145 8.768.5 12l2.5.5 1 3 2.66-4.221M12.855 8.768 15.5 12l-2.5.5-1 3-2.66-4.221" />
      <path d="m8.317.61 1.231.99 1.58-.079a.5.5 0 0 1 .507.369l.413 1.526 1.324.867a.5.5 0 0 1 .194.6l-.563 1.475.563 1.477a.5.5 0 0 1-.194.597l-1.324.867-.413 1.526a.5.5 0 0 1-.508.369l-1.579-.079-1.231.992a.501.501 0 0 1-.628 0l-1.232-.994-1.579.079a.5.5 0 0 1-.507-.369l-.414-1.526-1.324-.867a.5.5 0 0 1-.193-.597l.563-1.475L2.44 4.88a.5.5 0 0 1 .193-.6l1.324-.867.414-1.526a.5.5 0 0 1 .507-.368l1.58.08 1.23-.988a.501.501 0 0 1 .629 0Z" />
      <path d="M8 9.5 5.443 6.834a1.512 1.512 0 1 1 2.14-2.14L8 5.11l.417-.417a1.513 1.513 0 0 1 2.14 2.139L8 9.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(MostPopularIcon);
export default Memo;
