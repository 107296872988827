import IFormNavbar from './FormNavbar.types';
import {
  ButtonWithoutStyle as Button,
  Grid,
  StyledCloseButtonContainer,
  StyledFormNavbar,
} from './FormNavbar.styles';
import { ReactComponent as BackArrowSVG } from 'public/icons/back-arrow.svg';
import { ReactComponent as CloseSVG } from 'public/icons/close.svg';
import { ReactComponent as RayloLogoSVG } from 'public/icon__raylo-logo.svg';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { Copy } from '@raylo-tech/raylopay-ui';

export const FormNavbar = ({
  title,
  onBack,
  onClose,
  onLogoClick,
}: IFormNavbar) => {
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  return (
    <StyledFormNavbar data-testid="form-navbar">
      <Grid>
        <div>
          {isMobile ? (
            <Button onClick={onBack} data-testid="nav-back-button">
              <BackArrowSVG stroke={primaryColors.w01} />
            </Button>
          ) : (
            <Button onClick={onLogoClick} data-testid="logo-button">
              <RayloLogoSVG fill={primaryColors.p01} height={px2Rem(50)} />
            </Button>
          )}
        </div>
        <Copy
          dataTestId="form-title"
          bold
          fontSize={16}
          textAlign="center"
          color={primaryColors.w01}
        >
          {title}
        </Copy>
        <StyledCloseButtonContainer>
          <Button onClick={onClose} data-testid="close-button">
            <CloseSVG stroke={primaryColors.w01} />
          </Button>
        </StyledCloseButtonContainer>
      </Grid>
    </StyledFormNavbar>
  );
};
