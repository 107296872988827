import BankDetailsContainer from 'components/BankDetailsContainer';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import DirectDebitError from '../DirectDebitError';
import { StyledDirectDebitConfirmNewDetails } from './styles';

export const DirectDebitConfirmNewDetails = ({ dataTestId }: ITestDataId) => {
  const { values, setConfirmDetails, setFormSubmitted, formErrors } =
    useStepBankContext();
  const handleSubmit = () => {
    setFormSubmitted(true);
  };
  if (formErrors) {
    return <DirectDebitError />;
  }

  return (
    <StyledDirectDebitConfirmNewDetails data-testid={dataTestId}>
      <Header level={2} color={primaryColors.c01}>
        Check your details are correct
      </Header>
      <Spacer height={16} />
      <Copy color={primaryColors.c01}>
        We&rsquo;ll setup your Direct Debit mandate after the device has been
        dispatched and you&rsquo;ll receive an email to confirm.
      </Copy>
      <Spacer height={16} />
      <Copy color={primaryColors.c01}>
        The first payment will be taken 14 days later and the name on your bank
        account statement will be Raylo.
      </Copy>
      <Spacer height={16} />
      <Header level={4} color={primaryColors.c01}>
        Your bank details
      </Header>
      <Spacer height={24} />
      <BankDetailsContainer
        bankDetails={values}
        onEdit={() => setConfirmDetails(false)}
      />
      <Spacer height={24} />
      <Button
        size="large"
        fullWidth
        buttonColor={ButtonTypes.b01}
        onClick={handleSubmit}
        text="confirm"
      />
      <Spacer height={24} />
      <Copy color={primaryColors.c01}>
        You may cancel this direct debit at any time by contacting Raylo or your
        bank.
      </Copy>
    </StyledDirectDebitConfirmNewDetails>
  );
};
