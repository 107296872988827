import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { activateOptimize } from 'utils/optimize';

// HOOKS

import { useUiContext } from 'utils/useUiContext';

// GRAPHQL
import { QL_RISK_PRICING } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import colors from 'styles/colors';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Modal } from 'components/common/Modal';
import { useErrorNavigator } from 'utils/useErrorNavigator';

import Currency from 'react-currency-formatter';

import { PhoneHeader } from 'screens/Checkout/components/PhoneHeader';
import { getOptionValue } from 'utils/getOptionValue';
import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { VariantOptionSlugs } from 'types/variants/options/variantOptionSlugs';
import { CheckoutOrderSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSummary';
import { CheckoutOrderSidebarSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSidebarSummary';

export const DecideLater = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'counter offer',
    checkoutScreen: 'counter offer decide later',
    when: 'on page load',
  });

  const { checkoutToken } = useCheckoutContext();
  const history = useHistory();
  const errorNav = useErrorNavigator();

  const { showSummary } = useUiContext();

  const {
    data: { checkout },
    error,
    loading,
  } = useRayloQuery(QL_RISK_PRICING, {
    variables: {
      checkoutToken,
    },
  });

  useEffect(() => {
    activateOptimize();
  });

  if (error) {
    errorNav('DecisionLater: failure', error);
  }

  if (loading || !checkout) {
    return <Modal visible text="Loading..." />;
  }

  const variant = checkout?.items[0]?.variant;
  const product = variant?.product;
  const costSummary = checkout?.items[0]?.costSummary;

  const storage = getOptionValue(
    variant,
    VariantOptionSlugs.Storage,
  ).displayName;
  const color = getOptionValue(variant, VariantOptionSlugs.Colour).displayName;
  const watchCaseSize = getOptionValue(
    variant,
    VariantOptionSlugs.WatchCaseSize,
  ).displayName;

  return (
    <div className={classNames('checkout--index', { showSummary })}>
      <Helmet>
        <title>Decide Later - Raylo</title>
      </Helmet>
      <div className="checkout--header">
        <PhoneHeader checkoutToken={checkoutToken} />
      </div>
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <div
              style={{
                marginBottom: 28,
                paddingBottom: 18,
                borderBottom: '1px solid #434343',
              }}
            >
              <T.RayloBodyHeading margin="28px">
                <strong>No problem</strong>, we’ll email you a link so you can
                come back and decide any time within the next 5 days
              </T.RayloBodyHeading>
            </div>
          </FadeIn>

          <SlideDownFadeIn>
            <div
              style={{
                marginBottom: 28,
                paddingBottom: 18,
                borderBottom: '1px solid #434343',
              }}
            >
              <T.RayloBodyHeading margin="24px">
                <strong>
                  {product.displayName} {storage} {color} {watchCaseSize}
                </strong>
                {costSummary.includesInsurance && (
                  <>
                    <br />
                    including insurance
                  </>
                )}
              </T.RayloBodyHeading>
              <T.RayloWarningText>
                Total Monthly Payment:{' '}
                <span style={{ color: '#FFF', marginLeft: 10 }}>
                  <Currency
                    quantity={
                      costSummary.recurring.totalAmount.valueAfterTax.value
                    }
                    currency={costSummary.currencyCode}
                  />
                </span>
              </T.RayloWarningText>
            </div>
          </SlideDownFadeIn>

          <SlideDownFadeIn>
            <div>
              <div style={{ marginBottom: 34 }}>
                <T.RayloBodyType>
                  You can safely close this window and come back at any time
                  using the link sent to{' '}
                  <span
                    className="sentry-mask"
                    style={{ color: colors.rayloPink }}
                  >
                    {checkout.customerInfo.email}
                  </span>
                </T.RayloBodyType>
              </div>
              <BackButton
                onClick={() => history.push('/checkout/decision/offer-details')}
                withText={false}
              />
            </div>
          </SlideDownFadeIn>
        </div>
      </div>
      <FadeIn>
        <div>
          <div className={classNames('checkout--summary', { showSummary })}>
            <CheckoutOrderSummary checkoutToken={checkoutToken} displayOnly />
          </div>
          <CheckoutOrderSidebarSummary
            checkoutToken={checkoutToken}
            displayOnly
          />
        </div>
      </FadeIn>
    </div>
  );
};
