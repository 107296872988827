import dayjs from 'dayjs';
import { StyledBannerFooter, StyledLinkButton } from './BannerFooter.styles';
import { Copy } from '@raylo-tech/raylopay-ui';
import { BannerFooterProps } from './BannerFooter.types';

export function BannerFooter({
  formattedMonthlyAmount,
  endOfLeaseDate,
  onClick,
}: BannerFooterProps) {
  const reachedEndOfLease = dayjs(endOfLeaseDate).isBefore(dayjs());

  return (
    <StyledBannerFooter>
      {reachedEndOfLease ? (
        <Copy fontSize={14} textAlign="center">
          You&apos;re currently paying {formattedMonthlyAmount}/mo to
          <StyledLinkButton onClick={onClick}>
            Continue Leasing
          </StyledLinkButton>
        </Copy>
      ) : (
        <Copy fontSize={14} textAlign="center">
          If no action is taken, you&apos;ll
          <StyledLinkButton onClick={onClick}>
            Continue leasing
          </StyledLinkButton>
          for {formattedMonthlyAmount}/mo and can change options at any time.
        </Copy>
      )}
    </StyledBannerFooter>
  );
}
