import { PhoneStatusUpgrade } from './PhoneStatusUpgrade';
import { PhoneBlockedBanner } from './PhoneBlockedBanner';
import { Delivery } from './Delivery';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import { OverdueBanner } from './Banners/OverdueBanner/OverdueBanner';
import { PaymentBanner } from './Banners/PaymentBanner/PaymentBanner';

export const LeaseStatus = ({ order, customer, isInArrears }) => {
  switch (order.status) {
    case YourTechStatus.blocked:
      return <PhoneBlockedBanner asset={order.subscription.activeAsset} />;
    case YourTechStatus.arrears:
      return <OverdueBanner device={order} customer={customer} />;
    case YourTechStatus.upgradeAvailable:
      if (isInArrears) {
        return null;
      }
      return <PhoneStatusUpgrade phone={order} />;
    default:
      if (order.subscription?.payable) {
        return <PaymentBanner order={order} />;
      }
      if (order.hasDeliveryDate) {
        return <Delivery phone={order} address={customer.address} />;
      }
  }
};
