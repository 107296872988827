import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { orderBy, last, compact, filter } from 'lodash-es';
import dayjs from 'dayjs';
import moment from 'moment';
import { CSS_FONT_WEIGHT, CSS_FONT_FAMILY } from '@raylo-tech/raylopay-ui';

import * as T from 'styles/type';
import colors from 'styles/colors';

import inTransit from 'public/icon--shipment-in-transit.svg';
import downArrow from 'public/icon--arrow--white--down.svg';
import { DeliveryEstimate } from '../common/DeliveryEstimate';
import { primaryColors } from 'styles/variables/colors';

export const Delivery = ({ phone, address }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (document.getElementById('dropdown')) {
      let height = document.getElementById('dropdown').scrollHeight;
      setDropdownHeight(height);
    }
  }, []);

  const orderItem = phone.orderItem;
  const timeline = orderItem.timeline;
  const fulfillmentItem = orderItem.fulfillmentItem;
  const sortedTimeline = orderBy(timeline, ['happenedAt']);
  const firstEvent = last(sortedTimeline);

  return (
    <DeliveryOuterContainer>
      <DeliveryInnerContainer>
        <DeliveryInner>
          <DeliveryHeader>
            <img
              src={inTransit}
              alt="Shipment in Transit"
              className="transit-icon"
            />
            {fulfillmentItem && fulfillmentItem.deliveredAt ? (
              <DeliveryHeading className="sentry-mask">
                Good news, your {phone.model} has been delivered to{' '}
                {compact([address.line1, address.line2]).join(', ')}
              </DeliveryHeading>
            ) : fulfillmentItem && fulfillmentItem.dispatchedAt ? (
              <DeliveryHeading>
                <strong>How exciting</strong>, your {phone.model} is with{' '}
                {fulfillmentItem.courierName} for delivery.
              </DeliveryHeading>
            ) : fulfillmentItem && fulfillmentItem.expectedDeliveryAt ? (
              <DeliveryHeading>
                Good news, your {phone.model} is coming on{' '}
                <strong>
                  {moment(fulfillmentItem.expectedDeliveryAt).format('DD')}
                </strong>
              </DeliveryHeading>
            ) : phone.preOrder ? (
              <DeliveryHeading
                className="sentry-mask"
                data-testid="preorder-delivery-header"
              >
                Good news, we'll soon ship your {phone.model} to{' '}
                {compact([address.line1, address.line2]).join(', ')}.
              </DeliveryHeading>
            ) : (
              <DeliveryHeading
                className="sentry-mask"
                data-testid="default-delivery-header"
              >
                Good news, we're getting ready to ship your {phone.model} to{' '}
                {compact([address.line1, address.line2]).join(', ')}
              </DeliveryHeading>
            )}
          </DeliveryHeader>

          {!fulfillmentItem || !fulfillmentItem.dispatchedAt ? (
            <DeliveryDispatchMessage>
              <p>
                Delivery information will become available as soon as your
                device has been dispatched
              </p>
              <p>
                Expected delivery:{' '}
                <DeliveryEstimate
                  delivery={orderItem.deliveryEstimatedBetween}
                />
              </p>
            </DeliveryDispatchMessage>
          ) : (
            <>
              <DeliveryNoticeBox>
                <h5 className="notice-heading">
                  Latest update from {fulfillmentItem.courierName}:
                </h5>
                <p className="notice-message">{firstEvent.message}</p>
              </DeliveryNoticeBox>
              <DeliveryDropdown
                data-testid="delivery-dropdown-arrow"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                <p className="delivery-info">Delivery History</p>
                <DownArrow
                  src={downArrow}
                  alt="Arrow pointing down"
                  open={dropdownVisible}
                />
              </DeliveryDropdown>

              <DeliveryStages
                data-testid="delivery-timeline"
                id="dropdown"
                expanded={dropdownVisible}
                maxHeight={dropdownHeight}
              >
                {sortedTimeline.map((event, i) => {
                  return (
                    <DeliveryStage key={i}>
                      <DeliveryStageTime>
                        <p className="time">
                          {dayjs(event.happenedAt).format('dddd, h:mma')}
                        </p>
                        <p className="date">
                          {dayjs(event.happenedAt).format('DD/MM/YY')}
                        </p>
                      </DeliveryStageTime>
                      <DeliveryStageStatus>
                        <p className="message-title">
                          {event.stateDescription}
                        </p>
                        <p className="message">{event.message}</p>
                      </DeliveryStageStatus>
                    </DeliveryStage>
                  );
                })}

                <DeliveryAddress>
                  <p className="address-label">Delivery Address</p>

                  <div className="sentry-mask">
                    {filter(
                      [
                        address.line1,
                        address.line2,
                        address.line3,
                        address.city,
                        address.region,
                        address.postcode,
                      ],
                      (o) => o && o.length > 1,
                    ).map((o, index) => (
                      <p className="address" key={index}>
                        {o}
                      </p>
                    ))}
                  </div>
                </DeliveryAddress>

                <DeliveryTracking>
                  <div className="delivery-company">
                    <p>
                      Delivered by: <span>{fulfillmentItem.courierName}</span>
                    </p>
                  </div>

                  <div className="tracking-id">
                    <p className="sentry-mask">
                      Tracking ID:{' '}
                      <a
                        href={fulfillmentItem.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-external="true"
                      >
                        {fulfillmentItem.trackingReference}
                      </a>
                    </p>
                  </div>
                </DeliveryTracking>
              </DeliveryStages>
            </>
          )}
        </DeliveryInner>
      </DeliveryInnerContainer>
    </DeliveryOuterContainer>
  );
};

const DeliveryOuterContainer = styled.div`
  background-color: ${primaryColors.b01};
  padding: 75px 0;

  @media (max-width: 540px) {
    padding: 40px 0;
  }
`;

const DeliveryInnerContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px;
  text-align: center;

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`;

const DeliveryInner = styled.div`
  width: 100%;
`;

const DeliveryHeader = styled.div`
  margin-bottom: 30px;

  @media (max-width: 540px) {
    margin-bottom: 28px;
  }

  .transit-icon {
    margin-bottom: 16px;
  }
`;

const DeliveryHeading = styled(T.RayloBodyHeading)`
  margin-bottom: 0;

  @media (max-width: 540px) {
    font-size: 1.25em;
  }
`;

const DeliveryNoticeBox = styled.div`
  background-color: ${colors.rayloPink};
  padding: 20px 25px 28px;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;

  .notice-heading {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: inherit;
    color: ${colors.rayloBlue};
    line-height: 1.714;
    margin-bottom: 10px;
  }

  .notice-message {
    color: ${colors.rayloDark};
    line-height: 1.85;
    font-size: inherit;
  }
`;

const DeliveryDispatchMessage = styled.div`
  p {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 1.125em;
    color: ${colors.rayloPink};
    line-height: 1.7;
  }
`;

const DeliveryDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 44px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.rayloBlue3Dark};
  cursor: pointer;
  position: relative;
  transition: border 0.35s;

  .delivery-info {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 1.125em;
    color: ${colors.rayloPink};
    letter-spacing: 0;
    line-height: 1.5;
    margin: 0;

    @media (max-width: 540px) {
      font-size: 1em;
    }
  }

  &:hover {
    border-bottom-color: ${colors.rayloLight};
  }
`;

const DownArrow = styled.img`
  display: inline-block;
  width: 20px;
  height: 11px;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.35s;
`;

const DeliveryStages = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? `${props.maxHeight}px` : '0')};
  transition: max-height 0.35s ease-in-out;
`;

const DeliveryStage = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid ${colors.rayloBlue3Dark};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const DeliveryStageTime = styled.span`
  width: 40%;
  font-size: 0.875em;
  line-height: 1.714;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .time,
  .date {
    margin: 0;
  }

  .time {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    color: ${colors.rayloLight};

    span {
      white-space: nowrap;
    }
  }

  .date {
    font-family: var(--font-family-mono);
    color: #bebfff;
  }
`;

const DeliveryStageStatus = styled.span`
  width: 50%;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  color: ${colors.rayloPink};
  letter-spacing: 0;
  text-align: left;
  line-height: 1.714;

  .message-title {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  }
`;

const DeliveryAddress = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.875em;
  line-height: 1.714;
  padding: 20px 0;

  .address-label {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    color: ${colors.rayloLight};
    margin: 0;
  }

  .address {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    color: ${colors.rayloPink};
    text-align: right;
    margin: 0;
  }
`;

const DeliveryTracking = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;

  @media (max-width: 450px) {
    flex-direction: column;
    padding: 20px 0;
  }

  .delivery-company,
  .tracking-id {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.875em;
    color: ${colors.rayloPink};
    line-height: 2.14;

    @media (max-width: 450px) {
      p {
        margin: 0;
      }
    }
  }

  .delivery-company {
    margin-right: 28px;

    @media (max-width: 450px) {
      margin-right: 0;
    }

    span {
      color: ${colors.rayloLight};
    }
  }

  .tracking-id {
    a {
      font-weight: ${CSS_FONT_WEIGHT.vars.medium};
      color: ${colors.rayloLight};
      text-decoration: none;
      position: relative;

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 125%;
        left: 0;
        right: 0;
        opacity: 0.23;
        background-color: #fff;
        transition: opacity 0.35s;
      }
    }
  }
`;
