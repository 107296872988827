import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

export type IReason = {
  title: string;
  subreasons: string[];
};

const useSubscriptionCancellationReasons = (subscriptionId: string) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [cancelSubscriptionReasonTypes, setCancelSubscriptionReasonTypes] =
    useState<IReason[]>([]);
  const GET_CANCEL_SUBSCRIPTION_REASON_TYPES = gql`
    query cancelSubscriptionReasonTypes($subscriptionId: ID!) {
      cancelSubscriptionReasonTypes(subscriptionId: $subscriptionId) {
        title
        subreasons
      }
    }
  `;

  const [getCancelSubscriptionReasonTypes] = useLazyQuery(
    GET_CANCEL_SUBSCRIPTION_REASON_TYPES,
    {
      variables: { subscriptionId },
      onCompleted(data) {
        const { cancelSubscriptionReasonTypes } = data;

        setCancelSubscriptionReasonTypes(cancelSubscriptionReasonTypes);
      },
    },
  );

  useEffect(() => {
    if (!subscriptionId) return;

    getCancelSubscriptionReasonTypes();
  }, [subscriptionId, getCancelSubscriptionReasonTypes]);

  return {
    cancelSubscriptionReasonTypes: cancelSubscriptionReasonTypes as IReason[],
    setSelectedReason,
    selectedReason,
  };
};

export default useSubscriptionCancellationReasons;
