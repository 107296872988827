import { useHistory, useParams } from 'react-router';

import * as A from 'styles/account';

import { AccountFooter } from 'components/Account/AccountFooter';
import { Button } from 'components/common/Button';
import brokenPhoneIcon from 'public/icon--broken-phone.svg';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Modal } from 'components/common/Modal';
import { QL_INSURANCE } from 'screens/Account/queries';
import { InsuranceClaims } from 'components/Account/InsuranceClaims';
import { Helmet } from 'react-helmet';
import useInsurance from './useInsuranceContext';

export const Insurance = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const { orderItem, loading } = useInsurance({
    query: QL_INSURANCE,
    isLostOrStolen: false,
    orderId,
  });

  if (loading || !orderItem?.id) return <Modal visible text="Loading..." />;

  return (
    <>
      <Helmet>
        <title>Lost Device - Raylo</title>
      </Helmet>
      {orderItem?.fulfillmentItem?.dispatchedAt ? (
        <>
          <A.ClaimBanner>
            <img
              className="icon"
              src={brokenPhoneIcon}
              alt="Icon of a broken smartphone"
            />
            <h5 className="heading">Something wrong?</h5>
            <p className="body">
              If your device is damaged, lost or stolen, you can make a claim on
              your included insurance. To begin, choose the option that best
              describes the current condition of your device:
            </p>
            <div className="buttons">
              <Button
                buttonStyle="secondary"
                onClick={() =>
                  history.push(`/account/insurance/damage/${orderId}`)
                }
              >
                Accidental Damage
              </Button>
              <Button
                buttonStyle="secondary"
                onClick={() =>
                  history.push(`/account/insurance/liquid/${orderId}`)
                }
              >
                Liquid Damage
              </Button>
              <Button
                buttonStyle="secondary"
                onClick={() =>
                  history.push(`/account/insurance/lost/${orderId}`)
                }
              >
                Lost
              </Button>
              <Button
                dataTestId="insurance-stolen"
                buttonStyle="secondary"
                onClick={() =>
                  history.push(`/account/insurance/stolen/${orderId}`)
                }
              >
                Stolen
              </Button>
            </div>
          </A.ClaimBanner>
          {orderItem?.subscription?.insurancePolicy?.claims && (
            <InsuranceClaims
              policy={orderItem.subscription.insurancePolicy}
              claims={orderItem.subscription.insurancePolicy.claims}
            />
          )}
        </>
      ) : (
        <A.ClaimBanner>
          <img
            className="icon"
            src={brokenPhoneIcon}
            alt="Icon of a broken smartphone"
          />
          <h5 className="heading">Something wrong?</h5>
          <p className="body">
            You can make a claim on our policy if your event of accidental
            damage, loss or theft is covered under our policy. The claim will be
            processed by Pier Insurance, who will make the decision if your
            claim is approved or rejected. This will become available as soon as
            your device has been delivered.
          </p>
        </A.ClaimBanner>
      )}

      <PolicyBanner />
      <AccountFooter />
    </>
  );
};
