import { FormBase } from 'components/Forms/FormBase/FormBase';
import { ExitModal } from 'components/Forms/FormBase/ExitModal/ExitModal';
import { useFormContext } from 'screens/Account/Forms/context/useFormContext';
import { IForm } from './Form.types';

export const Form = ({ children, title, totalPages }: IForm) => {
  const { currentPage, setCurrentPage, showExitModal, setShowExitModal } =
    useFormContext();

  const onBack = () => {
    if (currentPage === 1) return navigateBackOrToAccount();

    setCurrentPage((oldProgress: number) => oldProgress - 1);
  };

  const onClose = () => {
    if (currentPage === 1) return navigateBackOrToAccount();

    setShowExitModal('close');
  };

  const onLogoClick = () => {
    if (currentPage === 1) return window.location.assign('/account');

    setShowExitModal('logo');
  };

  const exitModalCallback = () => {
    switch (showExitModal) {
      case 'logo':
        return window.location.assign('/account');
      case 'close':
        return navigateBackOrToAccount();
    }
  };

  return (
    <FormBase
      title={title}
      onBack={onBack}
      onClose={onClose}
      onLogoClick={onLogoClick}
      currentProgress={currentPage}
      totalProgress={totalPages}
    >
      {children}
      <ExitModal
        onClose={exitModalCallback}
        onResume={() => setShowExitModal(null)}
        modalOpen={showExitModal !== null}
      />
    </FormBase>
  );
};

const navigateBackOrToAccount = () => {
  if (history.length > 1) {
    return history.back();
  } else {
    return window.location.assign('/account');
  }
};
