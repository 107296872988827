import { useEffect } from 'react';
import {
  Container,
  Header,
  ProductImageCard,
  Spacer,
  TextLink,
  SectionMessageBase,
  Copy,
} from '@raylo-tech/raylopay-ui';
import Icon from 'elements/Icon';
import { Modal } from 'components/common/Modal';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { formatDateRange } from 'utils/formatDateRange';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { px2Rem } from 'utils/px2Rem';
import { LEGACY_BREAKPOINTS } from '../../../../../../styles/LEGACY_BREAKPOINTS';
import CheckoutDecisionWrapper from '../../CheckoutDecisionWrapper';
import { useCheckoutDecisionContext } from '../../hooks/useCheckoutDecisionContext';
import { track } from 'integrations/segment/events';
import Recommendations from 'components/Recommendations';
import { DecisionAcceptedUpgrade } from './DecisionAcceptedUpgrade';
import { DeliveryConfirmation } from './DeliveryConfirmation';
import { useHistory } from 'react-router';
import useMedia from 'use-media';

export const DecisionAcceptedContent = () => {
  const {
    productDisplayName,
    productImageUrl,
    deliveryEstimate,
    displayDecision,
    oldDevice,
    customerPreApproval,
  } = useCheckoutDecisionContext();

  const { checkoutID } = useCheckoutContext();
  const history = useHistory();
  const isUpgradeFlow = oldDevice;
  const shouldShowRecommendations = !oldDevice && customerPreApproval;
  const isSmallMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.smallMobile });

  useEffect(() => {
    if (analytics) {
      track('sale', { orderId: checkoutID });
    }
  }, [checkoutID]);

  const handleGoToAccount = () => {
    history.push('/account');
  };

  if (!displayDecision) {
    return <Modal visible text="Loading..." />;
  }

  return (
    <CheckoutDecisionWrapper>
      <Container
        maxWidth={px2Rem(420)}
        padding={`0 ${px2Rem(20)}`}
        dataTestId="decisionAcceptedContent"
      >
        <Container
          center
          noPadding
          width="100%"
          margin={`${px2Rem(-32)} 0 0 0`}
        >
          {!shouldShowRecommendations && (
            <Container noPadding>
              <ProductImageCard
                imageUrl={`${productImageUrl}?trim=color&fm=webp`}
                dataTestId="productImage"
              />
            </Container>
          )}
        </Container>
        <Header
          dataTestId="checkoutAcceptedHeader"
          color={primaryColors.c01}
          level={2}
          subColor={secondaryColors.c02}
        >
          Thanks for choosing Raylo.
          <span> Your {productDisplayName} is on its way</span>
        </Header>
        <Spacer height={16} />
        <SectionMessageBase
          header="We'll send you a confirmation email soon"
          content={
            <Container
              flexRow
              margin={`${px2Rem(8)} 0 0 0`}
              alignCenter={!isSmallMobile}
            >
              <Icon
                margin={`${px2Rem(4)} ${px2Rem(12)} 0 0`}
                name="ShipmentPackage"
                size={px2Rem(22)}
                color={secondaryColors.p02}
              />
              <Copy fontSize={14} lineHeight={20} color={primaryColors.c01}>
                {`Your order should arrive ${formatDateRange(deliveryEstimate || {})}`}
              </Copy>
            </Container>
          }
        />
        <Spacer height={16} />
        <TextLink
          fontSize={14}
          dataTestId="goToAccount"
          onClick={handleGoToAccount}
          fontColor={primaryColors.b01}
          noUnderline
        >
          View your order status in your account
        </TextLink>
        <Spacer height={16} />
      </Container>
      {isUpgradeFlow ? (
        <DecisionAcceptedUpgrade />
      ) : shouldShowRecommendations ? (
        <Recommendations preApprovalLimit={customerPreApproval} />
      ) : (
        <DeliveryConfirmation />
      )}
    </CheckoutDecisionWrapper>
  );
};
