import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { Button } from 'components/common/Button';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

export const CardCredit = ({ onClick }) => {
  useEffect(() => {
    activateOptimize();
  });
  return (
    <div className="checkout--step feedback">
      <FadeIn>
        <T.RayloBodyHeading>
          <strong>
            We’re sorry, but we’re unable to accept your application for credit.
          </strong>
        </T.RayloBodyHeading>
      </FadeIn>

      <SlideDownFadeIn>
        <div style={{ marginBottom: 40 }}>
          <T.RayloBodyType>Please try again in six months</T.RayloBodyType>
        </div>
      </SlideDownFadeIn>

      <SlideDownFadeIn>
        <Button
          buttonStyle="primaryBlue"
          onClick={onClick}
          style={{ height: '44px' }}
        >
          Finish
        </Button>
      </SlideDownFadeIn>
    </div>
  );
};
