import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

export const StyledRadioSelectBorderBox = styled.label<{ $active: boolean; $hasLabel: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${px2Rem(24)};
  padding: ${px2Rem(24)};
  border-radius: ${px2Rem(4)};
  box-shadow: 0 0 0 ${px2Rem(1)} ${palette.charcoal[300]} inset;

  ${({ $hasLabel }) =>
    $hasLabel &&
    `
    position: relative;
    margin-top: ${px2Rem(28)};
    border-top-left-radius: 0;
  `}

  &:hover, &:focus-within {
    box-shadow: 0 0 0 ${px2Rem(2)} ${palette.charcoal[400]} inset;
  }

  ${({ $active }) =>
    $active &&
    `
    &, &:hover, &:focus-within {
      box-shadow: 0 0 0 ${px2Rem(2)} ${palette.blue[500]} inset;
    }
  `}
`;

export const StyledRadioSelectBorderBoxBody = styled.div`
  padding-top: ${px2Rem(24)};
  border-top: ${px2Rem(1)} solid ${palette.charcoal[200]};
`;
