import { ASSETS_SVGS_BASE_URL } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';

export const CheckoutStepsBackArrow = ({
  backButtonOnClick,
  isStepTradeIn,
}: {
  backButtonOnClick: () => void;
  isStepTradeIn: boolean;
}) => (
  <div style={{ height: px2Rem(75), width: '100%' }}>
    <button
      data-testid="back-arrow"
      onClick={backButtonOnClick}
      title="Go back"
      style={{
        border: '0',
        background: 'none',
        cursor: 'pointer',
        outline: 'none',
        padding: px2Rem(24),
        margin: '0 auto 0 0',
        position: isStepTradeIn ? 'absolute' : 'static',
        top: px2Rem(80),
        left: '0',
        zIndex: 100,
      }}
    >
      <img
        src={`${ASSETS_SVGS_BASE_URL}/back-arrow-progress-bar.svg`}
        alt="Back"
      />
    </button>
  </div>
);
