import { useEffect } from 'react';
import {
  Button,
  Container,
  SectionMessage,
  Spacer,
} from '@raylo-tech/raylopay-ui';
import { Copy } from 'elements';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { PlainHeader } from 'components/common/PlainHeader';
import { secondaryColors } from 'styles/variables/colors';
import { useMedia } from 'use-media';
import { LEGACY_BREAKPOINTS } from '../../../../styles/LEGACY_BREAKPOINTS';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { px2Rem } from 'utils/px2Rem';
import { ContinuousPaymentAuthorityWrapper } from './HowWeWillUseYourCard.styles';
import {
  continuousPaymentAuthorityContent,
  sectionMessageCopy,
} from './HowWeWillUseYourCardUtils';

export const HowWeWillUseYourCard = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: '',
    checkoutScreen: 'cpa',
    when: 'on page load',
  });
  const history = useHistory();

  const handleSubmit = () => {
    history.push('/checkout/decision/pay-deposit');
  };

  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  useEffect(() => {
    setTimeout(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 0);
  }, []);

  return (
    <ContinuousPaymentAuthorityWrapper isMobile={isMobile}>
      <Helmet>
        <title>Continuous Payment Authority - Raylo</title>
      </Helmet>
      <PlainHeader />
      <Container maxWidth="450px">
        <Copy fontSize={24} bold>
          How we will use your card information
        </Copy>
        <Spacer height={24} />
        <div className="info-callout">
          <SectionMessage
            copy={sectionMessageCopy}
            icon="RayloDesignInfo"
            borderColor={secondaryColors.b04}
          />
          <Spacer height={24} />
          <Container
            backgroundColor={secondaryColors.c05}
            styles={{
              padding: '20px',
              maxHeight: px2Rem(isMobile ? 200 : 300),
              overflow: 'auto',
              width: `calc(100% - ${px2Rem(20)})`,
            }}
          >
            {continuousPaymentAuthorityContent}
          </Container>
        </div>
        <Spacer height={36} />
        <Button dataTestId="payDepositButton" onClick={handleSubmit}>
          PAY DEPOSIT
        </Button>
      </Container>
    </ContinuousPaymentAuthorityWrapper>
  );
};
