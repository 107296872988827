import styled from 'styled-components';
import { IStyledIcon } from './types';

const StyledIcon = styled.div<IStyledIcon>`
  ${({ margin }) => margin && `margin: ${margin}`};

  svg {
    color: ${({ color }) => color && color};
    fill: ${({ fill }) => fill && fill};
    width: ${({ size }) => size && size};
    height: ${({ size }) => size && size};
  }

  ${({ styles }) => styles};
`;

export { StyledIcon };
