import { when, then, end, _ } from 'utils/matching';
import { FRONTEND_URL } from './constants';

export default function (checkout) {
  let condition = {
    state: checkout.state,
    outcome: null,
    reason: null,
  };

  if (checkout.decision) {
    if (checkout.decision.outcome) {
      condition.outcome = checkout.decision.outcome;
    }
    if (checkout.decision.outcomeReason) {
      condition.reason = checkout.decision.outcomeReason;
    }
  }

  return when(condition)(
    { state: 'CANCELLED', outcome: _, reason: _ },
    then(() => '/checkout/decision/cancelled'),
  )(
    { state: 'ACCEPTED', outcome: _, reason: _ },
    then(() => '/checkout/decision/accepted'),
  )(
    { state: 'SUBMITTED', outcome: 'ACCEPTED', reason: _ },
    then(() => '/checkout/decision/accepted'),
  )(
    { state: 'SUBMITTED', outcome: 'REFERRED', reason: _ },
    then(() => '/checkout/decision/referred'),
  )(
    { state: 'SUBMITTED', outcome: 'DECLINED', reason: _ },
    then(() => '/checkout/decision/cancelled'),
  )(
    { state: 'SUBMITTED', outcome: 'PENDING', reason: _ },
    then(() => '/checkout/decision'),
  )(
    { state: 'SUBMITTED', outcome: 'CANCELLED', reason: _ },
    then(() => '/checkout/decision/cancelled'),
  )(
    {
      state: 'SUBMITTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'counter_offer',
    },
    then(() => '/checkout/decision/offer'),
  )(
    {
      state: 'ABANDONED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'bank_connection_requested',
    },
    then(() => '/checkout/decision/bank-connection'),
  )(
    {
      state: 'SUBMITTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'bank_connection_requested',
    },
    then(() => '/checkout/decision/bank-connection'),
  )(
    {
      state: 'SUBMITTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'security_deposit',
    },
    then(() => '/checkout/decision/security-deposit'),
  )(
    { state: 'ABANDONED', outcome: _, reason: _ },
    then(() => '/checkout/resume'),
  )(
    {
      state: 'STARTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'counter_offer',
    },
    then(() => '/checkout/decision/offer'),
  )(
    {
      state: 'STARTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'bank_connection_requested',
    },
    then(() => '/checkout/decision/bank-connection'),
  )(
    {
      state: 'STARTED',
      outcome: 'USER_ACTION_REQUIRED',
      reason: 'security_deposit',
    },
    then(() => '/checkout/decision/security-deposit'),
  )(
    { state: 'STARTED', outcome: _, reason: _ },
    then(() => '/checkout'),
  )(
    _,
    then(() => (window.location = FRONTEND_URL && false)),
  )(
    // assign location and use false as the return value
    end,
  );
}
