import { useRef, useState } from 'react';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Grid from 'elements/Grid';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextInput from 'elements/TextInput';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { dateValidation } from 'utils/formValidation';
import { StyledDateOfBirthInput } from './styles';
import { DOBValues, IDateOfBirthInput } from './types';

const dateTypes = {
  day: 'DD',
  month: 'MM',
  year: 'YYYY',
};

export const DateOfBirthInput = ({
  dataTestId,
  name,
  error,
  onChange,
  values,
}: IDateOfBirthInput) => {
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [formError, setFormError] = useState<string | null>(null);

  const onBlur = (field: string) => {
    const dateType = dateTypes?.[field as keyof typeof dateTypes];
    const value = values?.[dateType as keyof DOBValues];

    if (!value) {
      return;
    }

    const validateEntry = dateValidation(field, value);
    if (validateEntry !== 'success') {
      setFormError(validateEntry);
    } else {
      setFormError(null);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    onChange?.(e);

    const { value } = e.target;

    setTimeout(() => {
      if (field === 'day' && value.length >= 2) {
        monthRef.current?.focus();
      }
      if (field === 'month' && value.length >= 2) {
        yearRef.current?.focus();
      }
      if (field === 'year' && value.length >= 4) {
        yearRef.current?.blur();
      }
    }, 0);
  };

  return (
    <StyledDateOfBirthInput data-testid={dataTestId}>
      <Header level={3} color={primaryColors.c01} fontSize={16}>
        {name}
      </Header>
      <Grid>
        <Container width={px2Rem(64)} padding="0">
          <TextInput
            ref={dayRef}
            dataTestId={`${dataTestId}-day`}
            type="number"
            placeHolderText="DD"
            height="fit-content"
            onChange={(e) => handleChange(e, 'day')}
            onBlur={() => onBlur('day')}
            maxLength={2}
            fieldName="dayOfBirth"
            value={values?.DD}
          />
        </Container>
        <Container
          width={px2Rem(64)}
          padding="0"
          margin={`0 0 0 ${px2Rem(16)}`}
        >
          <TextInput
            ref={monthRef}
            dataTestId={`${dataTestId}-month`}
            type="number"
            placeHolderText="MM"
            height="fit-content"
            onChange={(e) => handleChange(e, 'month')}
            onBlur={() => onBlur('month')}
            maxLength={2}
            fieldName="monthOfBirth"
            value={values?.MM}
          />
        </Container>
        <Container
          width={px2Rem(92)}
          padding="0"
          margin={`0 0 0 ${px2Rem(16)}`}
        >
          <TextInput
            ref={yearRef}
            dataTestId={`${dataTestId}-year`}
            type="number"
            placeHolderText="YYYY"
            height="fit-content"
            onChange={(e) => handleChange(e, 'year')}
            onBlur={() => onBlur('year')}
            maxLength={4}
            fieldName="yearOfBirth"
            value={values?.YYYY}
          />
        </Container>
      </Grid>
      {error && (
        <>
          <Spacer height={4} />
          <Copy color={uiStateColors.error}>{error}</Copy>
        </>
      )}
      {formError && !error && (
        <>
          <Spacer height={4} />
          <Copy color={uiStateColors.error}>{formError}</Copy>
        </>
      )}
      {!error && (
        <>
          <Spacer height={8} />
          <Copy color={secondaryColors.c02}>For example 31 12 1970</Copy>
        </>
      )}
    </StyledDateOfBirthInput>
  );
};
