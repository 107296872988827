import { useState, useEffect } from 'react';
import constate from 'constate';
import { rayloLight } from 'styles/themes/rayloLight';
import { getThemeFromDomain } from 'utils/getThemeFromDomain';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { API_MAINTENANCE_MODE } from './constants';

const useUi = ({ costSummary = null }) => {
  const [showSummary, setShowSummary] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDDModal, setShowDDModal] = useState(false);
  const [showDDContactModal, setShowDDContactModal] = useState(false);
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [showTradeInModal, setShowTradeInModal] = useState(false);
  const [showTradeInTermsModal, setShowTradeInTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [afterLeaseChoiceModalOpen, setAfterLeaseChoiceModalOpen] =
    useState(false);

  const [uiCostSummary, setUiCostSummary] = useState(costSummary);
  const [showReview, setShowReview] = useState(false);

  const [additionalTech, setAdditionalTech] = useState(false);
  const [lightMode, setLightMode] = useState(false);
  const [hideProgressBar, setHideProgressBar] = useState(false);
  const [isRayloPay, setIsRayloPay] = useState(false);
  const [theme, setTheme] = useState(rayloLight);
  const [showNavBar, setShowNavBar] = useState(true);

  const growthbook = useGrowthBook();
  const apiMaintenanceMode = growthbook?.getFeatureValue(
    API_MAINTENANCE_MODE,
    false,
  );

  useEffect(() => {
    // Note: this is being run once on component mount
    // It's purpose is to set some properties for a whitelabeled merchant
    // before those get available trough the GraphQL, to avoid rendering unbranded
    // page and then applying the styling.
    if (window?.location?.hostname?.includes('raylopay')) {
      setIsRayloPay(true);
      const rayloPayTheme = getThemeFromDomain(window.location.hostname);
      if (rayloPayTheme) {
        setTheme(rayloPayTheme);
      }
    }
  }, []);

  useEffect(() => {
    if (apiMaintenanceMode) {
      window.location.assign(
        process.env.REACT_APP_MAINTENANCE_URL || 'https://www.raylo.com/503',
      );
    }
  }, [apiMaintenanceMode]);

  const handleShowTradeInTermsModal = () => {
    setShowModal(true);
    setShowTradeInTermsModal(true);
  };

  return {
    showSummary,
    showFeedback,
    showModal,
    showDDModal,
    showDDContactModal,
    showInsuranceModal,
    uiCostSummary,
    showReview,
    additionalTech,
    lightMode,
    hideProgressBar,
    showTradeInModal,
    showTradeInTermsModal,
    showPrivacyModal,
    isRayloPay,
    theme,
    showNavBar,
    afterLeaseChoiceModalOpen,
    setTheme,
    setIsRayloPay,
    setShowSummary,
    setShowFeedback,
    setShowModal,
    setShowDDModal,
    setShowDDContactModal,
    setShowInsuranceModal,
    setShowTradeInModal,
    setShowTradeInTermsModal,
    setUiCostSummary,
    setShowReview,
    setAdditionalTech,
    setLightMode,
    setHideProgressBar,
    handleShowTradeInTermsModal,
    setShowPrivacyModal,
    setShowNavBar,
    setAfterLeaseChoiceModalOpen,
  };
};

const [UiProvider, useUiContext] = constate(useUi);
export { UiProvider, useUiContext };
