import { useEffect } from 'react';
import store from 'store';
import { QL_CHECKOUT_STATE } from 'screens/Checkout/graphQL/queries';
import { useParams, useHistory } from 'react-router';
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';
import { MU_RESUME_CHECKOUT } from './graphQL/mutations';
import useRayloQuery from 'utils/useRayloQuery';
import { FRONTEND_URL } from 'utils/constants';
import { first } from 'lodash-es';
import { useSharedCustomer } from 'utils/useSharedCustomer';

import routes from 'utils/routes';
import { useErrorNavigator } from 'utils/useErrorNavigator';

export const Go = () => {
  const { removeDomainCookie, readDomainCookie, setDomainCookie } =
    useSharedCustomer();

  store.remove('checkoutToken');

  // HOOKS
  const { checkoutToken } = useParams();
  const history = useHistory();
  const errorNav = useErrorNavigator();

  // GRAPHQL
  const {
    data: { checkout },
  } = useRayloQuery(QL_CHECKOUT_STATE, {
    variables: {
      token: checkoutToken,
    },
    onError: (error) => {
      if (store.get('userToken') && first(error.graphQLErrors)) {
        window.location = '/account';
      } else if (first(error.graphQLErrors)?.type === 'UNAUTHORIZED') {
        window.location = '/account';
      } else if (first(error.graphQLErrors)?.type === 'NOT_FOUND') {
        window.location = FRONTEND_URL;
      }
    },
  });

  const [resumeCheckout] = useMutation(MU_RESUME_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          resumeCheckout: { checkout, errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        errorNav('GraphQL resume validation error', { errors: errors });
      }
    },
  });

  useEffect(() => {
    removeDomainCookie('checkoutToken');

    if (!!checkout) {
      store.set('checkoutToken', checkoutToken);
      setDomainCookie('checkoutToken', checkoutToken);
      switch (checkout.state) {
        case 'ABANDONED':
          resumeCheckout({ variables: { checkoutToken } });
          break;
        default:
          history.push(routes(checkout));
          break;
      }
    }
  }, [checkout, resumeCheckout, checkoutToken, history]);

  return <Modal visible text="Loading..." />;
};
