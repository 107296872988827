import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const StyledEndOfLeaseOptionsBanner = styled.div`
  padding-top: ${px2Rem(24)};
  background-color: ${primaryColors.b01};
`;

export const StyledEndOfLeaseOptionsBannerContent = styled.div`
  padding: 24px;
  background-color: ${primaryColors.b01};
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${px2Rem(450)};
  margin: auto;
`;

export const StyledOptionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: ${primaryColors.w01};
  font-size: ${px2Rem(14)};
  font-weight: 500;
`;

export const StyledOptionCircle = styled.div`
  display: flex;
  height: ${px2Rem(56)};
  width: ${px2Rem(56)};
  align-items: center;
  justify-content: center;
  border-radius: ${px2Rem(100)};
  background-color: ${secondaryColors.b02};
  margin-bottom: ${px2Rem(8)};
`;
