import * as React from 'react';
import { SVGProps, memo } from 'react';

interface IRightLineArrowIconProps extends SVGProps<SVGSVGElement> {
  color: string;
  height: number;
  width: number;
}

const RightLineArrowIcon = ({
  color,
  height,
  width,
}: IRightLineArrowIconProps) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.335 7.5h13.333M8.446 13.722 14.668 7.5 8.446 1.278"
    />
  </svg>
);
const Memo = memo(RightLineArrowIcon);
export default Memo;
