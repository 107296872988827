import { useEffect, useState } from 'react';
import { GET_MERCHANT_ID } from 'screens/Checkout/graphQL/queries';
import constate from 'constate';
import { useLazyQuery } from '@apollo/client';
import { getQueryParam } from './getQueryParam';
import { tryGetSessionStorageObject } from './handleSessionStorage';

const useMerchant = () => {
  const [domain, setDomain] = useState<string | null>(null);

  useEffect(() => {
    setDomain(getQueryParam('merchant_domain') || window?.location?.hostname);
  }, []);

  const [getMerchant, { data }] = useLazyQuery(GET_MERCHANT_ID, {
    variables: { domain },
  });

  useEffect(() => {
    if (domain) {
      getMerchant();
    }
  }, [domain, getMerchant]);

  return {
    merchantId:
      tryGetSessionStorageObject<{ value: string }>('TestMerchant')?.value ||
      data?.merchant?.id,
  };
};

const [MerchantProvider, useMerchantContext] = constate(useMerchant);
export { MerchantProvider, useMerchantContext };
