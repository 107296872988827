import { filter, merge, pick } from 'lodash-es';
import { useEffect, useState } from 'react';
import constate from 'constate';
import { useMutation } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { IAddress } from '../types';

const useCheckoutStepAddress = () => {
  const [address, setAddress] = useState<IAddress>();
  const [newAddress, setNewAddress] = useState<IAddress>();
  const [previousAddress, setPreviousAddress] = useState<IAddress>();
  const [newAddressSet, setNewAddressSet] = useState<boolean>();
  const [addressCheck, setAddressCheck] = useState<boolean>(false);
  const [onSuccess, setOnSuccess] = useState<() => void>();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>();
  const [addPreviousAddress, setAddPreviousAddress] = useState<boolean>(false);

  const { checkoutToken } = useCheckoutContext();
  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepAddress.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [updateMutation] = useMutation(CheckoutSteps.stepAddress.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(filter(errors, { field: 'base' }));
      } else {
        setFormSubmitted(true);
      }
    },
  });

  const [updateAddressCheckMutation] = useMutation(
    CheckoutSteps.stepAddressCheck.mutation,
    {
      update: (
        proxy,
        {
          data: {
            updateCheckout: { errors },
          },
        },
      ) => {
        if (errors && errors.length > 0) {
          setFormErrors(filter(errors, { field: 'base' }));
        } else {
          setAddPreviousAddress(false);
          setFormSubmitted(true);
        }
      },
    },
  );

  useEffect(() => {
    if (checkout) {
      if (checkout.address) {
        setAddress(
          merge(
            pick(
              checkout.address,
              'line1',
              'line2',
              'line3',
              'city',
              'region',
              'postcode',
            ),
            { countryIsoAlpha2: checkout.address.country.isoAlpha2 },
          ),
        );
      } else if (checkout.customer && checkout.customer.address) {
        setAddress(
          merge(
            pick(
              checkout.customer.address,
              'line1',
              'line2',
              'line3',
              'city',
              'region',
              'postcode',
            ),
            { countryIsoAlpha2: checkout.customer.address.country.isoAlpha2 },
          ),
        );
      }
    }
  }, [checkout]);

  const onSubmit = () => {
    const orderAddress = newAddress || address;
    updateMutation({
      variables: {
        ...orderAddress,
        checkoutToken: checkout.token,
      },
    });
    return false;
  };

  const handleAddPreviousAddress = () => {
    setAddPreviousAddress(true);
    setAddressCheck(false);
    onSubmit();
  };

  const onSubmitPreviousAddress = () => {
    setAddPreviousAddress(false);
    updateAddressCheckMutation({
      variables: {
        ...previousAddress,
        recentChangeOfAddress: true,
        recentChangeOfCountry: false,
        checkoutToken: checkout.token,
      },
    });
  };

  return {
    loading,
    address,
    newAddress,
    previousAddress,
    newAddressSet,
    addressCheck,
    editAddress,
    addPreviousAddress,
    onSuccess,
    onSubmitPreviousAddress,
    setAddress,
    setPreviousAddress,
    setNewAddress,
    onSubmit,
    handleAddPreviousAddress,
    formSubmitted,
    formErrors,
    setAddressCheck,
    setNewAddressSet,
    setEditAddress,
    setOnSuccess,
  };
};

const [CheckoutAddressProvider, useCheckoutAddressContext] = constate(
  useCheckoutStepAddress,
);
export { CheckoutAddressProvider, useCheckoutAddressContext };
