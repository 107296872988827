import styled from 'styled-components';
import { secondaryColors } from 'styles/variables/colors';
import colors from 'styles/colors';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const TradeInTerms = ({ visible, onBack }) => {
  return (
    <TradeInTermsText>
      <div>
        <p>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE TRADING IN
          YOUR DEVICE
        </p>
        <p>
          <strong>1. WHAT'S IN THESE TERMS?</strong>
        </p>
        <p>
          These terms and conditions (hereinafter referred to as the "Terms")
          apply when you trade in your used device (the "
          <strong>Trade-In Device</strong>") to Raylo Group Limited, our group
          companies or our representatives ("we", "our" or "us") in return for
          monthly cashback (the "<strong>Trade-In Cashback</strong>").
        </p>
        <p>
          Nothing in these Terms constitutes an offer by us to sell or purchase
          any goods or services from you. Sending us your Trade-In Device
          constitutes an offer by you to sell the device to us. However, no
          contract will be formed until we confirm that we have accepted your
          Trade-In Device.
        </p>
        <p>
          Please take care to ensure the Trade-In Device condition and
          specification you provide us are accurate. This will ensure the
          estimate of your Trade-In Cashback we provide (the "Trade-In
          Estimate") is correct.
        </p>
        <p>
          <strong>2. WHO WE ARE AND HOW TO CONTACT US </strong>
        </p>
        <p>
          Raylo Group Limited is registered in England and Wales under company
          number 11554120 and we have our registered office at 5 New Street
          Square, London, EC4A 3TW.
        </p>
        <p>
          To contact us, please email{' '}
          <a href="mailto:help@raylo.com">help@raylo.com</a> or call 0333 50
          9880.{' '}
        </p>
        <p>
          <strong>3. THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</strong>
        </p>
        <p>
          These Terms refer to the following additional terms, which also apply
          when you trade in your used device with us.
        </p>
        <p>
          Our <a href="https://www.raylo.com/legal">Privacy</a>
          <a href="https://www.raylo.com/legal"> Policy</a>, which sets out the
          terms on which we process any personal data we collect from you, or
          that you provide to us.
        </p>
        <p>
          Our <a href="https://www.raylo.com/legal">Website</a>
          <a href="https://www.raylo.com/legal"> Terms of Use</a>, which sets
          out the rules for using our website.
        </p>
        <p>
          Any contract with us in respect of providing you with other goods
          and/or services shall be subject to separate terms and conditions,
          which are relevant to the applicable goods and/or services.
        </p>
        <p>
          <strong>4. WE MAY MAKE CHANGES TO THESE TERMS </strong>
        </p>
        <p>
          We amend these Terms from time to time. Every time you wish to trade
          in a device, please check these Terms to ensure you understand the
          terms that apply at that time. These Terms were most recently updated
          on 25/02/2021.
        </p>
        <p>
          <strong>5. TRADE-IN CASHBACK AMOUNT</strong>
        </p>
        <p>
          The amount of your Trade-In Cashback will depend on the specification
          and condition of your Trade-In Device. We will provide a Trade-In
          Estimate based on the information you provide to us on our website, so
          it is important that this information is accurate.
        </p>
        <p>
          Your order confirmation email will confirm the Trade-In Estimate.
          Paragraph 10 below explains what happens when we receive your device
          and your options if your Trade-In Cashback amount is different to the
          Trade-In Estimate.
        </p>
        <p>
          It is important to note that the condition of your Trade-In Device
          must meet the minimum standards provided in paragraph 7 below to
          receive a Trade-In Cashback.
        </p>
        <p></p>
        <p></p>
        <p>
          <strong>6. PAYMENT OF TRADE-IN CASHBACK</strong>
        </p>
        <p>
          Payment of your Trade-In Cashback will begin in the month after we
          accept your Trade-In Device. Your order confirmation will confirm how
          many monthly Trade-In Cashback payments you will receive.{' '}
        </p>
        <p></p>
        <p></p>
        <p>
          <strong>7. TRADE-IN DEVICE MINIMUM STANDARDS</strong>
        </p>
        <p>
          To receive Trade-In Cashback your Trade-In Device must meet all of the
          following standards:
        </p>
        <ul type="disc">
          <li>
            device must be original and meet the manufacturer&rsquo;s original
            specifications;
          </li>
          <li>device must not be locked to a non-UK network;</li>
          <li>you must own the device.</li>
        </ul>
        <p></p>
        <p></p>
        <p>
          <strong>8. SENDING US YOUR TRADE-IN DEVICE</strong>
        </p>
        <p>
          We recommend you use another device to take photographs of your
          Trade-In Device at the time you send it to us to verify its condition.
          Please ensure one of these photographs captures the IMEI number.
        </p>
        <p>
          After we provide your Trade-In Estimate we will provide shipping
          instructions for your Trade-In Device. We are not responsible for any
          loss or damage incurred to your device from the time it leaves you
          until it is received at our warehouse and it is your responsibility to
          ensure it is adequately physically protected. Please note if your
          device is collected by DPD you will be covered for up to &pound;100.
        </p>
        <p>
          We will also provide the latest trade-in date for your device, which
          will be no sooner than 14 days from the date of your order
          confirmation email. If we do not receive your device by this date your
          Trade-In Estimate will no longer be valid.
        </p>
        <p>
          Please do not send us any accessories with your device, as we do not
          return accessories that we receive.
        </p>
        <p></p>
        <p>
          <strong>9. YOUR SIM CARD AND THE DATA ON YOUR TRADE-IN DEVICE</strong>
        </p>
        <p></p>
        <p>
          It is your responsibility to remove the SIM card from your Trade-In
          Device and to remove any content or data before sending it to us. If
          you fail to do this you agree we are not responsible for any claims,
          losses or damages relating to the use of the SIM card or any data or
          content arising before, or after we receive the Trade-In Device.
        </p>
        <p>
          We are not responsible for protecting any information on the Trade-In
          Device and it is not possible for you to obtain any content from the
          Trade-In Device, whether stored on the device or memory card, once it
          has been sent to us. We strongly recommend you back up the Trade-In
          Device before you send it to us.
        </p>
        <p>Please note that we do not return any SIM cards that we receive.</p>
        <p></p>
        <p>
          <strong>
            10. WHAT HAPPENS WHEN WE RECEIVE YOUR TRADE-IN DEVICE?
          </strong>
        </p>
        <p>
          When we receive your Trade-In Device, we will inspect it and calculate
          the Trade-In Cashback based on its condition and specification.
        </p>
        <p>
          If we accept your Trade-In Device, a contract will be formed, and we
          will not be able to return the Trade-In Device to you. This does not
          affect your rights to cancel any other contract you may have with us.
        </p>
        <p>
          If we do not accept your Trade-In Device, a contract will not be
          formed, and your Trade-In Device will be returned to you free of
          charge within 7 working days.
        </p>
        <p>
          10.1. When the Trade-In Cashback is the same as the Trade-In Estimate
        </p>
        <ul type="disc">
          <li>We will send you an email confirming the Trade-In Cashback.</li>
          <li>We will accept your Trade-In Device.</li>
          <li>You will receive payment according to Paragraph 6 above.</li>
          <li>No further action is required by you.</li>
        </ul>
        <p>
          10.2. If the Trade-In Cashback is different to the Trade-In Estimate
        </p>
        <ul type="disc">
          <li>We will send you an email confirming the Trade-In Cashback.</li>
          <li>You will have 7 days to accept the Trade-In Cashback.</li>
          <li>
            If we receive confirmation from you within 7 days that you accept
            the Trade-In Cashback, we will accept your Trade-In Device and you
            will receive payment according to Paragraph 6 above.
          </li>
          <li>
            Otherwise, we will not accept the Trade-In Device and it will be
            returned to you free of charge within 7 working days.
          </li>
          <li>
            Please note if we return the Trade-In Device to you, we are not
            responsible for any loss or damage incurred to it from the time it
            leaves us.
          </li>
        </ul>
        <p>10.3. What happens if we reject your Trade-In Device?</p>
        <p>
          Unfortunately, if any of the following circumstances arise, we will
          email you to tell you that your Trade-In Device has not been accepted.
        </p>
        <ul type="disc">
          <li>
            There are security settings (such as Find My iPhone) remaining on
            your Trade-In Device, which prevent access to it for testing
            purposes.
          </li>
          <li>
            Your trade-In Device does not meet the minimum standards defined in
            Paragraph 7 above.
          </li>
          <li>
            The device we receive is different to the specification you provided
            to us when we provided the Trade-In Estimate.
          </li>
        </ul>
        <p>
          We also reserve the right to reject your Trade-In Device for any other
          reason.
        </p>
        <p>
          If we reject your Trade-In Device, it will be returned to you free of
          charge within 7 working days.
        </p>
        <p>10.4. "Lost, Blocked or Stolen Devices" and "Fake Devices"</p>
        <p>
          "Lost, Blocked or Stolen Devices" are devices that have been reported
          as either lost, blocked or stolen on any of the stolen asset registers
          maintained by CheckMEND, which is operated by Recipero Limited (
          <a href="https://www.checkmend.com/uk">
            https://www.checkmend.com/uk
          </a>
          <u>)</u>.
        </p>
        <p>
          Any Trade-In Devices received which are "Lost, Blocked or Stolen
          Devices" will be:
        </p>
        <ul type="disc">
          <li>dealt with in accordance with Home Office guidelines;</li>
          <li>
            held pending a claim from the Police, an insurance company or the
            registered owner; and
          </li>
          <li>
            result in the immediate{' '}
            <strong>cancellation of any Trade-In Cashback payments</strong>.
          </li>
        </ul>
        <p>
          <strong></strong>
        </p>
        <p>
          "Fake Devices" are counterfeit devices which are manufactured to
          resemble products made by another company in breach of copyright and
          intellectual property rights. If we discover that a Trade-In Device is
          a Fake Device, we will immediately{' '}
          <strong>cancel any Trade-In Cashback payments</strong>.
        </p>
        <p>
          We may reclaim any Trade-In Cashback that has already paid to you if
          the Trade-In Device has either been reported Lost, Blocked or Stolen
          or the Trade-In Device is discovered to be a Fake Device.
        </p>
        <p>
          We will also inform the relevant authorities if there is any suspicion
          that there is a deliberate attempt to commit fraud.{' '}
        </p>
        <p></p>
        <p></p>
        <p>
          <strong>11. EVENTS OUTSIDE OF OUR CONTROL</strong>
        </p>
        <p>
          Unfortunately, sometimes things happen which we cannot control. We
          will not be liable or responsible to you for any failure or delay in
          providing the services or meeting any of our obligations under these
          terms caused by events outside of our control or due to our compliance
          with any applicable laws or regulations.
        </p>
        <p></p>
        <p></p>
        <p>
          <strong>12. GENERAL MATTERS</strong>
        </p>
        <p>
          Our services are supplied to you on an "as is" basis and we make no
          warranties, express or implied, regarding their satisfactory quality,
          fitness for a particular purpose, suitability, reliability,
          timeliness, accuracy, completeness, security or that they are free
          from error. We try to ensure the information we provide is accurate,
          but your access to the services and any action you carry out on the
          basis of information you obtain from us is carried out entirely at
          your own risk and we accept no liability for any losses that you may
          suffer as a result.{' '}
        </p>
        <p>
          We will not be liable for any loss of use, profits or data or any
          indirect, special or consequential damages or losses, whether such
          losses or damages arise in contract, negligence or tort, or otherwise.
        </p>
        <p>
          All terms implied by law, whether by statute or at common law, are
          hereby excluded. However, nothing in these terms is intended to
          exclude or limit our liability for death or personal injury, fraud or
          any other liability which we may not by law exclude or limit.
        </p>
        <p>
          No contract will be formed until we confirm that we have accepted your
          Trade-In Device. If a contract is formed, it is an unregulated sale of
          goods agreement.{' '}
        </p>
        <p>
          Title in your Trade-In Device remains with you until it is accepted by
          us, at which point title immediately transfers to us. Paragraph 10
          describes the conditions required for us to accept your Trade-In
          Device.{' '}
        </p>
        <p>
          These terms will be governed by and interpreted in accordance with
          English law and you and we both consent to the exclusive jurisdiction
          of the English courts.
        </p>
      </div>
    </TradeInTermsText>
  );
};

const TradeInTermsText = styled.div`
  color: ${colors.rayloDark};

  h1,
  h2,
  h3 {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    margin: 3em 0 1em 0;
    line-height: 1.625;
  }
  h1 {
    font-size: 1.1em;
    margin-top: 0;
  }
  h2 {
    font-size: 0.875em;
    color: ${colors.rayloPink};
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  }
  h2 + h3 {
    margin-top: 0em;
  }
  h3 {
    font-size: 0.875em;
  }

  th {
    color: ${colors.rayloPink};
  }

  td {
    color: ${colors.rayloDark};
  }

  td,
  th {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.875em;
  }

  a {
    color: ${secondaryColors.p02};
  }

  p,
  li {
    color: ${colors.rayloDark};
    font-size: 0.875em;
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    line-height: 1.625;
    margin-bottom: 1em;

    span.generated {
      color: ${secondaryColors.p02};
    }
  }
  li {
    margin-bottom: 0;
  }
`;
