import * as React from 'react';
import { memo } from 'react';

const CarbonIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.845 14a4.97 4.97 0 0 1-4.97-4.97c0-7.208 8.673-3.505 12.851-6.97a.304.304 0 0 1 .317 0 .337.337 0 0 1 .198.25C16.515 9.744 11.017 14 6.845 14Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.832 7.703a16.501 16.501 0 0 0-5.684 2.832L.574 12.733"
    />
  </svg>
);

const Memo = memo(CarbonIcon);
export default Memo;
