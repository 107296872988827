interface Checkout {
  items: CheckoutItem[];
}

interface CheckoutItem {
  variant: Variant;
}

interface Variant {
  id: string;
  displayName: string;
}

interface SegmentProduct {
  id: string;
  variant: string;
}

export default function mapCheckoutToSegmentProducts(
  checkout: Checkout,
): SegmentProduct[] {
  return checkout.items.map(
    (item: CheckoutItem): SegmentProduct => ({
      id: item.variant.id,
      variant: item.variant.displayName,
    }),
  );
}
