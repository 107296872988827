import {
  Container,
  Header,
  OrderPlacedAnimation,
  SectionMessage,
  Spacer,
  TextLink,
} from '@raylo-tech/raylopay-ui';
import { Countdown } from 'components/Countdown/Countdown';
import { Copy } from 'elements';
import { useEffect } from 'react';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import CheckoutDecisionWrapper from '../../CheckoutDecisionWrapper';
import { useCheckoutDecisionContext } from '../../hooks/useCheckoutDecisionContext';
import { track } from 'integrations/segment/events';

export const DecisionAcceptedMerchantContent = () => {
  const { merchantName, handleRedirect, redirectUrl } =
    useCheckoutDecisionContext();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 10000);
    track('Purchase');

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <CheckoutDecisionWrapper>
      <Container
        maxWidth={px2Rem(414)}
        padding={`0 ${px2Rem(24)} ${px2Rem(48)}`}
      >
        <Container width="100%" center>
          <OrderPlacedAnimation />
        </Container>
        <Spacer height={32} />
        <Header
          color={primaryColors.c01}
          level={2}
          subColor={secondaryColors.c02}
        >
          Thanks for choosing Raylo.
          <span> Your order will be on its way soon</span>
        </Header>
        <Spacer height={32} />
        <Header
          color={primaryColors.c01}
          fontSize={16}
          lineHeight={20}
          level={3}
        >
          We&apos;ll send you a confirmation email shortly.
        </Header>
        <Spacer height={20} />
        <Header
          color={primaryColors.c01}
          fontSize={16}
          lineHeight={20}
          level={3}
        >
          You will be automatically redirected back to {merchantName} in{' '}
          <Countdown seconds={10} /> seconds.
        </Header>
        <Spacer height={20} />
        <Copy color={primaryColors.c01} fontSize={16} lineHeight={20}>
          If nothing happens please click link below:
        </Copy>
        <Spacer height={16} />
        <TextLink
          onClick={handleRedirect}
          fontColor={primaryColors.b01}
          noUnderline
        >
          Back to {merchantName}
        </TextLink>
        <Spacer height={32} />
        <SectionMessage
          copy="Raylo is proud to be B Corporation Certified"
          header="You've made a greener choice"
          icon="RayloDesignCertified"
          iconLeftAlign
        />
      </Container>
    </CheckoutDecisionWrapper>
  );
};
