import {
  ConsumerTypesEnum,
  SectionMessage,
  SectionMessageWithIcon,
  Typography,
} from '@raylo-tech/raylopay-ui';
import TextLink from 'elements/TextLink';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { StyledCOS, StyledCOSItemRow, StyledCOSMainContent } from './styles';
import { px2Rem } from 'utils/px2Rem';
import { leaseTermLabel } from 'utils/leaseTermLabel';
import Divider from 'elements/Divider';
import Currency from 'react-currency-formatter';
import { Copy, Icon } from 'elements';
import { DeliveryEstimate } from 'components/common/DeliveryEstimate';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import { useUiContext } from 'utils/useUiContext';
import { CheckoutStep } from 'screens/Checkout/enums/checkoutSteps';
import { useCheckoutOrderSummaryData } from './useCheckoutOrderSummaryData';
import { CheckoutOrderSummaryCartItem } from './CheckoutOrderSummaryCartItem';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { CheckoutOrderSummaryInfo } from './CheckoutOrderSummaryInfo';
import dayjs from 'dayjs';
import { isTV } from 'screens/Account/utils/parseOrder.helper';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';

type CheckoutOrderSummaryProps = {
  checkoutToken: string;
  displayOnly?: boolean;
};

export const CheckoutOrderSummary = ({
  checkoutToken,
  displayOnly,
}: CheckoutOrderSummaryProps) => {
  const {
    merchantHasChangeProductOption,
    hasDeliveryDate,
    currentStep,
    progressBarSteps,
    setCurrentStep,
    checkoutMode,
    showUpdatedCheckoutSteps,
    consumerType,
    consumerMoneyField,
  } = useCheckoutContext();

  const { setShowSummary, setShowReview } = useUiContext();

  const {
    checkoutHasLoaded,
    checkout,
    costSummary,
    variant,
    variantImageUrl,
    changeProductURL,
    onChangeProduct,
  } = useCheckoutOrderSummaryData({ checkoutToken });

  if (!checkoutHasLoaded) {
    return null;
  }

  const onContinue = () => {
    setShowReview(false);
    setShowSummary(false);
  };

  const onChangeCustomerDetails = () => {
    setShowReview(false);
    setShowSummary(false);

    if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
      setCurrentStep(
        showUpdatedCheckoutSteps
          ? CheckoutStep.STEP_EMAIL
          : CheckoutStep.STEP_NAME_EMAIL,
      );
      return;
    }

    setCurrentStep(CheckoutStep.STEP_ADDRESS);
  };

  // Ony show the continue CTA in the summary if the user is on the first step of the checkout or the sign step
  const canShowContinueCtaInSummary =
    currentStep === progressBarSteps?.[0] ||
    [CheckoutStep.STEP_UPGRADE, CheckoutStep.STEP_SIGN].includes(
      currentStep as CheckoutStep,
    );

  const consumerIsBusiness = consumerType === ConsumerTypesEnum.BUSINESS;

  return (
    <StyledCOS>
      <StyledCOSMainContent>
        {currentStep === CheckoutStep.STEP_SIGN ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: px2Rem(12),
            }}
            data-testid="cos-header-step-sign"
          >
            <Typography variant="h4" bold>
              Your order
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: px2Rem(16),
                alignItems: 'flex-end',
              }}
            >
              <Typography>{variant.displayName}</Typography>
              {!displayOnly &&
                merchantHasChangeProductOption &&
                changeProductURL && (
                  <div style={{ flexShrink: 0 }}>
                    <TextLink
                      dataTestId="ChangeProduct"
                      onClick={onChangeProduct}
                      fontColor="var(--cos-link-color)"
                      medium
                    >
                      Change Product
                    </TextLink>
                  </div>
                )}
            </div>
          </div>
        ) : (
          <CheckoutOrderSummaryCartItem
            displayOnly={displayOnly}
            variantDisplayName={variant.displayName}
            variantImageUrl={variantImageUrl}
            changeProductURL={changeProductURL}
            merchantHasChangeProductOption={merchantHasChangeProductOption}
            onChangeProduct={onChangeProduct}
          />
        )}

        <Divider color={secondaryColors.c04} width="100%" />

        {isTV(variant.product.category) && (
          <SectionMessageWithIcon
            dataTestId="cos-tv-shipping-warning"
            icon="RayloDesignWarning"
            iconLeftAlign
            borderColor={secondaryColors.y04}
            backgroundColor={secondaryColors.y05}
            header="Please note"
            content={
              <Copy>
                At this time we cannot deliver TVs to:
                <br />
                Northern Ireland, the Scottish Highlands and the Scottish
                Islands.
              </Copy>
            }
          />
        )}

        {currentStep === CheckoutStep.STEP_SIGN &&
          (checkout.customerInfo ||
            checkout.address ||
            checkout.paymentInfo) && (
            <>
              <StyledCOSItemRow>
                <Typography variant="h4" bold>
                  Your details
                </Typography>
                <TextLink
                  dataTestId="ChangeCustomerDetails"
                  onClick={onChangeCustomerDetails}
                  fontColor="var(--cos-link-color)"
                  medium
                >
                  Edit
                </TextLink>
              </StyledCOSItemRow>

              {checkout.customerInfo && (
                <CheckoutOrderSummaryInfo
                  dataTestId="cos-info-customer"
                  title={
                    checkout.customerInfo.firstName ||
                    checkout.customerInfo.lastName
                      ? [
                          checkout.customerInfo.firstName,
                          checkout.customerInfo.lastName,
                        ].join(' ')
                      : ''
                  }
                  rows={[
                    ...(checkout.customerInfo.dateOfBirth
                      ? [
                          `Date of birth: ${dayjs(
                            checkout.customerInfo.dateOfBirth,
                          ).format('DD/MM/YY')}`,
                        ]
                      : []),
                    ...(checkout.customerInfo.mobilePhoneNumber
                      ? [
                          checkout.customerInfo.mobilePhoneNumber.replace(
                            '+44',
                            '0',
                          ),
                        ]
                      : []),
                    ...(checkout.customerInfo.email
                      ? [checkout.customerInfo.email]
                      : []),
                  ]}
                />
              )}

              {checkout.address && (
                <CheckoutOrderSummaryInfo
                  dataTestId="cos-info-address"
                  title="Delivery & billing address"
                  rows={[
                    ...(checkout.address.line1 ? [checkout.address.line1] : []),
                    ...(checkout.address.line2 ? [checkout.address.line2] : []),
                    ...(checkout.address.line3 ? [checkout.address.line3] : []),
                    ...(checkout.address.city ? [checkout.address.city] : []),
                    ...(checkout.address.region
                      ? [checkout.address.region]
                      : []),
                    ...(checkout.address.postcode
                      ? [checkout.address.postcode]
                      : []),
                  ]}
                />
              )}

              {checkout.paymentInfo && (
                <CheckoutOrderSummaryInfo
                  dataTestId="cos-info-bank-details"
                  title="Direct Debit details"
                  rows={[
                    ...(checkout.paymentInfo.bankAccountName
                      ? [
                          `Account name: ${checkout.paymentInfo.bankAccountName}`,
                        ]
                      : []),
                    ...(checkout.paymentInfo.bankAccountNumber
                      ? [
                          `Account number: ${checkout.paymentInfo.bankAccountNumber.replaceAll(
                            '*',
                            '•',
                          )}`,
                        ]
                      : []),
                    ...(checkout.paymentInfo.bankBranchIdentifier
                      ? [
                          `Sort code: ${checkout.paymentInfo.bankBranchIdentifier
                            .replaceAll('*', '•')
                            .split('')
                            .flatMap((char, charIndex, charArray) =>
                              // Add in a dash every second character, but not the last character
                              (charIndex + 1) % 2 === 0 &&
                              charIndex !== charArray.length - 1
                                ? [char, '-']
                                : [char],
                            )
                            .join('')}`,
                        ]
                      : []),
                  ]}
                />
              )}

              <Divider color="var(--cos-divider-color)" width="100%" />
            </>
          )}

        <StyledCOSItemRow data-testid="cos-row-lease-term">
          <Typography variant="h4" bold>
            Lease term
          </Typography>
          <div
            style={{ display: 'flex', gap: px2Rem(10), alignItems: 'center' }}
          >
            <Typography
              variant="fineprint"
              style={{
                color: primaryColors.c01,
                backgroundColor: secondaryColors.c05,
                padding: `${px2Rem(4)} ${px2Rem(6)}`,
                borderRadius: px2Rem(4),
              }}
            >
              {leaseTermLabel(costSummary.termLength)}
            </Typography>
            <TextLink
              onClick={onChangeProduct}
              fontColor="var(--cos-link-color)"
              medium
            >
              Edit
            </TextLink>
          </div>
        </StyledCOSItemRow>

        <Divider color={secondaryColors.c04} width="100%" />

        <StyledCOSItemRow>
          <Typography variant="h4" bold>
            Items
          </Typography>
        </StyledCOSItemRow>

        <StyledCOSItemRow data-testid="cos-row-lease-cost">
          <Typography>Raylo lease</Typography>
          <div>
            <Typography element="span" bold>
              <Currency
                currency={costSummary.currencyCode}
                quantity={
                  costSummary.recurring.adjustmentAmount &&
                  costSummary.recurring.adjustmentAmount[consumerMoneyField]
                    .value > 0
                    ? costSummary.recurring.adjustmentAmount[consumerMoneyField]
                        .value +
                      costSummary.recurring.baseAmount[consumerMoneyField].value
                    : costSummary.recurring.baseAmount[consumerMoneyField].value
                }
              />
              /
            </Typography>
            <Typography element="span">mo</Typography>
          </div>
        </StyledCOSItemRow>

        {costSummary.recurring?.insuranceAmount && (
          <StyledCOSItemRow data-testid="cos-row-insurance-cost">
            <Typography>
              Raylo Insurance <br />
              Loss, theft & accidental damage
            </Typography>
            <div>
              <Typography element="span" bold>
                <Currency
                  currency={costSummary.currencyCode}
                  quantity={
                    costSummary.recurring?.insuranceAmount[consumerMoneyField]
                      .value
                  }
                />
                /
              </Typography>
              <Typography element="span">mo</Typography>
            </div>
          </StyledCOSItemRow>
        )}

        <StyledCOSItemRow data-testid="cos-row-delivery-cost">
          <Typography>Delivery</Typography>
          <Typography bold>
            <Currency currency={costSummary.currencyCode} quantity={0} />
          </Typography>
        </StyledCOSItemRow>

        <Divider color={secondaryColors.c04} width="100%" />

        <StyledCOSItemRow data-testid="cos-row-upfront-cost">
          <Typography variant="h4" bold>
            Total upfront today
          </Typography>
          <Typography bold>
            <Currency currency={costSummary.currencyCode} quantity={0} />
          </Typography>
        </StyledCOSItemRow>

        <StyledCOSItemRow data-testid="cos-row-total-monthly">
          <Typography variant="h4" bold>
            Total monthly
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: px2Rem(8),
            }}
          >
            <Typography element="span">
              <strong>
                <Currency
                  currency={costSummary.currencyCode}
                  quantity={
                    costSummary.recurring?.totalAmount?.[consumerMoneyField]
                      ?.value
                  }
                />
                /
              </strong>
              mo {consumerIsBusiness && 'excl. VAT'}
            </Typography>
            {consumerIsBusiness && (
              <Typography variant="body2" element="span" color="charcoal.400">
                <Currency
                  currency={costSummary.currencyCode}
                  quantity={
                    costSummary.recurring?.totalAmount?.valueAfterTax?.value
                  }
                />
                /mo inc. VAT
              </Typography>
            )}
          </div>
        </StyledCOSItemRow>

        <SectionMessage
          content={
            <Typography variant="body2" style={{ lineHeight: px2Rem(20) }}>
              Your first payment is due 14 days after your order is confirmed.
            </Typography>
          }
          backgroundColor={secondaryColors.b05}
          borderColor={secondaryColors.b04}
        />

        {checkout.tradeIns.length > 0 &&
          typeof checkout.tradeIns[0].costSummary?.recurring?.amount?.value ===
            'number' && (
            <StyledCOSItemRow data-testid="cos-row-trade-in">
              <div style={{ display: 'flex', gap: px2Rem(8) }}>
                <Icon
                  name="TradeIn"
                  size={px2Rem(24)}
                  color={secondaryColors.p02}
                />
                <Typography>
                  Trade-in cashback <br />
                  direct to your bank
                </Typography>
              </div>
              <div>
                <Typography bold element="span">
                  <Currency
                    quantity={
                      checkout.tradeIns[0].costSummary?.recurring?.amount?.value
                    }
                    currency={
                      checkout.tradeIns[0].costSummary?.recurring?.amount
                        ?.currencyCode
                    }
                  />
                  /
                </Typography>
                <Typography element="span">mo</Typography>
              </div>
            </StyledCOSItemRow>
          )}

        <StyledCOSItemRow>
          <div style={{ display: 'flex', gap: px2Rem(8) }}>
            <Icon
              name="ReturnPackage"
              size={px2Rem(24)}
              color={secondaryColors.p02}
            />
            <Typography>Free to return within 14 days</Typography>
          </div>
          <Typography bold style={{ color: uiStateColors.success }}>
            Free
          </Typography>
        </StyledCOSItemRow>

        {hasDeliveryDate && (
          <StyledCOSItemRow data-testid="cos-row-delivery-date">
            <div style={{ display: 'flex', gap: px2Rem(8) }}>
              <Icon
                name="ShipmentPackage"
                size={px2Rem(20)}
                color={secondaryColors.p02}
              />
              <Typography variant="body1">
                Delivered{' '}
                <DeliveryEstimate delivery={variant.deliveryExpectedBetween} />
              </Typography>
            </div>
            <Typography bold style={{ color: uiStateColors.success }}>
              Free
            </Typography>
          </StyledCOSItemRow>
        )}

        {canShowContinueCtaInSummary && (
          <DoubleButtonFullWidth
            topButtonText={
              currentStep === CheckoutStep.STEP_SIGN
                ? 'Continue to T&Cs'
                : 'Continue'
            }
            topButtonTestId="overview-confirm"
            topButtonOnClick={onContinue}
            singleButton
          />
        )}
      </StyledCOSMainContent>
    </StyledCOS>
  );
};
