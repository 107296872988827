import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import constate from 'constate';
import { CLIENT_ID } from './constants';
import gql from 'graphql-tag';
import store from 'store';

export const MU_TOKEN = gql`
  mutation AuthenticateClientMutation($clientId: String!) {
    authenticateClient(clientId: $clientId) {
      clientAuthToken
    }
  }
`;

const useClient = () => {
  let token = store.get('token');

  const [authenticateClient] = useMutation(MU_TOKEN, {
    variables: {
      clientId: CLIENT_ID,
    },
    update: (
      proxy,
      {
        data: {
          authenticateClient: { clientAuthToken },
        },
      },
    ) => {
      token = clientAuthToken;
      store.set('token', clientAuthToken);
    },
  });
  useEffect(() => {
    if (!token) {
      authenticateClient();
    }
  }, [token, authenticateClient]);

  return {
    token,
  };
};

const [ClientProvider, useClientContext] = constate(useClient);
export { ClientProvider, useClientContext };
