import constate from 'constate';
import { useCallback, useMemo } from 'react';
import useRayloQuery from 'utils/useRayloQuery';
import { GET_CUSTOMER_ACCOUNT_OVERVIEW } from '../Overview/getCustomerAccountOverview.graphql';
import { Order, Variant } from '../../../types/Checkout/types';
import { IParseOrder, parseOrder } from '../utils/parseOrder.helper';
import { checkInArrears } from '../../../helpers/subscriptions/checkInArrears';
import { find } from 'lodash-es';
import { getOptionValue } from '../../../utils/getOptionValue';
import { VariantOptionSlugs } from '../../../types/variants/options/variantOptionSlugs';
import { YourTechStatus } from '../YourTechStatus';
import store from 'store';

const useAccount = () => {
  const userToken = store.get('userToken');

  const {
    data: { customer: customerData },
  } = useRayloQuery(GET_CUSTOMER_ACCOUNT_OVERVIEW, {
    skip: !userToken,
  });

  const customer = useMemo(() => {
    return userToken ? customerData : undefined;
  }, [customerData, userToken]);

  const activeOrderIsPending = useMemo(() => {
    return ['REFERRED', 'PENDING'].includes(
      customer?.preApproval?.checkout?.decision?.outcome,
    );
  }, [customer]);

  const devices = useMemo<IParseOrder[]>(() => {
    if (!customer?.orders) return [];

    return customer.orders
      .map((order: Order) => parseOrder(order))
      .filter((order: Order) => order);
  }, [customer]);

  const referredCheckoutItems = useMemo<IParseOrder[]>(() => {
    const checkout = customer?.preApproval?.checkout;
    const itemsInReferralQueue = checkout?.items;
    if (activeOrderIsPending && !!itemsInReferralQueue) {
      return customer?.preApproval?.checkout?.items?.map(
        ({ variant }: { variant: Variant }) => {
          return {
            model: variant?.displayName,
            image: find(variant?.images, { tag: 'front' }),
            status: 'Pending further checks',
            storage: getOptionValue(variant, VariantOptionSlugs.Storage)
              .displayName,
            color: getOptionValue(variant, VariantOptionSlugs.Colour)
              .displayName,
            watchCaseSize: getOptionValue(
              variant,
              VariantOptionSlugs.WatchCaseSize,
            ).displayName,
            payMonthly:
              checkout?.costSummary?.recurring?.totalAmount?.valueAfterTax
                ?.value,
          };
        },
      );
    }
    return [];
  }, [customer, activeOrderIsPending]);

  const allDevices = useMemo<IParseOrder[]>(() => {
    return [...devices, ...referredCheckoutItems];
  }, [devices, referredCheckoutItems]);

  const hasDeviceInArrears = useMemo(() => {
    return allDevices.some((device) => checkInArrears(device.subscription));
  }, [allDevices]);

  const canAddTech = useMemo(() => {
    if (!customer) return false;

    const preApproved =
      !!customer.preApproval?.checkout || customer.preApproval?.successful;

    return preApproved && !activeOrderIsPending;
  }, [customer, activeOrderIsPending]);

  const findDeviceBySubscriptionId = useCallback(
    (subscriptionId?: string) => {
      return allDevices.find(
        (device) => device.subscription?.id === subscriptionId,
      );
    },
    [allDevices],
  );

  const canUpgrade = useCallback(
    (subscriptionId?: string) => {
      if (!customer || !subscriptionId) return false;

      if (hasDeviceInArrears) return false;

      const targetDevice = findDeviceBySubscriptionId(subscriptionId);
      if (!targetDevice) return false;

      return targetDevice.status === YourTechStatus.upgradeAvailable;
    },
    [customer, hasDeviceInArrears, findDeviceBySubscriptionId],
  );

  return {
    customer,
    canAddTech,
    canUpgrade,
    findDeviceBySubscriptionId,
  };
};

const [AccountProvider, useAccountContext] = constate(useAccount);
export { AccountProvider, useAccountContext };
