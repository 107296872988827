import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';
import { MU_RESUME_CHECKOUT } from '../graphQL/mutations';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator } from 'utils/useErrorNavigator';

import routes from 'utils/routes';

export const CheckoutResume = () => {
  // HOOKS
  const { checkoutToken } = useCheckoutContext();
  const history = useHistory();
  const errorNav = useErrorNavigator();

  const [resumeCheckout] = useMutation(MU_RESUME_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          resumeCheckout: { checkout, errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        errorNav('GraphQL Resume validation error', errors);
      } else {
        history.push(routes(checkout));
      }
    },
  });

  useEffect(() => {
    resumeCheckout({ variables: { checkoutToken } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Modal visible text="Loading..." />;
};
