import { px2Rem } from 'utils/px2Rem';
import { Loader } from 'components/common/Loader';
import { Container, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import React from 'react';

export const ReturnsRedirectLoading = () => {
  return (
    <Container center height={px2Rem(400)} width="auto">
      <Loader type="quad" />
      <Spacer height={32} />
      <Copy textAlign="center">
        Please wait while we take you to our returns platform to choose your
        return option
      </Copy>
    </Container>
  );
};
