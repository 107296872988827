import { useEffect } from 'react';
import { Header, SectionMessage, Spacer } from '@raylo-tech/raylopay-ui';
import { Container, Copy } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { useCheckoutDecisionContext } from '../hooks/useCheckoutDecisionContext';
import { CheckoutDecisionWrapper } from '../CheckoutDecisionWrapper/CheckoutDecisionWrapper';
import { BackToMerchant } from '../BackToMerchant/BackToMerchant';
import DownsellContainer from 'screens/Checkout/containers/DownsellContainer/DownsellContainer';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { SHOW_DOWNSELL } from 'utils/constants';

export const DecisionDeclined = () => {
  const { loading, redirectUrl, originalRecurringAmount } =
    useCheckoutDecisionContext();
  const { checkoutMode } = useCheckoutContext();

  const growthbook = useGrowthBook();
  const showDownsell = growthbook?.getFeatureValue(SHOW_DOWNSELL, false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (redirectUrl) {
      timeoutId = setTimeout(() => {
        window.location.replace(redirectUrl);
      }, 10000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [redirectUrl]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (
    originalRecurringAmount > 20 &&
    checkoutMode === CheckoutMode.NEW_CUSTOMER &&
    showDownsell
  ) {
    return <DownsellContainer />;
  }

  return (
    <CheckoutDecisionWrapper>
      <Container
        maxWidth={px2Rem(414)}
        padding={`0 ${px2Rem(24)} ${px2Rem(48)}`}
      >
        <Header
          level={1}
          fontSize={24}
          color={primaryColors.c01}
          dataTestId="checkoutDeclinedHeader"
        >
          Unfortunately we are not able to continue with your order
        </Header>
        <Spacer height={32} />
        <Copy bold fontSize={16} color={primaryColors.c01}>
          We&rsquo;ve cancelled your order and will not take any payments from
          you.
        </Copy>
        <Spacer height={16} />
        <SectionMessage
          bulletList={[
            'Check you are on the electoral register',
            'Check the information held at the credit reference agencies: Experian, Transunion or Equifax.',
          ]}
          header="Based on information held at the credit reference agencies, we are unable to continue with your order. We cannot provide any further information on this decision but we recommend you do the following:"
          icon="RayloDesignInfo"
          iconSize={16}
        />
        {redirectUrl && <BackToMerchant />}
      </Container>
    </CheckoutDecisionWrapper>
  );
};
