import styled from 'styled-components';
import { BrowserBackTransientProps } from './BrowserBackArrow.types';
import { px2Rem } from 'utils/px2Rem';

export const StyledBrowserBackArrow = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  margin: 0 0 0 ${px2Rem(16)};
`;

export const StyledBrowserBackArrowText = styled.span<BrowserBackTransientProps>`
  color: ${({ $color }) => $color};
  font-size: ${px2Rem(14)};
  font-weight: 400;
  text-decoration: underline;
`;
