import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import {
  BadgeColors,
  BadgeFontTransformations,
  BadgeProps,
  TransientBadgeProps,
} from './Badge.types';

export const StyledBadge = styled.div<TransientBadgeProps>`
  ${({ $fontWeight, $type, $color, $fontSize, $textTransform }) => `
    padding: ${px2Rem(5)} ${px2Rem(8)};
    background-color: ${backgroundColor($color)};;
    color: ${contentColor($color)};
    height: ${px2Rem(28)};
    font-weight: ${$fontWeight};
    font-size: ${px2Rem($fontSize)};
    border-radius: ${$type === 'rounded' ? px2Rem(4) : '0'};
    width: fit-content;
    box-sizing: border-box;
    text-transform: ${$textTransform};
    display: table-cell;
    vertical-align: middle;
`}
`;

export const StyledBadgeWrap = styled.div<{ $color: BadgeColors }>`
  ${({ $color }) => `
    border-bottom: ${px2Rem(6)} solid transparent;
    border-right: ${px2Rem(6)} solid ${wrapColor($color)};
    height: 0;
    width: 0;
`}
`;

export const StyledBadgeIconWrapper = styled.div`
  vertical-align: top;
  display: inline-block;
  margin-right: ${px2Rem(6)};
  margin-top: ${px2Rem(3)};
`;

export const StyledBadgeText = styled.div<{ $textTransform: BadgeFontTransformations }>`
  ${({ $textTransform }) => `
    display: inline-block;
    margin-top: ${px2Rem(1)};
    letter-spacing: ${$textTransform === 'uppercase' ? px2Rem(1) : 0};
`}
`;

function backgroundColor(color: BadgeColors) {
  switch (color) {
    case 'blue':
      return palette.blue[400];
    case 'darkGrey':
      return palette.charcoal[400];
    case 'lightGrey':
      return palette.charcoal[100];
    default:
      throw new Error('Badge color not implemented');
  }
}

export function contentColor(color: BadgeColors): string {
  switch (color) {
    case 'blue':
      return palette.white;
    case 'darkGrey':
      return palette.white;
    case 'lightGrey':
      return palette.charcoal[400];
    default:
      throw new Error('Badge color not implemented');
  }
}

function wrapColor(color: BadgeColors) {
  switch (color) {
    case 'blue':
      return palette.blue[500];
    case 'darkGrey':
      return palette.charcoal[500];
    case 'lightGrey':
      return palette.charcoal[200];
    default:
      throw new Error('Badge color not implemented');
  }
}
