import React, { useState } from 'react';
import styled from 'styled-components';
import colors from 'styles/colors';
import Clipboard from 'react-clipboard.js';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

// Icons
import TextLink from 'elements/TextLink';
import Copy from 'elements/Copy';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import Icon from 'elements/Icon';

export const ShareLink = ({ link, description }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const copySuccess = () => {
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const quote = `Join Raylo and get a ${description}`;
  const whatsappText = `Hey, if you sign up to Raylo today we'll both get a ${description}.`;
  const emailSubject = `Join me on Raylo and get a ${description}`;
  const emailBody = `Join me on Raylo and get a ${description}.\n\nThe Raylo subscription offers a selection of the latest and greatest phones for a low monthly price. Their phones are SIM - free and unlocked, it's super simple to sign up and great for the environment too.\n\n`;

  return (
    <div>
      <div>
        <Clipboard
          data-clipboard-text={link}
          component={ShareLinkBox}
          onSuccess={() => copySuccess()}
          style={{
            border: 'none',
            padding: '0',
          }}
        >
          <TextLink>{link}</TextLink>
        </Clipboard>

        <ShareLinkSuccess visible={linkCopied}>Link copied!</ShareLinkSuccess>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            marginRight: px2Rem(16),
          }}
        >
          <Copy color={primaryColors.c01}>Share via:</Copy>
        </span>
        <ShareLinkOptions>
          <ShareLinkOption>
            <WhatsappShareButton
              url={link}
              title={whatsappText}
              separator=" "
              windowHeight={650}
              windowWidth={750}
            >
              <Icon
                name="Whatsapp"
                size={px2Rem(24)}
                color={primaryColors.c01}
              />
            </WhatsappShareButton>
          </ShareLinkOption>

          <ShareLinkOption>
            <EmailShareButton
              url={link}
              subject={emailSubject}
              body={emailBody}
              separator=" "
            >
              <Icon name="Email" size={px2Rem(24)} color={primaryColors.c01} />
            </EmailShareButton>
          </ShareLinkOption>

          <ShareLinkOption>
            <TwitterShareButton url={link} title={quote}>
              <Icon
                name="Twitter"
                size={px2Rem(24)}
                color={primaryColors.c01}
              />
            </TwitterShareButton>
          </ShareLinkOption>

          <ShareLinkOption>
            <FacebookShareButton quote={quote} url={link}>
              <Icon
                name="Facebook"
                size={px2Rem(24)}
                color={primaryColors.c01}
              />
            </FacebookShareButton>
          </ShareLinkOption>
        </ShareLinkOptions>
      </div>
    </div>
  );
};

const ShareLinkBox = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 10px 18px;
  border: 1px solid ${colors.raylo3Dark};
  transition: border 0.35s;

  &:hover {
    border: 1px solid ${colors.rayloLight};
  }
`;

const ShareLinkURL = styled.span`
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  color: ${colors.rayloPink};
  line-height: 2.14;
  word-break: break-all;

  &::-moz-selection {
    color: ${colors.rayloLight};
    background-color: ${colors.rayloBlue};
  }
  &::selection {
    color: ${colors.rayloLight};
    background-color: ${colors.rayloBlue};
  }
`;

const ShareLinkHeading = styled.h5`
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  font-size: 0.875em;
  color: ${colors.rayloLight};
  margin-right: 18px;
`;

const ShareLinkOptions = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const ShareLinkOption = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 28px;
  }
`;

const ShareLinkSuccess = styled.p`
  opacity: ${(props) => (props.visible ? '1' : '0')};
  margin-top: 2px;
  margin-bottom: 0;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  color: ${secondaryColors.p02};
  letter-spacing: 0.5px;
  line-height: 2;
  text-align: left;
  transition: opacity 0.25s;
`;
