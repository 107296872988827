import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledBorderWithLabel = styled.div<{
  $activeColor: string;
  $active: boolean;
  $defaultColor: string;
  $hoverColor: string;
  $labelColor: string;
}>`
  ${({ $activeColor, $defaultColor, $hoverColor, $labelColor, $active }) => `
    box-shadow: 0 0 0 ${px2Rem(1)} ${$defaultColor} inset;
    border-radius: 0 ${px2Rem(4)} ${px2Rem(4)} ${px2Rem(4)};
    position: relative;
    @media (min-width: 600px) {
      &:hover {
        box-shadow: 0 0 0 ${px2Rem(2)} ${$hoverColor} inset;
        & > p {
          box-shadow: ${px2Rem(-1)} 0 0 0 ${$labelColor} inset;
        };
      };
    }
    ${
      $active &&
      `
      box-shadow: 0 0 0 ${px2Rem(2)} ${$activeColor} inset;
      @media (min-width: 600px) {
        &:hover {
          box-shadow: 0 0 0 ${px2Rem(2)} ${$activeColor} inset;
        }
      }
      & > p {
        box-shadow: ${px2Rem(-1)} 0 0 0 ${$labelColor} inset;
      };`
    };
    & > p {
      background-color: ${$labelColor};
      border-radius: ${px2Rem(4)} ${px2Rem(4)} 0 0;
      border-bottom: ${px2Rem(1)} solid ${$labelColor};
      padding: ${px2Rem(5)} ${px2Rem(8)};
      color: ${palette.white};
      text-align: center;
      font-size: ${px2Rem(14)};
      font-weight: ${CSS_FONT_WEIGHT.vars.medium};
      line-height: normal;
      letter-spacing: ${px2Rem(0.7)};
      text-transform: uppercase;
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  `}
`;

const StyledBorderWrapper = styled.div`
  margin-top: ${px2Rem(32)};
  @media (min-width: 600px) {
    &:hover {
      box-shadow: 0 ${px2Rem(4)} ${px2Rem(24)} 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

export { StyledBorderWithLabel, StyledBorderWrapper };
