import React from 'react';
import { palette } from '../../../colors';
import PricePlanOptions from '../../../components/PricePlanOptions';
import Container from '../../../elements/Container';
import Copy from '../../../elements/Copy';
import Spacer from '../../../elements/Spacer';
import { px2Rem } from '../../../utils/px2Rem';
import ProductImageCard from '../ProductImageCard';
import { StyledLeaseCard } from './LeaseCard.styles';
import { ILeaseCard } from './LeaseCard.types';

export const LeaseCard = ({
  dataTestId,
  title,
  imageUrl,
  activeIndex,
  onPricePlanSelect,
  pricePlans,
}: ILeaseCard) => {
  return (
    <StyledLeaseCard data-testid={dataTestId}>
      <Container boxShadow noPadding backgroundColor={palette.white} border={palette.charcoal[200]}>
        {imageUrl && (
          <>
            <ProductImageCard imageUrl={imageUrl} />
            <Container backgroundColor={palette.charcoal[200]} height={px2Rem(1)} />
          </>
        )}
        <Container padding={`0 ${px2Rem(16)}`} width="initial">
          <Spacer height={24} />
          <Copy bold fontSize={18} color={palette.charcoal[500]}>
            {title}
          </Copy>
        </Container>
        <Spacer height={32} />
        <Container padding="0 16px" width="initial">
          <PricePlanOptions
            pricePlans={pricePlans}
            activeIndex={activeIndex}
            onClick={onPricePlanSelect}
          />
        </Container>
      </Container>
    </StyledLeaseCard>
  );
};
