import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';
import { primaryColors } from 'styles/variables/colors';
import { CSS_GLOBALS } from 'styles/globalAppStyles';

export const StyledCheckoutNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${CSS_GLOBALS.vars.navBarHeight};
  background-color: ${({ theme }) => theme?.navBar?.backgroundColor};
  padding-left: ${px2Rem(24)};
  padding-right: ${px2Rem(24)};
  border-bottom: ${({ theme }) => theme?.navBar?.borderBottom};
`;

export const StyledCheckoutNavBarLogo = styled.div`
  color: ${primaryColors.b01};
  height: ${px2Rem(32)};

  > svg {
    display: block;
    width: auto;
    height: 100%;
  }
`;

export const StyledCheckoutNavBarCartCta = styled.button<{
  $summaryIsOpen: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: ${px2Rem(40)};
  height: ${px2Rem(40)};
  color: ${({ theme, $summaryIsOpen }) =>
    $summaryIsOpen
      ? theme?.navBar?.backgroundColor
      : theme?.navBar?.cartCtaColor};
  position: relative;

  &:before {
    content: '';
    background-color: ${({ theme, $summaryIsOpen }) =>
      $summaryIsOpen
        ? theme?.navBar?.cartCtaColor
        : theme?.navBar?.backgroundColor};
    border-radius: 50%;
    position: absolute;
    inset: 0;
  }

  &:hover:before {
    background-color: ${({ theme }) => `${theme?.navBar?.cartCtaColor}`};
    opacity: 0.5;
  }

  svg {
    display: block;
    width: ${px2Rem(24)};
    height: auto;
    transform: translateY(${px2Rem(-1)});
    position: relative;
    z-index: 1;
  }
`;
