import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import useRayloQuery from 'utils/useRayloQuery';

export type IReason = {
  enum: string;
  displayName: string;
};

interface Copy {
  heading: string;
  bulletPoints: string[];
  displayFooter: boolean;
}

interface CopyList {
  MISSED_ESTIMATED_DELIVERY_DATE: Copy;
  DELIVERY_DATE_TOO_FAR: Copy;
  NOT_HAPPY_WITH_A_LEASE: Copy;
  WANT_TO_GET_A_DIFFERENT_DEVICE_FROM_ANOTHER_PROVIDER: Copy;
  CAN_NO_LONGER_AFFORD_IT: Copy;
  PRICE_TOO_HIGH: Copy;
  BETTER_PRICE_AVAILABLE_ELSEWHERE: Copy;
  MOVING_ABROAD: Copy;
}
const CANCELLATION_COPY: CopyList = {
  MISSED_ESTIMATED_DELIVERY_DATE: {
    heading:
      'Your order has been processed and is in the priority queue for shipping. Sorry it’s late, however we are experiencing greater demand than expected.',
    bulletPoints: [],
    displayFooter: true,
  },
  DELIVERY_DATE_TOO_FAR: {
    heading:
      'Your order has been processed and is in the priority queue for shipping. Sorry it’s late, however we are experiencing greater demand than expected.',
    bulletPoints: [],
    displayFooter: true,
  },
  NOT_HAPPY_WITH_A_LEASE: {
    heading:
      'Your order has been processed and is ready to ship. By canceling you’ll miss out on',
    bulletPoints: [
      '30 day free cancellation on all orders',
      'Super low monthly payments and free upgrades',
      'UK based support',
      'A sustainable choice',
      'Flexibility to keep paying monthly or contact us to discuss purchasing at the end of your lease',
    ],
    displayFooter: true,
  },
  WANT_TO_GET_A_DIFFERENT_DEVICE_FROM_ANOTHER_PROVIDER: {
    heading: 'If you cancel, you’ll lose these great Raylo benefits:',
    bulletPoints: [
      'Super low monthly payments and free upgrades',
      'No upfront cost',
      'UK based support with an Excellent Trustpilot rating',
      'A sustainable choice',
    ],
    displayFooter: true,
  },
  CAN_NO_LONGER_AFFORD_IT: {
    heading:
      'It’s important you can afford the monthly payments, but we’d love you to stay with Raylo:',
    bulletPoints: [
      'Cancel this order',
      'Take a look at our refurbished devices',
      'Choose 36 month term for the lowest monthly price',
    ],
    displayFooter: false,
  },
  PRICE_TOO_HIGH: {
    heading: 'If you cancel you’ll be missing out on:',
    bulletPoints: [
      'Super low monthly payments and free upgrades',
      'No upfront cost',
      'UK based support with an Excellent Trustpilot rating',
      'A sustainable choice',
    ],
    displayFooter: false,
  },
  BETTER_PRICE_AVAILABLE_ELSEWHERE: {
    heading: 'If you cancel you’ll be missing out on:',
    bulletPoints: [
      'Low monthly pricing',
      'Free upgrades, no upfront costs',
      'Most sustainable way to get a new device',
      'UK support with an Excellent Trustpilot rating',
    ],
    displayFooter: false,
  },
  MOVING_ABROAD: {
    heading: 'Thanks for choosing Raylo:',
    bulletPoints: [
      'We wish you all the best with your move',
      'Come back to us if you return to the UK',
    ],
    displayFooter: false,
  },
};

const useOrder = () => {
  const [selectedReason, setSelectedReason] = useState<IReason | null>(null);
  const [cancellationCopy, setCancellationCopy] = useState<any>({});
  const GET_CANCEL_ORDER_REASON_TYPES = gql`
    query GetCancelOrderReasonTypes {
      cancelOrderReasonTypes {
        displayName
        enum
      }
    }
  `;

  const {
    data: { cancelOrderReasonTypes },
  } = useRayloQuery(GET_CANCEL_ORDER_REASON_TYPES);

  useEffect(() => {
    if (selectedReason) {
      setCancellationCopy(
        CANCELLATION_COPY[selectedReason.enum as keyof CopyList] || {},
      );
    }
  }, [selectedReason]);

  return {
    cancelOrderReasonTypes: cancelOrderReasonTypes as IReason[],
    setSelectedReason,
    cancellationCopy,
  };
};

export default useOrder;
