import React from 'react';

import { useHistory } from 'react-router-dom';
import { match } from 'utils/matching';
import { FRONTEND_URL } from 'utils/constants';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { Modal } from 'components/common/Modal';

interface IProtectedCheckoutRoute {
  children: React.ReactNode;
  conditions: IProtectedCheckoutRouteConditions[];
}

interface IProtectedCheckoutRouteConditions {
  state: string;
  outcome?: string;
  reason?: string;
}

export const ProtectedCheckoutRoute = ({
  children,
  conditions,
}: IProtectedCheckoutRoute) => {
  const {
    hasCheckoutToken,
    checkoutLoading,
    checkoutCalled,
    condition,
    checkoutToken,
  } = useCheckoutContext();
  const history = useHistory();

  const hasMatch = conditions.some((o) => match(o, condition));

  if (!hasCheckoutToken) {
    window.location = FRONTEND_URL as unknown as Location;
    return;
  }

  if (checkoutLoading || !checkoutCalled) {
    return <Modal visible text="Loading..." />;
  }

  if (!hasMatch) {
    history.push(`/go/${checkoutToken}`);
    return;
  }

  return children;
};
