import { IGetCustomerDeviceOrderSubscription } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';

export const getActiveAssetReturn = (
  subscription: Partial<IGetCustomerDeviceOrderSubscription>,
) => {
  return subscription?.assetReturns?.find((assetReturn) => {
    return (
      assetReturn.state !== 'CANCELLED' && assetReturn.customerCollectionUrl
    );
  });
};
