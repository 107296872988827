import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import * as B from 'styles/buttons';
import { Button } from 'components/common/Button';
import { FadeIn } from 'components/animations/Transitions';
import store from 'store';
import { useMutation } from '@apollo/client';
import { MU_RESUME_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { useHistory } from 'react-router';

import { Helmet } from 'react-helmet';
import { useSharedCustomer } from 'utils/useSharedCustomer';

export const Abandoned = ({ onClick }) => {
  const { readDomainCookie } = useSharedCustomer();
  const history = useHistory();

  const checkoutToken =
    readDomainCookie('checkoutToken') || store.get('checkoutToken');

  const [updateMutation] = useMutation(MU_RESUME_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          resumeCheckout: { checkout, errors },
        },
      },
    ) => {
      if (checkout && checkout.state === 'STARTED') {
        history.push(`/checkout/${checkout.token}`);
      } else {
        alert("Sorry - we haven't been able to resume your basket");
        onClick();
      }
    },
  });

  useEffect(() => {
    activateOptimize();
  });

  const onResume = () => {
    updateMutation({
      variables: {
        checkoutToken,
      },
    });
  };

  return (
    <L.ErrorContainer>
      <Helmet>
        <title>Sorry - Expired - Raylo</title>
      </Helmet>
      <FadeIn>
        <T.RayloBodyHeading>
          <strong>We’re sorry</strong>, but your basket has expired.
        </T.RayloBodyHeading>
      </FadeIn>

      <FadeIn>
        <B.ButtonBar>
          <Button
            buttonStyle="primaryBlue"
            onClick={onResume}
            style={{ height: '44px' }}
          >
            Resume
          </Button>
          <Button
            buttonStyle="secondary"
            onClick={onClick}
            style={{ height: '44px' }}
          >
            Start again
          </Button>
        </B.ButtonBar>
      </FadeIn>
    </L.ErrorContainer>
  );
};
