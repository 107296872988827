import React from 'react';
import { StyledProgressBar } from './ProgressBar.styles';
import { IProgressBar } from './ProgressBar.types';

export const ProgressBar = ({
  colour,
  currentProgress,
  totalProgress,
  dataTestId,
}: IProgressBar) => {
  return (
    <StyledProgressBar
      colour={colour}
      currentProgress={currentProgress}
      totalProgress={totalProgress}
      data-testid={dataTestId}
    />
  );
};
