import { useState, useEffect } from 'react';
import { IGetSubscriptionQueryOrderItem } from 'screens/Account/Forms/graphql/subscription.graphql';
import { px2Rem } from 'utils/px2Rem';
import { Container, Copy } from '@raylo-tech/raylopay-ui';
import { Loader } from 'components/common/Loader';
import { Modal } from 'components/common/Modal';
import { UserReturnConfirmations } from 'screens/Account/Forms/components/UserReturnConfirmations/UserReturnConfirmations';
import { ReturnItemOverview } from 'screens/Account/Forms/components/ReturnItemOverview/ReturnItemOverview';
import { getActiveAssetReturn } from 'utils/subscription/getActiveAssetReturn';
import { IGetCustomerDeviceOrderSubscription } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import { ReturnsRedirectLoading } from './ReturnsRedirectLoading/ReturnsRedirectLoading';
import { ReturnFailedError } from './ReturnFailedError/ReturnFailedError';
import { useFormContext } from 'screens/Account/Forms/context/useFormContext';
import { Form } from 'screens/Account/Forms/Form';
import { ReasonsForCancellation } from './ReasonsForCancellation/ReasonsForCancellation';
import useSubscriptionCancellationReasons from '../../hooks/useSubscriptionCancellationReasons';
import { WinBackCopyContainer } from './WinBack/WinBackCopyContainer';
import { UpgradeAvailable } from './UpgradeAvailable/UpgradeAvailable';
import { useCancelLeaseContext } from 'screens/Account/Forms/context/useCancelLeaseContext';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { track } from 'integrations/segment/events';
import pluralize from 'pluralize';
import {
  CancellationCategory,
  subscriptionCancellationCopy,
} from './utils/subscriptionCancellationCopy';

const REASONS_WITH_NO_ASSET_RETURN = [
  'The device is lost',
  'The device was stolen',
];

export const EndOfLeaseForm = ({
  orderItem,
}: {
  orderItem: IGetSubscriptionQueryOrderItem;
}) => {
  const [customerNotes, setCustomerNotes] = useState<string>('');
  const { currentPage, setCurrentPage, setShowExitModal } = useFormContext();
  const { setDomainCookie, redirectToProductsApp } = useSharedCustomer();

  const subscriptionId = orderItem.subscription!.id;
  const { startSubscriptionTermination, showError } = useCancelLeaseContext();
  const { setSelectedReason, selectedReason, cancelSubscriptionReasonTypes } =
    useSubscriptionCancellationReasons(subscriptionId);
  const needsAssetReturn = selectedReason
    ? !REASONS_WITH_NO_ASSET_RETURN.includes(selectedReason)
    : true;

  const trackingUrl = getCustomerCollectionUrl(orderItem);
  if (trackingUrl) window.location.assign(trackingUrl);

  useEffect(() => {
    trackEvent(currentPage);
  }, [currentPage]);

  const onClose = () => {
    if (currentPage === 1) return history.back();

    setShowExitModal('close');
  };

  const onNext = () => {
    if (currentPage === 5 || (currentPage === 4 && !needsAssetReturn)) {
      startSubscriptionTermination(
        {
          subscriptionId,
          reason: selectedReason,
          customerNotes: customerNotes,
        },
        needsAssetReturn,
      );
    }

    setCurrentPage((previousPage: number) => previousPage + 1);
  };

  const onReasonsForCancellationNext = (params: {
    customerNotes: string;
    reason: string;
  }) => {
    setSelectedReason(params.reason);
    setCustomerNotes(params.customerNotes);
    onNext();
  };

  const onUpgrade = () => {
    setDomainCookie('checkoutContext', CheckoutMode.UPGRADE);
    setDomainCookie('subscriptionId', subscriptionId);
    redirectToProductsApp(
      pluralize.plural(
        orderItem.variant.product.category.displayName.toLowerCase(),
      ),
    );
  };

  const CurrentPage = () => {
    if (showError) return <ReturnFailedError />;

    if (!orderItem) {
      return (
        <Container center height={px2Rem(300)} width="auto">
          <Loader type="quad" />
        </Container>
      );
    }

    const randomizationSeed = new Date(
      orderItem.subscription!.createdAt,
    ).getMilliseconds();

    switch (currentPage) {
      case 1:
        return (
          <ReturnItemOverview
            onNext={onNext}
            onClose={onClose}
            orderItem={orderItem}
            header="Are you sure you want to end your lease?"
            primaryButtonText="Yes, end lease"
            secondaryButtonText="Don’t end lease"
          >
            <Copy>Please check this is the device you want to return.</Copy>
          </ReturnItemOverview>
        );
      case 2:
        return <UpgradeAvailable onNext={onNext} onClose={onUpgrade} />;
      case 3:
        return (
          <ReasonsForCancellation
            onNext={onReasonsForCancellationNext}
            onClose={onClose}
            reasons={cancelSubscriptionReasonTypes}
            randomizationSeed={randomizationSeed}
          />
        );
      case 4:
        return (
          <WinBackCopyContainer
            copy={subscriptionCancellationCopy(
              CancellationCategory.END_OF_LEASE,
              selectedReason,
            )}
            onNext={onNext}
            onClose={onClose}
          />
        );
      case 5:
        return needsAssetReturn ? (
          <UserReturnConfirmations
            onNext={onNext}
            onClose={onClose}
            secondaryButtonText="Don't cancel"
          />
        ) : (
          <Modal dataTestId="loading-modal" visible text="Processing..." />
        );
      case 6:
        return <ReturnsRedirectLoading />;
    }
  };

  return (
    <Form title="End lease" totalPages={6}>
      {CurrentPage()}
    </Form>
  );
};

const getCustomerCollectionUrl = (
  orderItem: IGetSubscriptionQueryOrderItem,
) => {
  const assetReturn = getActiveAssetReturn(
    orderItem.subscription as Partial<IGetCustomerDeviceOrderSubscription>,
  );
  return assetReturn?.customerCollectionUrl;
};

const trackEvent = (currentPage: number) => {
  const pageViewed = [
    'Return item overview',
    'Upgrade available',
    'Reason for cancellation selection',
    'Reasons to stay',
    'User confirmations',
    'Redirect Loading',
  ];

  track('Cancel Lease End of Lease Form', {
    pageViewed: pageViewed[currentPage - 1],
  });
};
