import styled from 'styled-components';
import { px2Rem } from './../../utils/px2Rem';
import { IGrid } from './Grid.types';

const StyledGrid = styled.div<IGrid>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-auto-flow: row;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  ${({ matchRowHeight }) => matchRowHeight && 'grid-auto-rows: 1fr;'}
  ${({ gap }) => gap && `gap: ${px2Rem(gap)};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${px2Rem(rowGap)}};`}
  ${({ columnGap }) => columnGap && `grid-column-gap: ${px2Rem(columnGap)}};`}
  ${({ columns, minColumnWidth, maxColumnWidth }) =>
    minColumnWidth &&
    maxColumnWidth &&
    `grid-template-columns: repeat(${columns}, minmax(${px2Rem(minColumnWidth)}, ${px2Rem(maxColumnWidth)}));`}
  @media (max-width: 1200px) {
    ${({ columnsTablet }) =>
      columnsTablet && `grid-template-columns: repeat(${columnsTablet}, 1fr);`}
    ${({ columnsTablet, minColumnWidth, maxColumnWidth }) =>
      minColumnWidth &&
      maxColumnWidth &&
      `grid-template-columns: repeat(${columnsTablet}, minmax(${px2Rem(minColumnWidth)}, ${px2Rem(maxColumnWidth)}));`}
  }
  @media (max-width: 600px) {
    ${({ columnsMobile }) =>
      columnsMobile && `grid-template-columns: repeat(${columnsMobile}, 1fr);`}
  }
`;

export { StyledGrid };
