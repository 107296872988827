import TextLink from 'elements/TextLink';
import { IParseOrder, isTV } from 'screens/Account/utils/parseOrder.helper';
import { InfoBox } from 'components/Account/InfoBox';
import CancelMerchantLease from 'components/CancelMerchantLease';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import { SubscriptionCancellationCategory } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import { primaryColors } from 'styles/variables/colors';
import { Copy } from 'elements';
import { tvsHelpLinkURL } from 'utils/constants';

const stateEnabled = (
  cancellationPeriodCategory: string | undefined,
  status: string,
): boolean => {
  switch (cancellationPeriodCategory) {
    case SubscriptionCancellationCategory.COOLING_OFF:
      return (
        status.startsWith(YourTechStatus.dispatched) ||
        status.startsWith(YourTechStatus.delivered)
      );
    case SubscriptionCancellationCategory.END_OF_INITIAL_LEASE_TERM:
      return true;
    default:
      return false;
  }
};

const getCancelLink = (order: IParseOrder) => {
  const dispatched = order.status.startsWith(YourTechStatus.dispatched);
  const delivered = order.status.startsWith(YourTechStatus.delivered);
  const inArrears = order.status === YourTechStatus.arrears;

  if (dispatched) {
    return (
      <InfoBox
        linkText="Cancel lease"
        title="Sorry, you can't cancel right now"
        content="You need to wait until your order has arrived, then you will be able to cancel."
        dataTestId="cancel-lease-dispatched"
      />
    );
  }

  if (inArrears) {
    return (
      <InfoBox
        linkText="Cancel lease"
        title="Sorry, you can't cancel right now"
        content="You need to pay your overdue bill first, then you will be able to cancel."
        dataTestId="cancel-lease-arrears"
      />
    );
  }

  if (delivered) {
    if (!order.directCancellationSupported) {
      return (
        <CancelMerchantLease
          merchantName={order.merchant.displayName}
          merchantReference={order.merchantReference}
        />
      );
    }

    if (isTV(order.orderItem.variant.product.category)) {
      return (
        <InfoBox
          linkText="Cancel lease"
          title="Let's get your TV returned safely"
          dataTestId="cancel-lease-tv"
        >
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
            Please contact Raylo customer support to cancel your lease and
            return your TV. See our{' '}
            <TextLink href={tvsHelpLinkURL}>help article</TextLink> on leasing
            TVs for more information.
          </Copy>
        </InfoBox>
      );
    }

    return (
      <TextLink link={`/account/cancel-lease/${order.subscription!.id}`}>
        <span data-testid="cancel-lease">Cancel lease</span>
      </TextLink>
    );
  }

  return null;
};

const getUpgradeLink = (order: IParseOrder) => {
  const upgradeAvailable = order.status === YourTechStatus.upgradeAvailable;
  if (!upgradeAvailable) {
    return null;
  }

  if (isTV(order.orderItem.variant.product.category)) {
    return (
      <InfoBox
        linkText="End lease"
        title="Let's get your TV returned safely"
        dataTestId="end-lease-upgrade-tv"
      >
        <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
          Please contact Raylo customer support to end your lease and return
          your TV. See our{' '}
          <TextLink href={tvsHelpLinkURL}>help article</TextLink> on leasing TVs
          for more information.
        </Copy>
      </InfoBox>
    );
  }

  return (
    <TextLink link={`/account/cancel-lease/${order.subscription!.id}`}>
      <span data-testid="end-lease-upgrade">End lease</span>
    </TextLink>
  );
};

export const CancelLease = ({ order }: { order: IParseOrder }) => {
  if (
    !stateEnabled(order.subscription?.cancellationPeriodCategory, order.status)
  ) {
    return null;
  }

  return (
    <>
      {getCancelLink(order)}
      {getUpgradeLink(order)}
    </>
  );
};
