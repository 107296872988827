import React from 'react';
import { Lottie } from '@crello/react-lottie';
import blockAnimation from 'public/cropped-blocks-2.1.json';

export const BlocksAnimation = () => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: blockAnimation,
    rendererSettings: {
      className: 'lottie-blocks',
      preserveAspectRatio: 'none',
    },
  };

  return (
    <div className="blocks-animation">
      {process.env.NODE_ENV !== 'test' && <Lottie config={lottieOptions} />}
    </div>
  );
};
