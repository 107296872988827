import gql from 'graphql-tag';
import { subscriptionNextPaymentScheduleEvent } from 'graphql/fragments/subscriptionNextPaymentScheduleEvent.fragment';

export const CUSTOMER_SUBSCRIPTIONS = gql`
  ${subscriptionNextPaymentScheduleEvent}
  query CustomerSubscriptionsQuery {
    customer {
      subscriptions {
        id
        arrearsAmount {
          valueInSubunit
          formattedValue
          value
        }
        ...SubscriptionPaymentScheduleEvent
      }
    }
  }
`;

export interface ICustomerSubscription {
  id: string;
  arrearsAmount?: {
    valueInSubunit: number;
    formattedValue: string;
    value: number;
  } | null;
  nextPaymentScheduleEvent?: {
    payable: boolean;
    amount: {
      value: number;
    };
  } | null;
}
