import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledButtonWithIcon = styled.button<{
  borderRadius?: number;
  fontSize?: number;
  lineHeight?: number;
  backgroundColor?: string;
  dataTestId?: string;
  color?: string;
}>`
  ${({ borderRadius, backgroundColor, color, lineHeight, fontSize }) => `
    background-color: ${backgroundColor ?? palette.blue[500]};
    border-radius: ${px2Rem(borderRadius ?? 4)};
    padding: ${px2Rem(14)} ${px2Rem(35)};
    color: ${color ?? palette.white};
    line-height: ${px2Rem(lineHeight ?? 20)};
    font-size: ${px2Rem(fontSize ?? 16)};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    cursor: pointer;
  `}
`;

export { StyledButtonWithIcon };
