import gql from 'graphql-tag';

export const subscriptionNextPaymentScheduleEvent = gql`
  fragment SubscriptionPaymentScheduleEvent on Subscription {
    nextPaymentScheduleEvent {
      amount {
        formattedValue
        value
      }
      payable
      paymentOn
    }
  }
`;

export type SubscriptionPaymentScheduleEvent = {
  amount: {
    formattedValue: string;
    value: number;
  };
  payable: boolean;
  paymentOn: string;
};
