import React from 'react';
import { StyledSelectDropdown } from './SelectDropdown.styles';
import { ISelectDropdown, IValue } from './SelectDropdown.types';
import { px2Rem } from '../../utils/px2Rem';
import Spacer from '../../elements/Spacer';
import { palette } from '../../colors';
import Select, { components } from 'react-select';
import Icon from '../../elements/Icon';
import { useMedia } from 'use-media';

// @ts-expect-error - TODO - type this properly
const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon name="ChevronDown" />
  </components.DropdownIndicator>
);

// @ts-expect-error - TODO - type this properly
const OptionWithIcon = (props) => (
  <components.Option {...props}>
    <div className="icon-wrapper">
      {props.data?.icon && (
        <Icon name={props.data.icon} $height={24} dataTestId={`icon-${props.data.icon}`} />
      )}
      {props.data.label}
    </div>
  </components.Option>
);

const SelectedValue = ({ data, isDisabled }: { data: IValue; isDisabled: boolean }) => (
  <div className="icon-wrapper">
    {data?.icon && (
      <Icon
        name={data.icon}
        $height={24}
        dataTestId={`icon-${data.icon}`}
        $opacity={isDisabled ? 0.4 : 1}
      />
    )}
    {data.label}
  </div>
);

export const SelectDropdown = ({
  dataTestId,
  options,
  onChange,
  value,
  label,
  subCopy,
  isSearchable,
  isDisabled,
}: ISelectDropdown) => {
  const isMobile = useMedia({ maxHeight: 600 });
  const customStyles = {
    // @ts-expect-error - TODO - type this properly
    control: (base, { isFocused, isDisabled }) => ({
      display: 'flex',
      cursor: 'pointer',
      height: px2Rem(56),
      borderRadius: 0,
      backgroundColor: isDisabled || isFocused ? palette.charcoal[100] : 'transparent',
      border: isFocused
        ? `${px2Rem(1)} solid ${palette.blue[500]}`
        : `${px2Rem(1)} solid ${palette.charcoal[300]}`,
      boxShadow: isFocused ? `inset 0 0 0 ${px2Rem(1)} ${palette.blue[500]}` : 'none',
      '&:hover': {
        borderColor: isFocused ? palette.blue[400] : palette.charcoal[500],
        boxShadow: isFocused ? `inset 0 0 0 ${px2Rem(1)} ${palette.blue[400]}` : 'none',
        backgroundColor: palette.charcoal[100],
      },
      color: isDisabled ? palette.charcoal[300] : palette.charcoal[400],
    }),
    // @ts-expect-error - TODO - type this properly
    option: (styles, { isFocused }) => ({
      ...styles,
      '&:active': {
        backgroundColor: palette.blue[200],
      },
      cursor: 'pointer',
      backgroundColor: isFocused ? palette.blue[100] : 'transparent',
      color: palette.charcoal[500],
    }),
    // @ts-expect-error - TODO - type this properly
    menuList: (base) => {
      const menuListStyles = { ...base };
      if (isMobile) {
        menuListStyles.maxHeight = px2Rem(180);
      }

      return menuListStyles;
    },
  };

  return (
    <StyledSelectDropdown data-testid={dataTestId}>
      {label && (
        <>
          <label data-testid={`${dataTestId}-label`} style={{ color: palette.charcoal[500] }}>
            {label}
          </label>
          <Spacer height={8} />
        </>
      )}
      {subCopy && (
        <>
          <Spacer height={12} />
          {subCopy}
        </>
      )}
      <Select
        options={options}
        // @ts-expect-error - TODO - type this properly
        onChange={onChange}
        formatOptionLabel={(data) => <SelectedValue data={data} isDisabled={!!isDisabled} />}
        classNames={{
          singleValue: ({ isDisabled }) => (isDisabled ? 'single-value-disabled' : 'single-value'),
        }}
        value={value}
        components={{
          DropdownIndicator,
          Option: OptionWithIcon,
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        isSearchable={isSearchable ?? true}
        isDisabled={!!isDisabled}
      />
    </StyledSelectDropdown>
  );
};
