import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { Button } from 'components/common/Button';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

export const CardDecline = ({ onClick }) => {
  useEffect(() => {
    activateOptimize();
  });

  return (
    <div className="checkout--step feedback">
      <FadeIn>
        <T.RayloBodyHeading>
          <strong>Your card issuer has declined your payment.</strong>
        </T.RayloBodyHeading>
      </FadeIn>

      <SlideDownFadeIn>
        <div style={{ marginBottom: 40 }}>
          <T.RayloBodyType>
            Please contact your card issuer or try another card.
          </T.RayloBodyType>
        </div>
      </SlideDownFadeIn>

      <SlideDownFadeIn>
        <Button
          buttonStyle="primaryBlue"
          onClick={onClick}
          style={{ height: '44px' }}
        >
          Use a different card
        </Button>
      </SlideDownFadeIn>
    </div>
  );
};
