import { FormNavbar } from '../FormNavbar/FormNavbar';
import { primaryColors } from 'styles/variables/colors';
import { IFormBase } from './FormBase.types';
import { Container, ProgressBar, Spacer } from '@raylo-tech/raylopay-ui';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { px2Rem } from 'utils/px2Rem';
import { ButtonWithoutStyle as Button } from '../FormNavbar/FormNavbar.styles';
import { ReactComponent as BackArrowSVG } from 'public/icons/back-arrow.svg';
import { StyledFormBase } from './FormBase.styles';

export const FormBase = ({
  children,
  title,
  onBack,
  onClose,
  onLogoClick,
  currentProgress,
  totalProgress,
}: IFormBase) => {
  const isMobile = useMedia({
    maxWidth: LEGACY_BREAKPOINTS.account.forms.mobile,
  });

  return (
    <StyledFormBase isMobile={isMobile}>
      <FormNavbar
        title={title}
        onBack={onBack}
        onClose={onClose}
        onLogoClick={onLogoClick}
      />
      {!isMobile && <Spacer height={24} />}
      {isMobile && (
        <ProgressBar
          dataTestId="form-progress-bar"
          currentProgress={currentProgress}
          totalProgress={totalProgress}
        />
      )}
      <Container
        maxWidth={px2Rem(500)}
        margin="auto"
        backgroundColor={primaryColors.w01}
      >
        {!isMobile && (
          <ProgressBar
            dataTestId="form-progress-bar"
            currentProgress={currentProgress}
            totalProgress={totalProgress}
          />
        )}
        <Container padding={px2Rem(24)} boxShadow={!isMobile}>
          {!isMobile && (
            <>
              <Button onClick={onBack} data-testid="form-back-button">
                <BackArrowSVG stroke={primaryColors.bk01} />
              </Button>
              <Spacer height={32} />
            </>
          )}
          {children}
        </Container>
      </Container>
    </StyledFormBase>
  );
};
