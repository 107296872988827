import { useEffect, useState } from 'react';
import { IGetSubscriptionQueryOrderItem } from 'screens/Account/Forms/graphql/subscription.graphql';
import { px2Rem } from 'utils/px2Rem';
import { Container, Copy, Spacer, TextLink } from '@raylo-tech/raylopay-ui';
import { Loader } from 'components/common/Loader';
import { UserReturnConfirmations } from 'screens/Account/Forms/components/UserReturnConfirmations/UserReturnConfirmations';
import { ReturnItemOverview } from 'screens/Account/Forms/components/ReturnItemOverview/ReturnItemOverview';
import { getActiveAssetReturn } from 'utils/subscription/getActiveAssetReturn';
import { IGetCustomerDeviceOrderSubscription } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import { ReturnsRedirectLoading } from './ReturnsRedirectLoading/ReturnsRedirectLoading';
import { ReturnFailedError } from './ReturnFailedError/ReturnFailedError';
import { useFormContext } from 'screens/Account/Forms/context/useFormContext';
import { Form } from 'screens/Account/Forms/Form';
import { ReasonsForCancellation } from './ReasonsForCancellation/ReasonsForCancellation';
import useSubscriptionCancellationReasons from '../../hooks/useSubscriptionCancellationReasons';
import { WinBackCopyContainer } from './WinBack/WinBackCopyContainer';
import { VariantConditions } from 'utils/VariantConditions.enum';
import { track } from 'integrations/segment/events';
import { useCancelLeaseContext } from 'screens/Account/Forms/context/useCancelLeaseContext';
import {
  CancellationCategory,
  subscriptionCancellationCopy,
} from './utils/subscriptionCancellationCopy';

export const CoolingOffForm = ({
  orderItem,
}: {
  orderItem: IGetSubscriptionQueryOrderItem;
}) => {
  const { startSubscriptionTermination, showError } = useCancelLeaseContext();
  const [customerNotes, setCustomerNotes] = useState<string>('');
  const { currentPage, setCurrentPage, setShowExitModal } = useFormContext();

  const subscriptionId = orderItem.subscription!.id;
  const { setSelectedReason, selectedReason, cancelSubscriptionReasonTypes } =
    useSubscriptionCancellationReasons(subscriptionId);

  const trackingUrl = getCustomerCollectionUrl(orderItem);
  if (trackingUrl) window.location.assign(trackingUrl);

  useEffect(() => {
    trackEvent(currentPage);
  }, [currentPage]);

  const onClose = () => {
    if (currentPage === 1) return history.back();

    setShowExitModal('close');
  };

  const onNext = () => {
    if (currentPage === 4) {
      startSubscriptionTermination(
        {
          subscriptionId,
          reason: selectedReason,
          customerNotes: customerNotes,
        },
        true,
      );
    }

    setCurrentPage((previousPage: number) => previousPage + 1);
  };

  const onReasonsForCancellationNext = (params: {
    customerNotes: string;
    reason: string;
  }) => {
    setSelectedReason(params.reason);
    setCustomerNotes(params.customerNotes);
    onNext();
  };

  const CurrentPage = () => {
    if (showError) return <ReturnFailedError />;

    if (!orderItem) {
      return (
        <Container center height={px2Rem(300)} width="auto">
          <Loader type="quad" />
        </Container>
      );
    }

    const randomizationSeed = new Date(
      orderItem.subscription!.createdAt,
    ).getMilliseconds();

    switch (currentPage) {
      case 1:
        return (
          <ReturnItemOverview
            onNext={onNext}
            onClose={onClose}
            orderItem={orderItem}
            header="Are you sure you want to cancel this lease?"
            primaryButtonText="Yes, cancel"
            secondaryButtonText="Don’t cancel"
          >
            <Copy>Please check this is the device you want to return.</Copy>
            <Spacer height={24} />
            <Copy>
              You are within the cooling off period so there won’t be a
              cancellation fee.
            </Copy>
            {orderItem.variant.condition === VariantConditions.NEW &&
              orderItem.subscription &&
              orderItem.subscription.costSummary.termLength !== 1 && (
                <>
                  <Spacer height={24} />
                  <Copy dataTestId="warranty-activation-charge-copy">
                    If your device has been opened, there will be a&nbsp;
                    <TextLink
                      href="https://help.raylo.com/en/articles/5760747-what-happens-if-i-open-my-brand-new-device-within-the-cooling-off-period"
                      target="_blank"
                    >
                      Warranty Activation Charge
                    </TextLink>
                  </Copy>
                </>
              )}
          </ReturnItemOverview>
        );
      case 2:
        return (
          <ReasonsForCancellation
            onNext={onReasonsForCancellationNext}
            onClose={onClose}
            reasons={cancelSubscriptionReasonTypes}
            randomizationSeed={randomizationSeed}
          />
        );
      case 3:
        return (
          <WinBackCopyContainer
            copy={subscriptionCancellationCopy(
              CancellationCategory.COOLING_OFF,
              selectedReason,
            )}
            onNext={onNext}
            onClose={onClose}
          />
        );
      case 4:
        return (
          <UserReturnConfirmations
            onNext={onNext}
            onClose={onClose}
            secondaryButtonText="Don't cancel"
          />
        );
      case 5:
        return <ReturnsRedirectLoading />;
    }
  };

  return (
    <Form title="Cancel lease" totalPages={5}>
      {CurrentPage()}
    </Form>
  );
};

const getCustomerCollectionUrl = (
  orderItem: IGetSubscriptionQueryOrderItem,
) => {
  const assetReturn = getActiveAssetReturn(
    orderItem.subscription as Partial<IGetCustomerDeviceOrderSubscription>,
  );
  return assetReturn?.customerCollectionUrl;
};

const trackEvent = (currentPage: number) => {
  const pageViewed = [
    'Return item overview',
    'Reason for cancellation selection',
    'Reasons to stay',
    'User confirmations',
    'Redirect Loading',
  ];

  track('Cancel Lease Cooling Off Form', {
    pageViewed: pageViewed[currentPage - 1],
  });
};
