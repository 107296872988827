import { useEffect, useState } from 'react';
import { invert, pick } from 'lodash-es';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

// STATE
import { useUiContext } from 'utils/useUiContext';
import 'styles/checkout-layout.css';

// COMPONENTS
import * as L from 'styles/layout';
import { Modal } from 'components/common/Modal';
import { FadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { CheckoutOrderSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSummary';
import { CheckoutOrderSidebarSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSidebarSummary';

// GRAPHQL
import {
  CheckoutSteps,
  QL_CHECKOUT_BASE,
} from 'screens/Checkout/graphQL/queries';
import { FRONTEND_URL } from 'utils/constants';
import { captureErrorEvent } from 'utils/errorTracking';
import useRayloQuery from 'utils/useRayloQuery';
import { useHistory } from 'react-router';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useTrackCheckout } from 'utils/trackCheckout';

// Utils
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import CheckoutStepsContainer from './containers/CheckoutStepsContainer';
import { SLUGS } from './helpers/slugs';
import Container from 'elements/Container';
import { pageTitles } from './helpers/pageTitles';
import DirectDebitModal from 'components/DirectDebitModal';
import DirectDebitContactModal from './components/DirectDebitContactModal';
import InsuranceModal from './components/InsuranceModal';
import { getLatestSubscriptionBankDetails } from 'utils/getLatestSubscriptionBankDetails';
import TradeInTermsModal from './components/TradeInTermsModal';
import PrivacyModal from './components/PrivacyModal';
import { CheckoutMode } from './enums/checkoutModes';
import { CheckoutStep } from './enums/checkoutSteps';
import { CheckoutStepsBackArrow } from './components/CheckoutStepsBackArrow/CheckoutStepsBackArrow';
import { StyledHeaderWrapper } from './containers/CheckoutStepsContainer/styles';
import CheckoutNavBar from './components/CheckoutNavBar';
import { buildProductURL } from 'utils/productUrlHelpers';
import { palette, ProgressBar } from '@raylo-tech/raylopay-ui';

const slugLookup = (slug) => {
  return invert(SLUGS)[slug];
};

export const Checkout = () => {
  const history = useHistory();
  const {
    checkoutToken,
    currentStep,
    setCurrentStep,
    setCustomerDetails,
    checkoutMode,
    progressBarSteps,
    showUpdatedCheckoutSteps,
  } = useCheckoutContext();

  const [trackings, setTrackings] = useState(undefined);
  const [waitForExperiment, setWaitForExperiment] = useState(0);
  const [cookies, removeCookie] = useCookies(['raylo_tracking']);

  useEffect(() => {
    if (waitForExperiment === 0) {
      if (
        cookies &&
        cookies.raylo_tracking &&
        cookies.raylo_tracking !== 'undefined'
      ) {
        setWaitForExperiment(1);
        setTrackings(cookies.raylo_tracking);
      } else {
        setWaitForExperiment(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const {
    showSummary,
    showFeedback,
    showModal,
    showDDModal,
    showDDContactModal,
    showInsuranceModal,
    showTradeInTermsModal,
    setLightMode,
    showPrivacyModal,
    theme,
    setShowSummary,
  } = useUiContext();

  const { trackCheckout } = useTrackCheckout();
  const [firstLoad, setFirstLoad] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [additionalTech, setAdditionalTech] = useState(false);

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(QL_CHECKOUT_BASE, {
    skip: waitForExperiment < 2,
    variables: {
      token: checkoutToken,
    },
  });

  const item = checkout?.items[0];
  const productURL = buildProductURL({
    checkout,
    slug: item?.variant?.slug,
    termLength: item?.costSummary?.termLength,
  });

  const handleAppBack = () => {
    if (currentStep === progressBarSteps[0] && productURL) {
      window.location.href = productURL;
      return;
    }

    history.goBack();
  };

  const handleBrowserBack = () => {
    const slug = history.location.pathname.split('/')[3];

    if (!Object.values(SLUGS).includes(slug) && productURL) {
      window.location.href = productURL;
    }
  };

  useEffect(() => {
    if (history.action === 'POP') {
      handleBrowserBack();
    }
    setTimeout(() => window.scrollTo(0, 0), 150);
  }, [history.location.pathname]);

  const hideSummary = () => setShowSummary(false);

  const {
    data: { checkout: checkoutTwo },
  } = useRayloQuery(CheckoutSteps.stepUpgrade.query, {
    variables: {
      token: checkoutToken,
    },
    skip: ![CheckoutMode.UPGRADE, CheckoutMode.ADDITIONAL_TECH].includes(
      checkoutMode,
    ),
  });

  const [updateMutation] = useMutation(CheckoutSteps.stepUpgrade.mutation);

  useEffect(() => {
    if (checkout?.customer) {
      const subscriptions = checkout.customer?.subscriptions;
      const bankDetails = getLatestSubscriptionBankDetails(subscriptions);
      setCustomerDetails(bankDetails);
    }
  }, [checkout, loading]);

  useEffect(() => {
    setAdditionalTech(checkoutMode === CheckoutMode.ADDITIONAL_TECH);
  }, [checkoutMode]);

  useEffect(() => {
    setLightMode(true);
  }, [checkoutMode]);

  useEffect(() => {
    if (
      history?.location?.pathname === '/checkout/step/bank/view' &&
      checkoutMode !== CheckoutMode.NEW_CUSTOMER
    ) {
      setCurrentStep(CheckoutStep.STEP_BANK);
      history.push('/checkout/step/bank');
    }
  }, [history?.location]);

  useEffect(() => {
    if (checkoutTwo) {
      if (checkoutTwo.customerInfo) {
        setCustomerInfo(
          pick(
            checkoutTwo.customerInfo,
            'firstName',
            'middleName',
            'lastName',
            'dateOfBirth',
            'email',
          ),
        );
      } else if (checkoutTwo.customer) {
        setCustomerInfo(
          pick(
            checkoutTwo.customer,
            'firstName',
            'middleName',
            'lastName',
            'dateOfBirth',
            'email',
          ),
        );
      }
    }
  }, [checkoutTwo]);

  useEffect(() => {
    // add stored name, email and dob for customers buying additional tech
    if (customerInfo && additionalTech) {
      updateMutation({
        variables: {
          ...customerInfo,
          checkoutToken,
        },
      });
    }
  }, [customerInfo, additionalTech]);

  if (!checkoutToken) {
    window.location = FRONTEND_URL;
  }

  function handleChange() {
    const pathElements = window.location.pathname.split('/');
    const slug = pathElements[3];
    setCurrentStep(slugLookup(slug));
    hideSummary();
  }

  useEffect(() => {
    window.addEventListener('popstate', handleChange);
    return () => {
      window.removeEventListener('popstate', handleChange);
    };
  }, []);

  const nextStep = (current) => {
    if (current === CheckoutStep.STEP_SIGN) {
      current = 'StepSign';
    }
    const currentPos = progressBarSteps.indexOf(current);
    if (progressBarSteps.length > currentPos) {
      return goToStep(progressBarSteps[currentPos + 1]);
    } else {
      return (window.location = FRONTEND_URL);
    }
  };

  const globalStepNumber = (current) => {
    if (current === CheckoutStep.STEP_SIGN) {
      current = 'StepSign';
    }
    const currentPos = progressBarSteps.indexOf(current);
    return currentPos + 1;
  };

  const goToStep = (newStep) => {
    const slug = SLUGS[newStep];
    history.push(`/checkout/step/${slug}`);

    setCurrentStep(newStep);
  };

  useEffect(() => {}, [currentStep]);

  useEffect(() => {
    if (waitForExperiment !== 1 || !checkoutToken) {
      return;
    }

    try {
      trackCheckout(checkoutToken, trackings, () => {
        //clean cookie
        setWaitForExperiment(2);
        removeCookie('raylo_tracking');
      });
    } catch (e) {
      captureErrorEvent('Error track checkout', {
        trackings: trackings,
        errors: e,
      });
      setWaitForExperiment(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutToken, cookies, waitForExperiment]);

  useEffect(() => {
    if (
      checkout &&
      checkout.decision &&
      checkout.decision.outcome === 'USER_ACTION_REQUIRED' &&
      checkout.decision.outcomeReason === 'counter_offer'
    ) {
      setFirstLoad(true);
      goToStep(CheckoutStep.STEP_SIGN);
    } else if (
      checkout &&
      checkout.decision &&
      checkout.decision.outcome === 'USER_ACTION_REQUIRED' &&
      checkout.decision.outcomeReason === 'bank_connection_requested'
    ) {
      history.push('/checkout/decision/bank-connection');
    } else if (
      checkout &&
      checkout.decision &&
      checkout.decision.outcome === 'USER_ACTION_REQUIRED' &&
      checkout.decision.outcomeReason === 'security_deposit'
    ) {
      history.push('/checkout/decision/security-deposit');
    } else if (checkout && checkout.token && checkout.state === 'STARTED') {
      if (!firstLoad) {
        setFirstLoad(true);
        if (checkout.items[0].originSubscription) {
          goToStep(progressBarSteps[0]);
        } else {
          if (checkout.items[0].costSummary.includesInsurance) {
            const firstStep = progressBarSteps[0];
            if (currentStep !== firstStep) {
              goToStep(firstStep, true);
            }
          } else {
            goToStep(progressBarSteps[0]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, checkout]);

  useEffect(() => {
    if (currentStep) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: globalStepNumber(currentStep),
              option: currentStep,
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (loading || waitForExperiment < 2)
    return (
      <L.MainWrapper>
        <Modal visible text="Loading..." />
      </L.MainWrapper>
    );

  const displayOnly =
    checkout &&
    checkout.decision &&
    checkout.decision.outcome === 'USER_ACTION_REQUIRED';

  const personalDetailSteps = progressBarSteps.slice(
    showUpdatedCheckoutSteps
      ? progressBarSteps.indexOf(CheckoutStep.STEP_EMAIL)
      : progressBarSteps.indexOf(CheckoutStep.STEP_NAME_EMAIL),
  );

  return (
    <ThemeProvider theme={theme}>
      <Container noPadding>
        <div
          className={classNames('checkout--index', { showSummary })}
          style={{
            backgroundColor: palette.white,
          }}
        >
          <Helmet>
            <title>{pageTitles[currentStep] || 'Checkout'} - Raylo</title>
          </Helmet>
          {!showModal && (
            <StyledHeaderWrapper>
              <CheckoutNavBar />
              <ProgressBar
                currentProgress={
                  personalDetailSteps.includes(currentStep)
                    ? personalDetailSteps.indexOf(currentStep) + 1
                    : 0
                }
                totalProgress={personalDetailSteps.length + 1}
              />
            </StyledHeaderWrapper>
          )}
          {!showSummary && (
            <CheckoutStepsBackArrow
              backButtonOnClick={handleAppBack}
              isStepTradeIn={currentStep === CheckoutStep.STEP_TRADE_IN}
            />
          )}
          <div className="checkout--main">
            {showModal && (
              <>
                {showDDModal && <DirectDebitModal />}
                {showDDContactModal && <DirectDebitContactModal />}
                {showInsuranceModal && <InsuranceModal />}
                {showTradeInTermsModal && <TradeInTermsModal />}
                {showPrivacyModal && <PrivacyModal />}
              </>
            )}

            <CheckoutStepsContainer
              step={currentStep}
              checkout={checkout}
              checkoutToken={checkoutToken}
              goToStep={goToStep}
              nextStep={nextStep}
            />
          </div>

          {showFeedback ? (
            <FadeIn>
              <BlocksAnimation />
            </FadeIn>
          ) : (
            <div>
              <div className={classNames('checkout--summary', { showSummary })}>
                <CheckoutOrderSummary
                  checkoutToken={checkoutToken}
                  displayOnly={displayOnly}
                />
              </div>
              <CheckoutOrderSidebarSummary
                checkoutToken={checkoutToken}
                displayOnly={displayOnly}
              />
            </div>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
};
