import { useEffect, useState } from 'react';
import {
  RayloModal,
  SectionMessage,
  SelectDropdown,
  TextArea,
} from '@raylo-tech/raylopay-ui';
import { useMutation } from '@apollo/client';
import Copy from 'elements/Copy';
import TextLink from 'elements/TextLink';
import Spacer from 'elements/Spacer';
import useOrder, { IReason } from 'screens/Account/hooks/useOrderContext';
import { NotificationBox } from 'components/Account/NotificationBox';
import { primaryColors } from 'styles/variables/colors';
import { CANCEL_ORDER } from 'screens/Account/graphql/order.graphql';
import { ReasonsToStayModal } from './ReasonsToStayModal';

interface IValue {
  label: string;
  value: string;
}
const selectReason: IValue = {
  value: '',
  label: 'Please choose reason to return',
};

interface ICancelOrder {
  orderId: string;
  onComplete: () => void;
}

export const CancelOrder = ({ orderId, onComplete }: ICancelOrder) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState<IValue[]>([]);
  const [reason, setReason] = useState<IValue>(selectReason);
  const [customerNotes, setCustomerNotes] = useState<string>('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [displayReasonsToStayModal, setDisplayReasonsToStayModal] =
    useState(false);
  const [error, setError] = useState('');

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeReasonsToStayModal = () => setDisplayReasonsToStayModal(false);

  const captureCustomerNotes = (notes: string) => {
    setCustomerNotes(notes);
  };

  const { cancelOrderReasonTypes, setSelectedReason, cancellationCopy } =
    useOrder();
  const [cancelOrderMutation] = useMutation(CANCEL_ORDER, {
    onCompleted: (data) => {
      closeReasonsToStayModal();
      if (data.cancelOrder.success) {
        setDisplayConfirmation(true);
      } else {
        setError(data.cancelOrder.errors[0].message);
      }
    },
    onError: (e) => {
      closeReasonsToStayModal();
      setError(e.message);
    },
  });

  const cancelOrder = () => {
    cancelOrderMutation({
      variables: { orderId, reason: reason?.value, customerNotes },
    });
  };

  const completeCancellation = () => {
    setDisplayConfirmation(false);
    onComplete();
  };

  const displayReasonsToStay = () => {
    const selectedReason = cancelOrderReasonTypes?.find(
      (type) => type.enum === reason?.value,
    );
    setSelectedReason(selectedReason || null);
    setModalOpen(false);
    setDisplayReasonsToStayModal(true);
  };

  useEffect(() => {
    if (cancelOrderReasonTypes) {
      setOptions(
        cancelOrderReasonTypes.map((type: IReason) => {
          return { label: type.displayName, value: type.enum };
        }),
      );
    }
  }, [cancelOrderReasonTypes]);

  return (
    <>
      <TextLink onClick={openModal}>Cancel order</TextLink>
      {displayConfirmation && (
        <NotificationBox
          type="success"
          title="Order successfully cancelled"
          onClose={completeCancellation}
        />
      )}
      {error && (
        <NotificationBox
          type="error"
          title="We are unable to cancel your order"
          onClose={() => setError('')}
        >
          <SectionMessage
            icon="RayloDesignInfo"
            iconSize={16}
            content={
              <Copy fontSize={14} lineHeight={20} color={primaryColors.c01}>
                Sorry, we can't cancel your order right now. Please try again,
                and if it doesn’t work,{' '}
                <a
                  href="https://help.raylo.com/en/articles/3271547-contact-raylo"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact our support team.
                </a>
              </Copy>
            }
          />
        </NotificationBox>
      )}
      {displayReasonsToStayModal && (
        <ReasonsToStayModal
          heading={cancellationCopy.heading}
          bulletPoints={cancellationCopy.bulletPoints}
          displayFooter={cancellationCopy.displayFooter}
          onNext={cancelOrder}
          onClose={closeReasonsToStayModal}
        />
      )}
      {modalOpen && (
        <RayloModal
          dataTestId="cancel-order-modal"
          modalOpen={modalOpen}
          title="We’re sorry to hear you’ve had a change of heart."
          handleCloseModal={closeModal}
          primaryButtonText="Next"
          primaryButtonOnClick={displayReasonsToStay}
          secondaryButtonText="Don’t cancel"
          secondaryButtonOnClick={closeModal}
          disablePrimaryButton={!reason.value}
        >
          <>
            <Copy fontSize={16} lineHeight={20} color={primaryColors.c01} bold>
              Can you let us know why you would like to cancel?
            </Copy>
            <Spacer height={16} />
            <SelectDropdown
              options={options}
              onChange={(value: IValue) => setReason(value)}
              value={reason}
              dataTestId="cancel-order-reason"
              isSearchable={false}
            />
            <Spacer height={16} />
            <TextArea
              floatingLabel="What should we improve?"
              height={104}
              onChange={captureCustomerNotes}
              dataTestId="cancel-order-notes"
            />
          </>
        </RayloModal>
      )}
    </>
  );
};
