import {
  Container,
  Header,
  IconWithTextLink,
  SectionMessage,
  LearnMoreModalContent,
  LeaseCard,
  LeaseTotalBar,
  RayloHeader,
  Divider,
  FAQs,
} from '@raylo-tech/raylopay-ui';
import { Copy, Spacer } from 'elements';
import { useEffect, useState } from 'react';
import { useCheckoutLeaseTermsContext } from 'screens/Checkout/containers/CheckoutLeaseTermsContainer/hooks/useCheckoutLeaseTermsContext';
import { AfterLeaseChoicesModal } from 'screens/Checkout/modals/AfterLeaseChoicesModal';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { useMedia } from 'use-media';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import ITestDataId from 'types/ITestDataId.type';
import { CancelMerchantOrderModal } from 'screens/Checkout/modals/CancelMerchantOrderModal';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { StyledStepLeaseTermsContent } from './styles';

export const StepLeaseTermsContent = ({ dataTestId }: ITestDataId) => {
  const {
    productName,
    imageUrl,
    costSummaries,
    costSummaryId,
    handleCostSummarySelect,
    formattedMonthlyPrice,
    handlePageSubmit,
  } = useCheckoutLeaseTermsContext();
  const { setHideProgressBar, setShowNavBar, setAfterLeaseChoiceModalOpen } =
    useUiContext();

  const [showCancelMerchantOrderModal, setShowCancelMerchantOrderModal] =
    useState(false);

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: '',
    checkoutScreen: 'select lease term',
    when: 'on page load',
  });

  useEffect(() => {
    setShowNavBar(false);
    setHideProgressBar(true);

    return () => {
      setShowNavBar(true);
      setHideProgressBar(false);
    };
  }, [setHideProgressBar, setShowNavBar]);

  const isMobile = useMedia({ maxWidth: 414 });

  return (
    <StyledStepLeaseTermsContent data-testid={dataTestId}>
      <Container
        backgroundColor={secondaryColors.c05}
        styles={{
          position: 'fixed',
          top: '0',
          right: '0',
          width: '100vw',
          height: '100vh',
          zIndex: '1',
          overflow: 'scroll',
        }}
        centerHorizontal
      >
        <Container
          maxWidth={px2Rem(414)}
          padding={isMobile ? `0 ${px2Rem(20)}` : '0'}
        >
          <RayloHeader onClose={() => setShowCancelMerchantOrderModal(true)} />
          <Spacer height={48} />
          <Header
            color={primaryColors.c01}
            level={2}
            subColor={secondaryColors.c02}
          >
            Welcome to Raylo.{' '}
            <span>First lets choose the length of your lease</span>
          </Header>
          <Spacer height={32} />
          <LeaseCard
            activeIndex={costSummaryId || ''}
            imageUrl={imageUrl}
            onPricePlanSelect={handleCostSummarySelect}
            pricePlans={costSummaries}
            title={productName}
          />
          <Spacer height={32} />
          <SectionMessage
            header="After the lease, what happens?"
            icon="RayloDesignInfo"
            iconSize={16}
            textLink="Find out about your choices"
            linkOnClick={() => setAfterLeaseChoiceModalOpen(true)}
          />
          <Spacer height={32} />
          <Container
            backgroundColor={primaryColors.w01}
            padding={`${px2Rem(20)} ${px2Rem(20)} ${px2Rem(4)} ${px2Rem(20)}`}
          >
            <Copy bold fontSize={16} lineHeight={20} color={primaryColors.c01}>
              How Raylo works
            </Copy>
          </Container>
          <Container
            styles={{
              boxShadow:
                '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <LearnMoreModalContent />
          </Container>
          <Spacer height={32} />
          <Container
            backgroundColor={primaryColors.w01}
            styles={{
              boxShadow:
                '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
              padding: `0 ${px2Rem(20)} ${px2Rem(20)} ${px2Rem(20)}`,
            }}
          >
            <FAQs headerPadding={`${px2Rem(20)} 0 ${px2Rem(24)} 0`} />
          </Container>
          <Spacer height={32} />
          <IconWithTextLink
            dataTestId="open-intercom-link"
            copy="Any questions?"
            icon="CustomerSupport"
            linkText="We're here to help"
            linkTextOnClick={() => window.Intercom('show')}
          />
          <Spacer height={32} />
          <Divider height={20} />
          <Copy fontSize={12} color={primaryColors.c01}>
            © Copyright of Raylo Group {new Date().getFullYear()}. All rights
            reserved. Raylo Group Limited is authorised and regulated by the
            Financial Conduct Authority (841488)
          </Copy>
          <Spacer height={120} />
          <Container
            styles={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              width: '100vw',
              zIndex: '2',
            }}
            backgroundColor={primaryColors.w01}
          >
            <LeaseTotalBar
              formattedInitialAmount="£0"
              formattedMonthlyPrice={formattedMonthlyPrice}
              onSubmit={handlePageSubmit}
            />
          </Container>
        </Container>
      </Container>
      <AfterLeaseChoicesModal />
      <CancelMerchantOrderModal
        modalOpen={showCancelMerchantOrderModal}
        onCloseModal={() => {
          setShowCancelMerchantOrderModal(false);
        }}
      />
    </StyledStepLeaseTermsContent>
  );
};
