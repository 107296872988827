import React, { useEffect, useState, Fragment } from 'react';
import useFormal from '@kevinwolf/formal-web';
import { useMutation, useQuery } from '@apollo/client';
import store from 'store';
import { Button } from 'components/common/Button';

import * as yup from 'yup';
import colors from 'styles/colors';
import { TextInput } from 'components/common/TextInput';
import { CheckboxInputString } from 'components/common/CheckboxInputString';
import { ACCEPTABLE_DATA_TOKEN_MUTATION } from '../graphql/mutations/acceptableData/createAcceptableDataMutation.graphql';
import { ACCEPTABLE_DATA_SUBSCRIPTION_OPTION_ENUM } from '../graphql/types/acceptableDataSubscriptionOptionType.graphql';
import { toSentence } from '../helpers/format/toSentence';
import { toCamelCase } from '../helpers/format/toCamelCase';
import { MerchantSelector } from 'components/QualityAssurance/merchantSelector';
import { EmailGenerator } from 'components/QualityAssurance/emailGenerator';
import { px2Rem } from 'utils/px2Rem';
import { useMerchantContext } from 'utils/useMerchantContext';
import Spacer from '../elements/Spacer';
import { ClientProvider, useClientContext } from '../utils/useClientContext';
import { useSharedCustomer } from 'utils/useSharedCustomer';

const schema = yup.object().shape({
  endpoint: yup.string().nullable().default(''),
  productsBaseUrl: yup.string().nullable().default(''),
  override: yup.string().default('Y'),
});

export const EndpointForm = () => {
  const { token } = useClientContext();
  const {
    setUserToken,
    removeDomainCookie,
    removeUserToken,
    resetCustomerContextData,
  } = useSharedCustomer();

  const getEndpoint = () => {
    return store.get('endpoint') || process.env.REACT_APP_GRAPHQL_ENDPOINT;
  };
  const getProductsBaseUrl = () => {
    return (
      store.get('productsBaseUrl') || process.env.REACT_APP_PRODUCTS_DOMAIN
    );
  };
  const { merchantId } = useMerchantContext();

  const [possibleSubscriptionOptions, setPossibleSubscriptionOptions] =
    useState([]);
  const [subscriptionOptionsChoices, setSubscriptionOptionsChoices] = useState(
    new Set(),
  );
  const [isFormValid, setIsFormValid] = useState(true);

  const setSubscriptionChoicesOnChange = (e) => {
    const checkboxValue = e.target.value;

    if (e.target.checked) {
      subscriptionOptionsChoices.add(checkboxValue);
    } else {
      subscriptionOptionsChoices.delete(checkboxValue);
    }
    setSubscriptionOptionsChoices(subscriptionOptionsChoices);

    const choicesValid = subscriptionOptionsChoicesValid(
      Array.from(subscriptionOptionsChoices),
      possibleSubscriptionOptions,
    );

    setIsFormValid(choicesValid);
  };

  function subscriptionOptionsChoicesValid(choices, possibleChoices) {
    return choices.every((element) => {
      return possibleChoices.includes(element);
    });
  }

  const [loginTestCustomer] = useMutation(ACCEPTABLE_DATA_TOKEN_MUTATION, {
    update: (
      proxy,
      {
        data: {
          createAcceptableData: { userToken },
        },
      },
    ) => {
      setUserToken(userToken);
      window.location = '/account';
    },
    skip: !merchantId,
  });

  const getOverride = () => {
    return store.get('override') || 'Y';
  };

  const resetLocalStorage = () => {
    console.log('Clearing local storage');
    resetCustomerContextData();
    removeUserToken();
    store.clearAll();
    window?.sessionStorage.clear();
    window.location.reload();
  };

  const resetToOriginal = () => {
    console.log('Reseting to original');
    store.remove('endpoint');
    store.remove('productsBaseUrl');
    window.location.reload();
  };

  const testCustomerLogin = () => {
    removeUserToken();
    removeDomainCookie('checkoutToken');
    store.remove('checkoutToken');
    loginTestCustomer({
      variables: {
        subscriptionOptions: Array.from(subscriptionOptionsChoices),
        merchantId,
      },
    });
  };

  const formal = useFormal(
    {
      endpoint: getEndpoint(),
      productsBaseUrl: getProductsBaseUrl(),
      override: getOverride(),
    },
    {
      schema,
      onSubmit: (values) => {
        store.set('endpoint', values.endpoint);
        store.set('productsBaseUrl', values.productsBaseUrl);
        store.set('override', values.override);
        window.location.reload();
      },
    },
  );

  useEffect(() => {
    // SET DEFAULTS
    store.set('override', getOverride());
  }, []);

  useQuery(ACCEPTABLE_DATA_SUBSCRIPTION_OPTION_ENUM, {
    onCompleted(data) {
      setPossibleSubscriptionOptions(
        data.__type.enumValues.map((value) => value.name),
      );
    },
  });

  const subscriptionOptionInputs = () => {
    return possibleSubscriptionOptions.map((option) => {
      let camelCasedOption = toCamelCase(option);

      return (
        <Fragment key={camelCasedOption}>
          <input
            key={`${camelCasedOption}_checkbox`}
            type="checkbox"
            id={camelCasedOption}
            name={camelCasedOption}
            value={option}
            data-testid={camelCasedOption}
            onChange={setSubscriptionChoicesOnChange}
          />
          <label key={`${camelCasedOption}_label`} htmlFor={camelCasedOption}>
            {' '}
            {toSentence(option)}
          </label>
          <br />
        </Fragment>
      );
    });
  };

  return (
    <div
      style={{
        marginTop: 20,
        padding: 20,
        backgroundColor: colors.rayloDark,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <div>
        <form {...formal.getFormProps()}>
          <TextInput
            label="GraphQL endpoint"
            value={getEndpoint()}
            field={formal.getFieldProps('endpoint')}
            tooltip="Add your endpoint e.g. https://raylo-api-staging-pr-1.herokuapp.com/"
            className={'sentry-unmask'}
            errorMessage={null}
          />
          <div style={{ marginTop: -30, marginBottom: 10 }}>
            current: <strong>{getEndpoint()}</strong>
          </div>
          <TextInput
            label="Products base URL"
            value={getProductsBaseUrl()}
            field={formal.getFieldProps('productsBaseUrl')}
            tooltip="Add your products base URL e.g. https://deploy-preview-1.products.early-access.raylo.com"
            className={'sentry-unmask'}
            errorMessage={null}
          />
          <div style={{ marginTop: -30, marginBottom: 10 }}>
            current: <strong>{getProductsBaseUrl()}</strong>
          </div>
          <CheckboxInputString
            value="Y"
            noValue="N"
            label="Allow similar customers"
            checked={formal.values.override === 'Y'}
            field={formal.getFieldProps('override')}
            errorMessage={null}
          />
          <Button type="submit" limitWidth="true">
            Set
          </Button>
          <Button limitWidth="true" onClick={resetToOriginal}>
            Use original
          </Button>
        </form>
      </div>

      <div style={{ paddingTop: 20 }}>
        <Button limitWidth="true" onClick={resetLocalStorage}>
          Clear Local storage
        </Button>
      </div>
      <div
        style={{
          paddingTop: px2Rem(40),
          maxWidth: px2Rem(300),
          margin: 'auto',
        }}
      >
        <MerchantSelector />
      </div>
      <div style={{ paddingTop: 20 }}>
        <fieldset style={{ width: '300px', margin: 'auto' }}>
          <legend>Subscription types</legend>

          <div style={{ textAlign: 'left', paddingLeft: '15px' }}>
            {subscriptionOptionInputs()}
          </div>
          <div style={{ paddingTop: 20 }}>
            <Button
              limitWidth="true"
              disabled={!isFormValid}
              onClick={testCustomerLogin}
              dataTestId="testCustomerLogin"
            >
              Create customer and login
            </Button>
          </div>
        </fieldset>
      </div>
      <Spacer height={16} />
      <ClientProvider value={token}>
        <EmailGenerator />
      </ClientProvider>
    </div>
  );
};
