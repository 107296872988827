import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { primaryColors } from '../../styles/variables/colors';
import { ButtonTypes, IButton } from './types';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

const StyledButton = styled.button<IButton>`
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  cursor: pointer;
  border: none;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  background-color: ${primaryColors.b01};
  ${({ size }) =>
    size === 'large' &&
    `
    font-size: ${px2Rem(14)};
    line-height: ${px2Rem(17)};
    text-transform: uppercase;
    height: ${px2Rem(58)};
    `}
  ${({ size }) =>
    size === 'medium' &&
    `
      line-height: ${px2Rem(17)};
      padding: ${px2Rem(15)} ${px2Rem(30)};
    `}
  ${({ size }) =>
    size === 'small' &&
    `
      padding: ${px2Rem(15)} ${px2Rem(30)};
      font-size: ${px2Rem(12)};
      line-height: ${px2Rem(14)};
      letter-spacing: 0.1rem;
    `}
  ${({ size, selected }) =>
    size === 'small' &&
    selected &&
    `
      padding: ${px2Rem(14)} ${px2Rem(29)};
    `}
  ${({ size }) =>
    size === 'xsmall' &&
    `
      padding: ${px2Rem(9)} ${px2Rem(12)};
      font-size: ${px2Rem(12)};
      line-height: ${px2Rem(14)};
      letter-spacing: 0;
    `}
  ${({ theme, buttonColor, active }) =>
    buttonColor === ButtonTypes.b01 &&
    `
      color: ${theme?.button?.fontColor?.white};
      background-color: ${theme?.button?.background?.b01};
      &:hover {
        background-color: ${theme?.button?.hover?.b01};
      }
      ${active && `background-color: ${theme?.button?.active?.b01};`}
    `}
  ${({ theme, buttonColor, active }) =>
    buttonColor === ButtonTypes.p01 &&
    `
      color: ${primaryColors.c01};
      background-color: ${theme?.button?.background?.p01};
      &:hover {
        background-color: ${theme?.button?.hover?.p01};
      }
      ${active && `background-color: ${theme?.button?.active?.p01};`}
      `}
  ${({ theme, buttonColor, active }) =>
    buttonColor === ButtonTypes.p02 &&
    `
    color: ${primaryColors.c01};
    background-color: ${theme?.button?.background?.p02};
    &:hover {
      background-color: ${theme?.button?.hover?.p02};
    }
    ${active && `background-color: ${theme?.button?.active?.p02};`}
    `}
  ${({ theme, buttonColor, active }) =>
    buttonColor === ButtonTypes.w01 &&
    `
    color: ${primaryColors.b01};
    background-color: ${primaryColors.w01};
    &:hover {
      background-color: ${theme?.button?.hover?.p02};
    }
    ${active && `background-color: ${theme?.button?.active?.p02};`}
    `}
  ${({ theme, buttonType, active }) =>
    buttonType === 'ghost' &&
    `
      pointer-events: cursor;
      color: ${theme?.button?.ghostType?.color};
      background: ${theme?.button?.ghostType?.background};
      border: ${theme?.button?.ghostType?.border};
      &:hover {
        background: ${theme?.button?.ghostType?.background};
        border: ${theme?.button?.ghostType?.hover?.border};
      }
    ${active && `border: ${theme?.button?.ghostType?.active?.border};`}
    `}

  ${({ theme, disabled, buttonType }) =>
    disabled &&
    `
    background: ${theme?.button?.disabled?.background};
    background-color: ${theme?.button?.disabled?.background};
    color: ${theme?.button?.disabled?.color};
    pointer-events: none;
    opacity: ${theme?.button?.disabled?.opacity};
    ${
      buttonType === 'ghost' &&
      `
      background: ${theme?.button?.ghostType.disabled?.background};
      color: ${theme?.button?.ghostType.disabled?.color};
      opacity: ${theme?.button?.ghostType.disabled?.opacity};
      border: ${theme?.button?.ghostType.disabled?.border};
    `
    }
  `}
  width: initial;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ selected }) =>
    selected && `border: ${px2Rem(2)} solid ${primaryColors.b01};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ styles }) => styles}
`;

export { StyledButton };
