import DateOfBirthInput from 'components/DateOfBirthInput';
import TextInput from 'elements/TextInput';
import { FormEvent, ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { dateValidation, isUser18, validation } from 'utils/formValidation';
import { IFormCollection } from './types';
import FormRadioList from 'components/FormRadioList/FormRadioList';
import { px2Rem } from 'utils/px2Rem';
import { RadioButtonBox } from '@raylo-tech/raylopay-ui';
import FormDropdown from 'components/FormDropdown/FormDropdown';

type ErrorObj = { [key: string]: string };

function removeInputFocusOnScroll() {
  // prevent changing the value of input[type=number] on scroll when the input is focused
  if (
    document.activeElement?.tagName === 'INPUT' &&
    (document.activeElement as HTMLInputElement).type === 'number'
  ) {
    (document.activeElement as HTMLInputElement).blur();
  }
}

export const FormCollection = ({
  dataTestId,
  fields,
  handleSubmit,
  formId,
  presetValues,
  setPresetValues,
}: IFormCollection) => {
  const [values, setValues] = useState<{ [key: string]: any }>(
    presetValues || {},
  );
  const [errors, setErrors] = useState<ErrorObj>({});
  const [success, setSuccess] = useState<{ [key: string]: boolean | string }>(
    {},
  );

  useEffect(() => {
    const formattedValues = { ...presetValues };
    if (
      presetValues?.dateOfBirth &&
      typeof presetValues?.dateOfBirth === 'string'
    ) {
      const dateArray = presetValues.dateOfBirth?.split('-');
      formattedValues.dateOfBirth = {
        DD: dateArray[2],
        MM: dateArray[1],
        YYYY: dateArray[0],
      };
    }
    if (presetValues?.mobilePhoneNumber) {
      formattedValues.mobilePhoneNumber =
        presetValues.mobilePhoneNumber.replace('+44', '0');
    }
    setValues((oldVals) => ({
      ...oldVals,
      ...formattedValues,
    }));
  }, [presetValues]);

  const handleInputChange = (
    field: string,
    e: ChangeEvent<HTMLInputElement>,
    type?: string,
    maxLength?: number,
  ) => {
    const { value, placeholder } = e.target;
    let valid = true;
    if (type && maxLength) {
      if ((type === 'number' || type === 'tel') && value?.length > maxLength) {
        valid = false;
      }
    }
    if (field === 'dateOfBirth') {
      if (placeholder === 'DD' || placeholder === 'MM') {
        if (value?.length > 2) {
          valid = false;
        }
      }
      if (placeholder === 'YYYY') {
        if (value?.length > 4) {
          valid = false;
        }
      }
    }
    if (valid) {
      if (field === 'dateOfBirth') {
        setValues({
          ...values,
          [field]: {
            ...values?.[field],
            [placeholder]: value,
          },
        });
      } else {
        setValues((vals) => ({
          ...vals,
          [field]: value,
        }));
      }
    }
  };

  const handleRadioSelect = (field: string, value?: string) => {
    setValues((oldVals) => ({
      ...oldVals,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors((oldErrors) => {
        const errs = { ...oldErrors };
        delete errs[field];
        return errs;
      });
      return;
    }

    setPresetValues?.((oldVals) => ({
      ...oldVals,
      [field]: value,
    }));
  };

  // validate completed date of birth

  const isDateOfBirthComplete = () => {
    const dob = values?.dateOfBirth;
    const day = dateValidation('day', dob?.DD);
    const month = dateValidation('month', dob?.MM);
    const year = dateValidation('year', dob?.YYYY);
    return day === 'success' && month === 'success' && year === 'success';
  };

  useEffect(() => {
    if (values?.dateOfBirth) {
      const dob = values?.dateOfBirth;
      const completeDOB = isDateOfBirthComplete();
      const validUserAge = isUser18(dob?.DD, dob?.MM, dob?.YYYY);
      if (completeDOB) {
        if (!validUserAge) {
          setErrors({
            ...errors,
            dateOfBirth: 'Sorry, you have to be 18 or older to sign up',
          });
        } else {
          delete errors.dateOfBirth;
        }
      } else {
        delete errors.dateOfBirth;
      }
    }
  }, [values?.dateOfBirth]);

  const handleValidation = (
    type: string,
    field: string,
    required: boolean,
    name: string,
  ) => {
    if (required) {
      const validationMessage = validation(type, values?.[field], field, name);

      if (validationMessage !== 'success') {
        delete success[field];
        setErrors({
          ...errors,
          [field]: validationMessage,
        });
      } else {
        delete errors[field];
        setSuccess({
          ...success,
          [field]: validationMessage,
        });
      }
    }
  };

  const formatFiles = () => {
    const valueObject = values;
    if (valueObject?.mobilePhoneNumber) {
      const formattedNumber = valueObject?.mobilePhoneNumber
        .replace(/\s/g, '')
        .trim();
      valueObject.mobilePhoneNumber = formattedNumber;
    }
    setValues(valueObject);
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    // validate all required fields
    const errorObject: ErrorObj = {};
    const customValidation = ['dateOfBirth'];
    fields.forEach(({ type, field, name }) => {
      if (field === 'middleName' && !values?.middleName) {
        return;
      }
      if (!customValidation.includes(field)) {
        const validationMessage = validation(
          type,
          values?.[field],
          field,
          name,
        );
        if (validationMessage !== 'success') {
          errorObject[field] = validationMessage;
        }
      }
      if (field === 'dateOfBirth') {
        const validationMessage = dateValidation(field, values?.[field]);
        if (validationMessage !== 'success') {
          errorObject[field] = validationMessage;
        }
      }
    });
    setErrors(errorObject);
    formatFiles();
    if (!Object.keys(errorObject).length) {
      handleSubmit(values);
    }
  };

  const handleOnBlur = (
    type: string,
    field: string,
    required: boolean,
    name: string,
  ) => {
    handleValidation(type, field, required, name);
  };

  // prevent changing the value of input[type=number] on scroll when the input is focused
  useEffect(() => {
    document.addEventListener('wheel', removeInputFocusOnScroll);
    return () => {
      document.removeEventListener('wheel', removeInputFocusOnScroll);
    };
  }, []);

  return (
    <>
      <form id={formId} onSubmit={handleSubmitForm} data-testid={dataTestId}>
        {fields.map(
          ({
            name,
            type,
            field,
            required,
            maxLength,
            tooltip,
            options,
            pattern,
            displayOptionalText,
          }) => {
            if (field === 'dateOfBirth') {
              return (
                <DateOfBirthInput
                  key={name}
                  dataTestId={`${dataTestId}-${field}`}
                  name={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(field, e, type, maxLength)
                  }
                  error={errors[field]}
                  values={values?.dateOfBirth}
                />
              );
            }

            if (type === 'dropdown') {
              return (
                <FormDropdown
                  key={name}
                  name={name}
                  options={options ?? []}
                  error={errors?.[field]}
                  field={field}
                  value={values?.[field]}
                  handleOnBlur={handleOnBlur}
                  onChange={handleRadioSelect}
                />
              );
            }

            // TODO rename these - they're not even radio buttons
            if (type === 'radio') {
              return (
                <div
                  key={name}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: px2Rem(16),
                    marginBottom: px2Rem(30),
                  }}
                >
                  {options?.map((option, i) => (
                    <RadioButtonBox
                      index={i}
                      key={option.id}
                      active={values?.[field] === option.id}
                      label={option.label}
                      onClick={() => handleRadioSelect(field, option.id)}
                      textAlignment="center"
                    />
                  ))}
                </div>
              );
            }

            if (type === 'radio-checkbox' && options) {
              return (
                <FormRadioList
                  key={name}
                  dataTestId={`${dataTestId}-${field}`}
                  options={options}
                  value={values?.[field]}
                  name={name}
                  errorMessage={errors?.accountType}
                  onChange={(e) =>
                    handleRadioSelect(field, e.target.value.toUpperCase())
                  }
                />
              );
            }
            return (
              <TextInput
                key={name}
                dataTestId={`${dataTestId}-${field}`}
                type={type}
                labelText={name}
                maxLength={maxLength}
                tooltip={tooltip}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(field, e, type, maxLength)
                }
                onBlur={() => handleOnBlur(type, field, required, name)}
                error={errors[field]}
                success={!!success[field]}
                value={values?.[field]}
                fieldName={field}
                pattern={pattern}
                onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                displayOptionalText={displayOptionalText}
              />
            );
          },
        )}
      </form>
    </>
  );
};
