import gql from 'graphql-tag';
import {
  subscriptionNextPaymentScheduleEvent,
  SubscriptionPaymentScheduleEvent,
} from '../../fragments/subscriptionNextPaymentScheduleEvent.fragment';

export const END_OF_LEASE_OPTIONS = gql`
  ${subscriptionNextPaymentScheduleEvent}
  query EndOfLeaseOptionsQuery {
    customer {
      id
      orders {
        id
        items {
          id
          variant {
            id
            product {
              id
              displayName
              category {
                id
                displayName
              }
            }
          }
          subscription {
            id
            arrearsAmount {
              valueInSubunit
            }
            costSummary {
              id
              recurring {
                totalAmount {
                  valueAfterTax {
                    currencyCode
                    formattedValue
                  }
                }
              }
            }
            upgrade {
              dueOn
            }
            ...SubscriptionPaymentScheduleEvent
          }
        }
      }
    }
  }
`;

export type EndOfLeaseOptionsQuery = {
  customer: {
    id: string;
    orders: Order[];
  };
};

export type Order = {
  id: string;
  items: {
    id: string;
    subscription: {
      id: string;
      arrearsAmount: {
        valueInSubunit: number;
      };
      costSummary: {
        id: string;
        recurring: {
          totalAmount: {
            valueAfterTax: {
              currencyCode: string;
              formattedValue: string;
            };
          };
        };
      };
      upgrade: {
        dueOn: string;
      };
      nextPaymentScheduleEvent: SubscriptionPaymentScheduleEvent;
    };
    variant: {
      id: string;
      product: {
        id: string;
        displayName: string;
        category: {
          id: string;
          displayName: string;
        };
      };
    };
  }[];
};
