import ImgixImage from 'elements/ImgixImage';
import TextLink from 'elements/TextLink';
import { buildProductImageUrl, buildProductURL } from 'utils/productUrlHelpers';
import { px2Rem } from 'utils/px2Rem';
import { CheckoutPhoneSummaryQueryQuery } from 'graphql/generated/graphql';
import { Typography } from '@raylo-tech/raylopay-ui';

type CheckoutOrderSummaryCartItemProps = {
  displayOnly?: boolean;
  variantDisplayName: NonNullable<
    CheckoutPhoneSummaryQueryQuery['checkout']
  >['items'][0]['variant']['displayName'];
  variantImageUrl: ReturnType<typeof buildProductImageUrl>;
  changeProductURL: ReturnType<typeof buildProductURL>;
  merchantHasChangeProductOption: boolean;
  onChangeProduct: () => void;
};

export const CheckoutOrderSummaryCartItem = ({
  displayOnly,
  variantDisplayName,
  variantImageUrl,
  changeProductURL,
  merchantHasChangeProductOption,
  onChangeProduct,
}: CheckoutOrderSummaryCartItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: px2Rem(24),
      }}
      data-testid="cos-cart-item"
    >
      <div style={{ width: px2Rem(100), flexShrink: 0 }}>
        {variantImageUrl && (
          <ImgixImage
            alt={variantDisplayName ?? ''}
            src={variantImageUrl}
            width={100}
            height={120}
            imageWidths={[100]}
            sizes="100px"
            dataTestId="cos-cart-item-image"
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: px2Rem(12),
        }}
      >
        <Typography variant="h4" bold>
          {variantDisplayName}
        </Typography>
        {!displayOnly && merchantHasChangeProductOption && changeProductURL && (
          <div>
            <TextLink
              dataTestId="ChangeProduct"
              onClick={onChangeProduct}
              fontColor="var(--cos-link-color)"
              medium
            >
              Change Product
            </TextLink>
          </div>
        )}
      </div>
    </div>
  );
};
