import * as LT from 'styles/legalText';

export const InsuranceCopy = () => (
  <div
    style={{
      padding: '16px 0 60px 0',
    }}
  >
    <p>
      <strong>Our Policy </strong>
    </p>
    <p>
      The policy is held in Our name and covers Us against the Goods being
      accidentally lost, stolen, or accidentally damaged. Given this is Our
      policy, You have no direct rights under the policy, but instead can
      benefit from Our policy, and claim on Our behalf should something happen
      to the Goods.
    </p>
    <p>
      You will only receive the benefits under Our policy if We make a
      successful claim, and any benefit to You will be limited to the amount
      that We receive.
    </p>
    <p>
      <strong>The Underwriter </strong>
    </p>
    <p>
      Our policy is underwritten by Collinson Insurance (a trading name of
      Astrenska Insurance Limited). Astrenska Insurance is authorised by the
      Prudential Regulation Authority and regulated by the Financial Conduct
      Authority and the Prudential Regulation Authority in the United Kingdom,
      under Firm Reference Number 202846. Registered office: 3 More London
      Riverside, 5th Floor, London, SE1 2AQ, United Kingdom. Registered in
      England number 01708613. These details can be checked on the Financial
      Services Register by visiting: www.fca.org.uk
    </p>
    <p>
      <strong>Excess</strong>
    </p>
    <p>
      As with most insurance policies, if We make a successful claim, there will
      be an excess charged as a contribution towards the cost of a repair to the
      Goods, or for a replacement Goods.
    </p>
    <p>
      If You claim on Our behalf on Our policy, You will be responsible for the
      payment of an excess, as follows:
    </p>

    <LT.RayloLegalTable>
      <tbody>
        <tr>
          <th>Goods type</th>
          <th>Accidental damage or liquid damage claims</th>
          <th>Accidental loss or theft claims</th>
        </tr>
        <tr>
          <td>All other goods</td>
          <td>&pound;89.00</td>
          <td>&pound;125.00</td>
        </tr>
        <tr className="double-border">
          <td>Headphones &amp; Watches</td>
          <td>&pound;30.00</td>
          <td>&pound;50.00</td>
        </tr>
        <tr className="double-border">
          <td>Consoles &amp; VR Headsets</td>
          <td>&pound;60.00</td>
          <td>&pound;80.00</td>
        </tr>
        <tr className="double-border">
          <td>iMac</td>
          <td rowSpan="5">&pound;99.00</td>
          <td rowSpan="5">&pound;175.00</td>
        </tr>
        <tr className="double-border">
          <td>iPhone 13/14/15 Pro and Pro Max</td>
        </tr>
        <tr>
          <td>iPad Pro 11 and 12.9 inch</td>
        </tr>
        <tr>
          <td>Galaxy Book3 Pro 14 &amp; 16 inch i7</td>
        </tr>
        <tr>
          <td>Samsung Galaxy S22/S23/S24 Plus and Ultra</td>
        </tr>
        <tr className="double-border">
          <td>MacBook Pro 14-inch M1/M2/M3</td>
          <td rowSpan="2">&pound;150.00</td>
          <td rowSpan="2">&pound;175.00</td>
        </tr>
        <tr>
          <td>MacBook Pro 16-inch M1/M2/M3</td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 5 16-inch (2024) 165Hz Intel Core i7 1TB Storage 16GB
            Memory RTX 4060
          </td>
          <td rowSpan="4">&pound;99.00</td>
          <td rowSpan="4">&pound;125.00</td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 5 16-inch (2024) 240Hz Intel Core i7 1TB Storage 16GB
            Memory RTX 4060
          </td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 5 16-inch (2024) 165Hz Intel Core i7 1TB Storage 16GB
            Memory RTX 4070
          </td>
        </tr>
        <tr>
          <td>
            Lenovo LOQ 15.6-inch (2024) Intel Core i7 1TB Storage 24GB Memory
          </td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 5 16-inch (2024) 240Hz Intel Core i7 1TB Storage 16GB
            Memory RTX 4070
          </td>
          <td rowSpan="2">&pound;99.00</td>
          <td rowSpan="2">&pound;150.00</td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 7 16-inch (2024) Intel Core i7 1TB Storage 32GB Memory
            RTX 4070
          </td>
        </tr>
        <tr>
          <td>
            Lenovo Legion 7 16-inch (2024) Intel Core i9 1TB Storage 32GB Memory
            RTX 4070
          </td>
          <td rowSpan="2">&pound;150.00</td>
          <td rowSpan="2">&pound;175.00</td>
        </tr>
        <tr>
          <td>
            Lenovo Legion Pro 7 16-inch (2023) AMD Ryzen 9 1TB Storage 32GB
            Memory RTX 4080
          </td>
        </tr>
        <tr className="double-border">
          <td>LG TV QNED80 43-inch 43QNED80T6A.AEK</td>
          <td>&pound;89.00</td>
          <td>&pound;125.00</td>
        </tr>
        <tr>
          <td>LG TV QNED80 55-inch 55QNED80T6A.AEK</td>
          <td>&pound;99.00</td>
          <td>&pound;125.00</td>
        </tr>
        <tr>
          <td>OLED TVs, All Sizes</td>
          <td>&pound;150.00</td>
          <td>&pound;175.00</td>
        </tr>
      </tbody>
    </LT.RayloLegalTable>

    <p>
      <strong>What is covered? </strong>
    </p>
    <ul type="disc">
      <li>Repair costs of the Goods if they are accidentally damaged.</li>
      <li>Replacement of the Goods if they are accidentally lost.</li>
      <li>Replacement of the Goods if they are stolen.</li>
      <li>
        Repair or replacement of the Goods in the event of mechanical breakdown
        when out of the manufacturer&rsquo;s warranty period.
      </li>
    </ul>
    <p>
      <strong>What is not covered? </strong>
    </p>
    <ul type="disc">
      <li>
        Any claim where all reasonable precautions have not been taken, for
        example accidental damage when a case and screen protector were not
        fitted.
      </li>
      <li>
        Theft from a motor vehicle unless the Goods were out of view in either
        an enclosed compartment, boot or luggage space and the vehicle&rsquo;s
        windows and doors were securely locked. In such an event You will need
        to provide additional evidence.
      </li>
      <li>
        Theft or loss of the Goods that have been left unattended, when away
        from Your home.
      </li>
      <li>
        Repairs carried out by third party repair centres not authorised or
        agreed for use by the Underwriter.
      </li>
      <li>
        A device that has been operating without a functioning network SIM card.
      </li>
      <li>
        Repair costs for wear, tear and cosmetic damage to the Goods that do not
        stop the Goods working normally.
      </li>
      <li>The relevant excess, as set out above.</li>
    </ul>
    <p>
      <strong>Restrictions on the cover </strong>
    </p>
    <ul type="disc">
      <li>2 claims within a rolling 12-month period.</li>
      <li>
        Replacement Goods may be with refurbished items, where possible with an
        identical specification, but may differ in colour. Replacements come
        with a 12-month warranty.
      </li>
      <li>
        Theft or loss coverage requires your device to have a passcode and "Find
        My" or “Find My Device”, where available, to be continuously enabled on
        your Goods and associated with your Apple ID, Samsung account or Google
        account for the duration of the coverage and throughout the Theft or
        Loss claims process.
      </li>
      <li>
        You will not be able to claim on Our behalf on the insurance unless Your
        Hire Payments are up to date, or if Your agreement to hire the Goods has
        been cancelled or terminated.
      </li>
    </ul>
    <p>
      <strong>Where does the cover apply? </strong>
    </p>
    <p>
      The policy will cover the Goods anywhere in the world. Repaired or
      replacement Goods will be delivered only to a UK address.
    </p>
    <p>
      <strong>How to make a claim on Our behalf on Our Policy </strong>
    </p>
    <p>
      Log in to your account on the website, or contact Us on the chat
      application, via email (<a href="mailto:help@raylo.com">help@raylo.com</a>
      ), or via phone (<a href="tel:0333 050 9880">0333 050 9880</a>) for
      information about how to make a claim.
    </p>
  </div>
);
