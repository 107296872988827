import { captureErrorEvent } from '../errorTracking';
import { getActiveAssetReturn } from './getActiveAssetReturn';
import { IGetCustomerDeviceOrderSubscription } from '../../screens/Account/DeviceProfile/graphql/deviceProfile.graphql';

export const navigateToReturnUrl = (
  subscription: Partial<IGetCustomerDeviceOrderSubscription>,
) => {
  const assetReturn = getActiveAssetReturn(subscription);
  const url = assetReturn?.customerCollectionUrl;

  if (url) {
    window.location.assign(url);
  } else {
    captureErrorEvent('Customer return label URL not found', {
      subscriptionId: subscription.id,
    });
  }
};
