import React from 'react';
import * as I from 'styles/inputs';

export const CheckboxInputString = ({
  label,
  errorMessage,
  field,
  altLabel,
  checked,
  value,
  noValue,
}) => {
  const { error } = field;

  const onChange = (e) => {
    field.onChange({ target: { value: e.target.checked ? value : noValue } });
  };

  return (
    <I.RayloFormRow>
      {label ? (
        <label
          htmlFor={field.name}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <I.RayloCheckbox
            {...field}
            onChange={onChange}
            value={value}
            type="checkbox"
            checked={checked}
          />
          <I.RayloCheckboxLabel>{label}</I.RayloCheckboxLabel>
        </label>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <I.RayloCheckbox
            {...field}
            onChange={onChange}
            value={value}
            type="checkbox"
            checked={checked}
          />
          <I.RayloCheckboxLabel htmlFor={field.name}>
            {altLabel}
          </I.RayloCheckboxLabel>
        </div>
      )}
      <I.RayloError visible={error}>
        {errorMessage || <span>&nbsp;</span>}
      </I.RayloError>
    </I.RayloFormRow>
  );
};
