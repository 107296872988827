import { Helmet } from 'react-helmet';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { PlainHeader } from 'components/common/PlainHeader';
import { ThemeProvider } from 'styled-components';
import { rayloLight } from 'styles/themes/rayloLight';
import { primaryColors } from 'styles/variables/colors';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { START_CHECKOUT_SECURITY_DEPOSIT_PAYMENT_MUTATION } from 'graphql/mutations/checkout/startCheckoutSecurityDepositPaymentMutation.graphql';
import { SecurePaymentInfo } from '../../../Payment/SecurePaymentInfo';
import Copy from 'elements/Copy';
import Container from 'elements/Container';
import Spacer from 'elements/Spacer';
import { DepositPaymentFormWrapper } from './DepositPaymentFormWrapper';

export const SecurityDepositScreen = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'security deposit',
    checkoutScreen: 'stripe form',
    when: 'on page load',
  });

  const [incomingPayment, setIncomingPayment] = useState<any>(null);

  const { checkoutToken } = useCheckoutContext();

  const [startDepositPayment] = useMutation(
    START_CHECKOUT_SECURITY_DEPOSIT_PAYMENT_MUTATION,
    {
      variables: { checkoutToken },
      onCompleted(data: any) {
        setIncomingPayment(
          data.startCheckoutSecurityDepositPayment.incomingPayment,
        );
      },
    },
  );

  useEffect(() => {
    if (incomingPayment) {
      return;
    }

    startDepositPayment();
  }, []);

  const backgroundColor = primaryColors.w01;

  return (
    <ThemeProvider theme={rayloLight}>
      <div
        className="checkout--index"
        style={{ backgroundColor, width: '100vw' }}
      >
        <Helmet>
          <title>Security Deposit - Raylo</title>
        </Helmet>
        <div className="checkout--header">
          <PlainHeader />
        </div>
        <div className="checkout--main">
          <div className="checkout--step">
            <SecurePaymentInfo />
            <Container
              padding="0"
              styles={{
                display: 'grid',
                gridTemplateColumns: '70% 30%',
              }}
            >
              <Copy color={primaryColors.c01} fontSize={18} bold>
                Total
              </Copy>
              <Copy
                color={primaryColors.c01}
                fontSize={18}
                bold
                textAlign="right"
              >
                {incomingPayment
                  ? incomingPayment.totalAmount.formattedValue
                  : null}
              </Copy>
            </Container>
            <Spacer height={56} />
            {checkoutToken ? (
              <DepositPaymentFormWrapper
                successUrl={`/checkout/decision/process-deposit/${incomingPayment?.id}`}
                checkoutToken={checkoutToken}
                amountValid={true}
              />
            ) : null}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
