import {
  contentColor,
  StyledBadge,
  StyledBadgeIconWrapper,
  StyledBadgeText,
  StyledBadgeWrap,
} from './Badges.styles';
import { BadgeProps } from './Badge.types';
import React from 'react';
import PreApprovedIcon from './PreApprovedIcon';

export const Badge = ({
  type,
  color,
  icon,
  fontSize,
  fontWeight,
  textTransform,
  children,
}: BadgeProps) => {
  return (
    <div>
      <StyledBadge
        $fontWeight={fontWeight}
        $type={type}
        $color={color}
        $fontSize={fontSize}
        $textTransform={textTransform}
      >
        {icon && (
          <StyledBadgeIconWrapper>
            <PreApprovedIcon data-testid="badge-icon" fill={contentColor(color)} />
          </StyledBadgeIconWrapper>
        )}
        <StyledBadgeText $textTransform={textTransform}>{children}</StyledBadgeText>
      </StyledBadge>
      {type === 'wrapped' && <StyledBadgeWrap data-testid="badge-wrap" $color={color} />}
    </div>
  );
};
