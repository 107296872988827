import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as B from 'styles/buttons';
import { BackButton } from 'components/common/BackButton';

export const RoyalMail = ({ onBack }) => {
  useEffect(() => {
    activateOptimize();
  });
  return (
    <div className="checkout--step feedback">
      <T.RayloBodyHeading>
        <strong>
          It looks like Royal Mail don’t have an accurate address for you.
        </strong>
      </T.RayloBodyHeading>

      <T.RayloBodyType style={{ marginBottom: 12 }}>
        Unfortunately we can’t process your order without a Royal Mail verified
        address.
      </T.RayloBodyType>

      <div style={{ marginBottom: 40 }}>
        <T.RayloBodyType style={{ marginBottom: 12 }}>
          <strong>Luckily, that’s easy to fix.</strong>
        </T.RayloBodyType>
        <T.RayloBodyList>
          <li>
            Tell Royal Mail on their{' '}
            <T.RayloLink
              href="https://www.royalmail.com/personal/receiving-mail/update-your-address"
              target="_blank"
            >
              missing address page
            </T.RayloLink>
          </li>
          <li>Come back here in 10 days and try again</li>
        </T.RayloBodyList>
      </div>

      <B.BackForwardContainer>
        <BackButton onClick={onBack} />
      </B.BackForwardContainer>
    </div>
  );
};
