import useMedia from 'use-media';

import Container from 'elements/Container';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Spacer from 'elements/Spacer';
import { track } from 'integrations/segment/events';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { navigateToReturnUrl } from 'utils/subscription/navigateToReturnUrl';
import { px2Rem } from 'utils/px2Rem';
import { IDeviceLeasePrimaryActions } from './types';
import { isTV } from 'screens/Account/utils/parseOrder.helper';
import { TextLink } from '@raylo-tech/raylopay-ui';
import { tvsHelpLinkURL } from 'utils/constants';

export const DeviceLeasePrimaryAction = ({
  order,
  onUpgrade,
  accountInArrears,
}: IDeviceLeasePrimaryActions) => {
  const orderState = order.status;

  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  const upgradeAvailable: boolean =
    orderState === YourTechStatus.upgradeAvailable && !accountInArrears;
  const returnStarted: boolean = orderState === YourTechStatus.returnStarted;
  const isInArrears: boolean = orderState === YourTechStatus.arrears;
  const isOverPayable: boolean =
    !!order.subscription?.nextPaymentScheduleEvent?.payable;
  const returnDue: boolean = orderState === YourTechStatus.returnDue;
  const showCTA: boolean =
    upgradeAvailable ||
    isOverPayable ||
    [
      YourTechStatus.arrears,
      YourTechStatus.returnStarted,
      YourTechStatus.returnDue,
    ].includes(orderState);

  const payNowClick = () => {
    track('My Account Pay Now Clicked', {
      screen: 'lease details',
      where: 'lease details tile',
      when: "on clicking the 'Pay Now' button on the product tile",
      url: window.location.href,
    });
    window.location.assign(`/account/payment/${order.subscription!.id}`);
  };
  const buttonText = (): string => {
    const labels = {
      [YourTechStatus.arrears]: 'pay now',
      [YourTechStatus.upgradeAvailable]: 'view upgrades',
      [YourTechStatus.returnStarted]: 'view return label',
      [YourTechStatus.returnDue]: 'start return',
    };

    if (labels[orderState]) return labels[orderState];
    if (isOverPayable) return 'make a payment';

    return '';
  };

  const buttonClick = (): void => {
    if (isInArrears || isOverPayable) {
      payNowClick();
      return;
    }
    if (upgradeAvailable) {
      onUpgrade();
      return;
    }
    if (returnStarted) {
      navigateToReturnUrl(order.subscription!);
      return;
    }
    if (returnDue) {
      window.location.assign(`/account/start-return/${order.subscription!.id}`);
    }
  };

  if (!showCTA) {
    return null;
  }

  const isTvReturn =
    isTV(order.orderItem.variant.product.category) &&
    (returnStarted || returnDue);

  return isTvReturn ? (
    <Container padding="0" width="100%">
      <span data-testid="tvReturnInfo">
        See our <TextLink href={tvsHelpLinkURL}>help article</TextLink> for more
        info on returning TVs.
      </span>
      <Spacer height={24} />
    </Container>
  ) : (
    <Container padding="0" width={isMobile ? '100%' : px2Rem(242)}>
      <Button
        dataTestId="deviceCardCTA"
        buttonColor={ButtonTypes.b01}
        size="medium"
        text={buttonText()}
        onClick={buttonClick}
      />
      <Spacer height={24} />
    </Container>
  );
};
