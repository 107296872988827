import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Lottie } from '@crello/react-lottie';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import colors from 'styles/colors';

import { FadeIn } from 'components/animations/Transitions';

import rayloLogo from 'public/raylo-logo.svg';
import downArrow from 'public/icon--arrow--white--down.svg';
import leftArrow from 'public/icon--arrow--white--left.svg';
import animatedLogo from 'public/logo.json';

export const Header = ({
  mobileHeader,
  phone,
  onToggle,
  showSummary,
  onBack,
}) => {
  const [showAnimatedLogo, setShowAnimatedLogo] = useState(true);
  const [fadeout, setFadeout] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeout(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimatedLogo(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HeaderSC>
      <HeaderContainer bordered={showSummary}>
        <HeaderLogo src={rayloLogo} />
        <HeaderOrderSummary>
          {showSummary ? (
            <FadeIn>
              <div
                className="order-summary"
                style={{ cursor: 'pointer' }}
                onClick={onToggle}
              >
                <p>Order Summary</p>
              </div>
            </FadeIn>
          ) : (
            <>
              {mobileHeader ? (
                <>
                  {showAnimatedLogo ? (
                    <FadeIn visible={fadeout}>
                      <div style={{ width: 106, height: 25 }}>
                        {process.env.NODE_ENV !== 'test' && (
                          <Lottie
                            config={{
                              animationData: animatedLogo,
                              loop: false,
                            }}
                          />
                        )}
                      </div>
                    </FadeIn>
                  ) : (
                    <FadeIn>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={onToggle}
                      >
                        <div className="model">
                          <p>{phone.model}</p>
                        </div>
                        <div className="mobile-order-summary">
                          <p>Order Summary</p>
                        </div>
                      </div>
                    </FadeIn>
                  )}
                </>
              ) : (
                <FadeIn>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={onToggle}
                  >
                    <div className="model">
                      <p>{phone.model}</p>
                    </div>
                    <div className="mobile-order-summary">
                      <p>Order Summary</p>
                    </div>
                  </div>
                </FadeIn>
              )}
            </>
          )}
          <div
            className="down-arrow"
            style={{ cursor: 'pointer' }}
            onClick={onToggle}
          >
            <HeaderDownArrow
              src={downArrow}
              style={{ display: 'block' }}
              showSummary={showSummary}
            />
          </div>
        </HeaderOrderSummary>
      </HeaderContainer>
    </HeaderSC>
  );
};

const HeaderSC = styled.header`
  width: 100%;
  height: 75px;
  padding: 0 40px;
  background-color: ${colors.rayloBlue};
  display: flex;
  z-index: 5;

  @media (min-width: 1025px) {
    background-color: transparent;
  }

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @media (max-width: 1024px) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      opacity: ${(props) => (props.bordered ? '0.7' : '0')};
      background-color: #7277ff;
      transition: opacity 1s;
    }
  }
`;

const HeaderLogo = styled.img`
  display: block;

  @media (max-width: 450px) {
    display: none;
  }
`;

const HeaderOrderSummary = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (min-width: 1025px) {
    display: none;
  }

  @media (max-width: 450px) {
    justify-content: space-between;
    width: 100%;
  }

  p {
    margin: 0;
  }

  .model {
    border-right: 2px solid #7277ff;
    padding-right: 12px;
    margin-right: 10px;

    p {
      font-weight: ${CSS_FONT_WEIGHT.vars.regular};
      font-size: 0.9375em;
      color: ${colors.rayloLight};
      letter-spacing: -0.16px;
    }
  }

  .mobile-order-summary {
    p {
      font-weight: ${CSS_FONT_WEIGHT.vars.medium};
      font-size: 0.9375em;
      color: ${colors.rayloPink};
      letter-spacing: 0;
    }
  }

  .order-summary {
    p {
      font-weight: ${CSS_FONT_WEIGHT.vars.regular};
      font-size: 0.9375em;
      color: ${colors.rayloLight};
      letter-spacing: -0.16px;
    }
  }

  .down-arrow {
    padding-left: 18px;

    @media (max-width: 440px) {
      padding-left: 8px;
    }
  }
`;

const HeaderDownArrow = styled.img`
  display: block;
  transform: ${(props) => (props.showSummary ? 'rotate(180deg)' : 'none')};
  transition: transform 0.35s;
`;

const HeaderBackLink = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.9375em;
  color: ${colors.rayloLight};
  letter-spacing: -0.16px;
  cursor: pointer;
  padding: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 12px;
    background-image: url('${leftArrow}');
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 14px;
  }
`;
