import { Button, Header, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import { IUpgradeAvailable } from './UpgradeAvailable.types';

export const UpgradeAvailable = ({ onNext, onClose }: IUpgradeAvailable) => {
  return (
    <>
      <Header fontSize={24}>Free upgrade available</Header>
      <Spacer height={24} />
      <Copy>
        Before you end your lease, would you like a free upgrade to a newer
        model?
      </Copy>
      <Spacer height={24} />
      <Button onClick={onNext} dataTestId="form-primary-button">
        No, end lease
      </Button>
      <Spacer height={24} />
      <Button onClick={onClose} secondary dataTestId="form-secondary-button">
        View upgrade options
      </Button>
    </>
  );
};
