import { useState, useEffect, useMemo } from 'react';
import { find } from 'lodash-es';
import { Modal } from 'components/common/Modal';
import { AccountFooter } from 'components/Account/AccountFooter';
import useRayloQuery from 'utils/useRayloQuery';
import * as A from 'styles/account';
import { Helmet } from 'react-helmet';
import { Devices } from '../../../components/Account/Devices';
import AddTechEligibilityInfo from 'components/AddTechEligibilityInfo';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import Icon from 'elements/Icon';
import { Variant, Order, CustomerOrder } from 'types/Checkout/types';
import { primaryColors } from 'styles/variables/colors';
import { useHistory } from 'react-router';
import ReferAFriend from 'components/ReferAFriend';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from '../../../styles/LEGACY_BREAKPOINTS';
import { px2Rem } from 'utils/px2Rem';
import { track } from 'integrations/segment/events';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { parseOrder } from 'screens/Account/utils/parseOrder.helper';
import { getOptionValue } from 'utils/getOptionValue';
import { VariantOptionSlugs } from 'types/variants/options/variantOptionSlugs';
import { GET_CUSTOMER_ACCOUNT_OVERVIEW } from './getCustomerAccountOverview.graphql';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { removeSessionStorage } from 'utils/handleSessionStorage';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { Recommendations } from 'components/Recommendations/Recommendations';
import { sendMessageToApp } from 'utils/sendMessageToApp';

export const Overview = () => {
  const {
    data: { customer },
    loading,
  } = useRayloQuery(GET_CUSTOMER_ACCOUNT_OVERVIEW);
  const [orders, setOrders] = useState([]);
  const [mappedOrders, setMappedOrders] = useState<Array<CustomerOrder>>([]);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);

  const history = useHistory();
  const { removeDomainCookie, readDomainCookie } = useSharedCustomer();

  const selectedOrders = mappedOrders.length ? mappedOrders : orders;
  const hasSubscriptionInArrears = useMemo(() => {
    return selectedOrders.some((order) => checkInArrears(order.subscription));
  }, [selectedOrders]);

  useEffect(() => {
    if (!customer && !loading) {
      track('Login Event', {
        page: 'Account overview',
        event: 'User redirected to /error',
        error: { message: 'customer-not-found' },
      });
      history.push('/login/error', {
        error: { message: 'customer-not-found' },
      });
    }
    sendMessageToApp({ action: 'CUSTOMER_DATA_RECEIVED', payload: customer });
  }, [customer, loading, history]);

  useEffect(() => {
    if (customer && customer.orders) {
      const customerOrders = customer.orders
        .map((order: Order) => parseOrder(order))
        .filter((order: Order) => order);
      setOrders(customerOrders);
    }
  }, [customer]);

  const preApproved = useMemo(() => {
    return (
      !!customer?.preApproval?.checkout || customer?.preApproval?.successful
    );
  }, [customer]);

  const activeOrderIsPending = useMemo(() => {
    return ['REFERRED', 'PENDING'].includes(
      customer?.preApproval?.checkout?.decision?.outcome,
    );
  }, [customer]);

  const preApprovalLimit = useMemo(() => {
    return customer?.preApproval?.recurringTaxableAmount?.valueAfterTax.value;
  }, [customer]);

  useEffect(() => {
    if (readDomainCookie('checkoutContext') !== CheckoutMode.UPGRADE) {
      removeDomainCookie('subscriptionId');
    }
    removeSessionStorage('checkoutToken');
    removeDomainCookie('checkoutToken');
  }, [removeDomainCookie, readDomainCookie]);

  useEffect(() => {
    if (selectedOrders.length > 0 && !trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'account overview',
        in_arrears: hasSubscriptionInArrears ? 'Yes' : 'No',
        when: 'on page load',
        url: window.location.href,
      });

      setTrackedAccountViewedEvent(true);
    }
  }, [selectedOrders, trackedAccountViewedEvent, hasSubscriptionInArrears]);

  useEffect(() => {
    const checkout = customer?.preApproval?.checkout;
    const itemsInReferralQueue = checkout?.items;
    if (activeOrderIsPending && !!itemsInReferralQueue) {
      const referredCheckoutItems = customer?.preApproval?.checkout?.items?.map(
        ({ variant }: { variant: Variant }) => {
          return {
            model: variant?.displayName,
            image: find(variant?.images, { tag: 'front' }),
            status: 'Pending further checks',
            storage: getOptionValue(variant, VariantOptionSlugs.Storage)
              .displayName,
            color: getOptionValue(variant, VariantOptionSlugs.Colour)
              .displayName,
            watchCaseSize: getOptionValue(
              variant,
              VariantOptionSlugs.WatchCaseSize,
            ).displayName,
            payMonthly:
              checkout?.costSummary?.recurring?.totalAmount?.valueAfterTax
                ?.value,
          };
        },
      );
      setMappedOrders([...orders, ...referredCheckoutItems]);
    }
  }, [customer, orders, activeOrderIsPending]);

  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  const headerText = () => {
    if (hasSubscriptionInArrears) {
      return (
        <div>
          <Icon
            name="WhiteAlert"
            size={isMobile ? px2Rem(28) : px2Rem(24)}
            styles={`
            ${
              isMobile
                ? ''
                : `
              display: inline-block;
              vertical-align: sub;
              margin-right: ${px2Rem(12)};
            `
            }
          `}
          />
          {isMobile && <Spacer height={12} />}
          <Copy fontSize={24} color="#fff" styles="display: inline-block;">
            You need to make a payment
          </Copy>
        </div>
      );
    }
    return (
      <Copy fontSize={24} color="#fff">
        Here is your account overview
      </Copy>
    );
  };

  if (loading || orders?.length === 0) {
    return <Modal visible text="Loading..." />;
  }

  return (
    <A.AccountContainer>
      <Helmet>
        <title>Overview - Raylo</title>
      </Helmet>
      <Container
        center
        backgroundColor={primaryColors.b01}
        styles={{
          textAlign: 'center',
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Spacer height={16} />
        <Header level={2} color="#fff">
          Hi {customer?.firstName}
        </Header>
        <Spacer height={12} />
        {headerText()}
        <Spacer height={24} />
      </Container>
      <Devices orders={selectedOrders} />
      <Spacer height={8} />
      {preApproved && !activeOrderIsPending && (
        <Recommendations preApprovalLimit={preApprovalLimit} />
      )}
      {!preApproved && <AddTechEligibilityInfo />}
      <ReferAFriend />
      <AccountFooter />
    </A.AccountContainer>
  );
};
