import * as React from 'react';
import { memo } from 'react';

const UpgradeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16 10.282V21.71M11.238 15.044 16 10.282l4.762 4.762"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2.668 15.996a13.333 13.333 0 1 0 26.667 0 13.333 13.333 0 0 0-26.667 0v0Z"
    />
  </svg>
);
const Memo = memo(UpgradeIcon);
export default Memo;
