import { palette } from './../../colors';
import { BREAKPOINTS, CSS_FONT_WEIGHT } from '../../constants';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';

const StyledPreApprovedBadgeContainer = styled.div`
  height: 100%;
  position: relative;
`;

const StyledPreApprovedBadgeBody = styled.div`
  position: absolute;
  left: ${px2Rem(-6)};
  top: ${px2Rem(-14)};
  background: ${palette.blue[400]};
  z-index: 99;
`;

const StyledPreApprovedBadgeInline = styled.div`
  padding: ${px2Rem(5)} ${px2Rem(8)};
  background: ${palette.blue[400]};
  color: ${palette.white};
  height: ${px2Rem(28)};
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  font-size: ${px2Rem(14)};
  border-radius: ${px2Rem(4)};
  width: fit-content;
  box-sizing: border-box;
`;

const StyledPreApprovedBadgeFold = styled.div`
  position: absolute;
  left: ${px2Rem(-6)};
  top: ${px2Rem(14)};
  border-bottom: ${px2Rem(6)} solid transparent;
  border-right: ${px2Rem(6)} solid ${palette.blue[500]};
  height: 0;
  width: 0;
`;

export {
  StyledPreApprovedBadgeContainer,
  StyledPreApprovedBadgeBody,
  StyledPreApprovedBadgeFold,
  StyledPreApprovedBadgeInline,
};
