import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { BREAKPOINTS } from '@raylo-tech/raylopay-ui';

export const StyledEndOfLeaseOptionsCard = styled.div`
  background-color: ${primaryColors.w01};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${px2Rem(4)};
  padding: ${px2Rem(24)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media screen and (min-width: ${BREAKPOINTS.smallDesktop}px) {
    max-width: 400px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}px) {
    max-width: 500px;
  }
`;

export const StyledBadgeAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${px2Rem(24)};
`;

export const StyledIconContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const StyledLeaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${primaryColors.b01};
  background: none;
  border: none;
  line-height: 1.5rem;
  cursor: pointer;
  margin-top: ${px2Rem(24)};
  padding: 0;
  &:hover {
    text-decoration: underline;
    color: ${secondaryColors.b02};
  }
`;

export const StyledArrowContainer = styled.div`
  display: flex;
  margin-left: ${px2Rem(8)};
`;

export const StyledLinksContainer = styled.div`
  margin-top: auto;
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 500;
  gap: ${px2Rem(16)};
  margin-top: ${px2Rem(24)};
`;

const sharedTagContainerStyles = `
  display: flex;
  flex-direction: row;
  gap: ${px2Rem(8)};
  align-items: center;
`;

export const StyledMostPopularTagContainer = styled.div`
  color: ${primaryColors.b01};
  ${sharedTagContainerStyles}
`;

export const StyledCarbonTagContainer = styled.div`
  color: ${uiStateColors.success};
  ${sharedTagContainerStyles}
`;

export const CtaContainerStyles = styled.div`
  max-width: ${px2Rem(320)};
  margin-left: 0;
  margin-top: ${px2Rem(24)};
`;
