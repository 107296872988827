import { IPaymentBanner } from './types';
import { primaryColors } from 'styles/variables/colors';
import { Spacer } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';
import { ButtonTypes } from 'elements/Button/types';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { Button } from 'elements';

export const PaymentBanner = ({ order }: IPaymentBanner) => {
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  return (
    <div
      style={{
        backgroundColor: primaryColors.b01,
        textAlign: 'center',
        padding: px2Rem(24),
      }}
    >
      <h2
        style={{
          color: primaryColors.w01,
        }}
      >
        {order.orderItem.variant.product.displayName}
      </h2>
      <Spacer height={16} />
      <Button
        dataTestId="make-payment-button"
        buttonColor={ButtonTypes.b01}
        buttonType="ghost"
        onClick={() => {
          window.location.assign(`/account/payment/${order.subscription?.id}`);
        }}
        size="medium"
        text="make a payment"
        fullWidth={isMobile}
      />
    </div>
  );
};
