import * as T from 'styles/type';
import { ILink } from './Link.types';

export const Link = ({
  children,
  href,
  target,
  inline,
  onClick,
  color,
  dataTestId,
}: ILink) => {
  return (
    <T.RayloLink
      href={href}
      target={target}
      inline={inline}
      onClick={onClick}
      color={color}
      data-testid={dataTestId}
    >
      {children}
    </T.RayloLink>
  );
};
