import { useCookies } from 'react-cookie';
import store from 'store';
import { getProductsBaseUrl } from './productUrlHelpers';

type ICookieNames =
  | 'checkoutToken'
  | 'userToken'
  | 'checkoutContext'
  | 'subscriptionId'
  | 'consumerType';
type IRayloCookieNames =
  | 'raylo_checkoutToken'
  | 'raylo_userToken'
  | 'raylo_checkoutContext'
  | 'raylo_subscriptionId';

export const useSharedCustomer = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'raylo_checkoutToken',
    'raylo_userToken',
    'raylo_checkoutContext',
    'raylo_subscriptionId',
    'mobileClient',
  ]);

  const redirectToProductsApp = (
    category?: string,
    preApproved: boolean = false,
  ) => {
    const searchParams = new URLSearchParams();
    if (category) {
      searchParams.set('category', category);
    }

    if (preApproved) {
      searchParams.set('monthlyprice', 'pre-approved');
    }
    searchParams.sort();
    const params = searchParams.toString();
    redirectToRayloUrl(params ? `?${params}` : '');
  };

  // We could deprecate this if we no longer support relative URLs
  const convertToProductAppUrlIfNeeded = (inputUrl: string) => {
    if (inputUrl.startsWith('https://') || inputUrl.startsWith('http://')) {
      // absolute url, do not change
      return inputUrl;
    }
    // relative url, prepend products app base url by default
    const pathSeparator =
      !inputUrl || inputUrl.startsWith('/') || inputUrl.startsWith('?')
        ? ''
        : '/';
    return `${getProductsBaseUrl()}/products${pathSeparator}${inputUrl}`;
  };

  const redirectToRayloUrl = (inputUrl: string) => {
    try {
      const targetUrl = convertToProductAppUrlIfNeeded(inputUrl);

      const url = new URL(targetUrl);
      const hostnameAllowed =
        url.hostname.endsWith('.raylo.com') ||
        url.hostname.endsWith('.raylopay.com') ||
        url.hostname === 'raylo.com' ||
        url.hostname === 'localhost';
      if (!hostnameAllowed) {
        window.location.assign('/account');
        return;
      }

      if (typeof analytics !== 'undefined') {
        const segmentAnonymousId = analytics?.user?.().anonymousId();
        if (segmentAnonymousId) {
          url.searchParams.set('segment_anonymous_id', segmentAnonymousId);
          url.searchParams.sort();
        }
      }

      window.location.assign(url.toString());
    } catch {
      window.location.assign('/account');
    }
  };

  const setDomainCookie = (
    name: ICookieNames,
    value: string | undefined | null,
  ) => {
    const cookieName = `raylo_${name}` as IRayloCookieNames;
    setCookie(cookieName, value, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  };

  const removeDomainCookie = (name: ICookieNames) => {
    const cookieName = `raylo_${name}` as IRayloCookieNames;

    removeCookie(cookieName, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  };

  const readDomainCookie = (name: ICookieNames) => {
    const cookieName = `raylo_${name}` as IRayloCookieNames;
    return cookies[cookieName];
  };

  const setUserToken = (token: string) => {
    setDomainCookie('userToken', token);
    store.set('userToken', token);
  };

  const removeUserToken = () => {
    removeDomainCookie('userToken');
    store.remove('userToken');
  };

  const readUserToken = () => {
    return readDomainCookie('userToken') || store.get('userToken');
  };

  const resetCustomerContextData = () => {
    removeCookie('raylo_checkoutToken');
    removeCookie('raylo_checkoutContext');
    removeCookie('raylo_subscriptionId');
  };

  const customerIsInMobileApp = !!window.ReactNativeWebView;

  return {
    setDomainCookie,
    removeDomainCookie,
    readDomainCookie,
    redirectToProductsApp,
    setUserToken,
    removeUserToken,
    readUserToken,
    resetCustomerContextData,
    redirectToRayloUrl,
    customerIsInMobileApp,
  };
};
