import { Spacer } from 'elements';
import { StyledProductCard } from './styles';
import { ProductCardProps } from './types';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { Container, Button, Copy } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';
import { useHistory } from 'react-router';
import { track } from 'integrations/segment/events';
import { useCheckoutDecisionContext } from '../CheckoutDecision/hooks/useCheckoutDecisionContext';

export const ProductCard = ({
  img,
  condition,
  displayName,
  price,
}: ProductCardProps) => {
  const history = useHistory();
  const { checkoutId } = useCheckoutDecisionContext();

  const handlePlaceOrder = () => {
    track('Place Order on Downsell Page Clicked', {
      displayName,
      checkoutId,
    });
    window.scrollTo(0, 0);
    history.push('/checkout/decision/not-ready-yet');
  };

  return (
    <StyledProductCard>
      <div>
        <img src={img} alt={displayName} />
      </div>
      <Container padding={`0 0 0 ${px2Rem(24)}`}>
        <Copy
          medium
          fontSize={14}
          lineHeight={16}
          color={
            condition === 'Refurbished'
              ? secondaryColors.p02
              : primaryColors.b01
          }
        >
          {condition}
        </Copy>
        <Copy bold fontSize={14} lineHeight={20} color={primaryColors.c01}>
          {displayName}
        </Copy>
        <Spacer height={8} />
        <Copy fontSize={14} lineHeight={16} color={secondaryColors.c02}>
          Monthly rolling
        </Copy>
        <Copy fontSize={14} lineHeight={20} bold color={primaryColors.c01}>
          £{price}/mo
        </Copy>
        <Container width="100%" alignRight padding={`${px2Rem(12)} 0 0 0`}>
          <Button xsmall onClick={handlePlaceOrder} dataTestId="place-order">
            Place order
          </Button>
        </Container>
      </Container>
    </StyledProductCard>
  );
};
