interface IMerchantFeatures {
  slug: string;
  enabled: boolean;
}

export const isFeatureEnabled = (
  array: IMerchantFeatures[],
  slugName: string,
) => {
  return array.find(({ slug }: IMerchantFeatures) => slug === slugName)
    ?.enabled;
};
