import {
  CSS_FONT_WEIGHT,
  SectionMessageBase,
  Typography,
} from '@raylo-tech/raylopay-ui';
import FormCollection from 'components/FormCollection';
import { Button } from 'elements';
import { ButtonTypes } from 'elements/Button/types';
import { ConsumerTypesEnum } from 'screens/Checkout/enums/consumerTypes';
import { px2Rem } from 'utils/px2Rem';
import { useCheckoutContext } from 'utils/useCheckoutContext';

const fields = [
  {
    field: 'leaseType',
    name: 'Type of lease',
    type: 'radio-checkbox',
    required: true,
    options: [
      {
        id: 'BUSINESS',
        label: 'BUSINESS',
      },
      {
        id: 'PERSONAL',
        label: 'PERSONAL',
      },
    ],
  },
];

const StepLeaseType = ({
  presetValue,
  onSuccess,
}: {
  onSuccess: () => void;
  presetValue?: ConsumerTypesEnum;
}) => {
  const { setConsumerType } = useCheckoutContext();
  // `FormCollection` converts the `fields.options` to uppercase, so do an extra check here to revert it back to `ConsumerTypesEnum`
  const onSubmit = ({ leaseType }: { leaseType: string }) => {
    setConsumerType(
      leaseType === 'BUSINESS'
        ? ConsumerTypesEnum.BUSINESS
        : ConsumerTypesEnum.PERSONAL,
    );
    onSuccess();
  };
  return (
    <>
      <Typography
        bold
        variant="h2"
        element="h2"
        style={{ paddingBottom: px2Rem(24) }}
      >
        Is this lease for business or personal use?
      </Typography>
      <FormCollection
        formId="step-lease-type"
        fields={fields}
        presetValues={{ leaseType: presetValue }}
        dataTestId="step-lease-type"
        handleSubmit={onSubmit}
      />
      <div style={{ paddingBottom: px2Rem(54) }}>
        <SectionMessageBase
          content={
            <>
              <Typography variant="body2" color="charcoal.500">
                We run a soft credit search on your personal and business
                details.{' '}
                <span style={{ fontWeight: CSS_FONT_WEIGHT.vars.bold }}>
                  This will not impact your credit score.
                </span>
              </Typography>
            </>
          }
        />
      </div>
      <Button
        type="submit"
        size="large"
        text="Continue"
        fullWidth
        buttonColor={ButtonTypes.b01}
        formId="step-lease-type"
      />
    </>
  );
};

export default StepLeaseType;
