import { useState } from 'react';
import constate from 'constate';

const useForm = () => {
  const [showExitModal, setShowExitModal] = useState<'logo' | 'close' | null>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);

  return {
    currentPage,
    setCurrentPage,
    showExitModal,
    setShowExitModal,
  };
};

const [FormProvider, useFormContext] = constate(useForm);
export { FormProvider, useFormContext };
