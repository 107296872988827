import { useState, useEffect } from 'react';

const MAX_ATTEMPTS = 6;
const ATTEMPT_INTERVAL = 5000;

// Hook
let cachedScripts = [];
export function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  const [attempt, setAttempt] = useState(0);

  const [internalSrc, setInternalSrc] = useState(src);

  useEffect(() => {
    if (attempt > 0) {
      setInternalSrc(src + '?' + attempt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt]);

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (
        cachedScripts.includes(internalSrc) ||
        process.env.NODE_ENV === 'test'
      ) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(internalSrc);

        setState({
          loaded: false,
          error: false,
        });

        // Create script
        let script = document.createElement('script');
        script.src = internalSrc;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(internalSrc);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          if (attempt < MAX_ATTEMPTS) {
            setTimeout(() => setAttempt(attempt + 1), ATTEMPT_INTERVAL);
          } else {
            setState({
              loaded: true,
              error: true,
            });
          }
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalSrc], // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
}
