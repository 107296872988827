import {
  StyledEndOfLeaseOptions,
  StyledEndOfLeaseOptionsContentContainer,
  StyledQuestionsContainer,
  StyledQuestionsLink,
  StyledQuestionsLinksContainer,
} from './EndOfLeaseOptions.styles';
import EndOfLeaseOptionsBanner from './EndOfLeaseOptionsBanner';
import { useParams } from 'react-router';
import { createRef, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  END_OF_LEASE_OPTIONS,
  EndOfLeaseOptionsQuery,
  Order,
} from '../../../graphql/queries/customer/endOfLeaseOptions.graphql';
import BannerFooter from './EndOfLeaseOptionsBanner/BannerFooter';
import EndOfLeaseOptionsCard from './EndOfLeaseOptionsCard';
import { endOfLeaseOptionsContent } from './utils/endOfLeaseOptionsContent';
import { Header, RayloModal } from '@raylo-tech/raylopay-ui';
import RightLineArrowIcon from './Icons/RightLineArrowIcon';
import { primaryColors } from 'styles/variables/colors';
import { StyledArrowContainer } from './EndOfLeaseOptionsCard/EndOfLeaseOptionsCard.styles';
import { CheckoutMode } from '../../Checkout/enums/checkoutModes';
import pluralize from 'pluralize';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { checkInArrears } from '../../../helpers/subscriptions/checkInArrears';

interface Params {
  orderId: string;
}

export function EndOfLeaseOptions() {
  const [order, setOrder] = useState<Order | null>(null);
  const [inArrears, setInArrears] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { orderId } = useParams<Params>();
  const { setDomainCookie, redirectToProductsApp } = useSharedCustomer();

  useQuery(END_OF_LEASE_OPTIONS, {
    onCompleted(data: EndOfLeaseOptionsQuery) {
      const order = data.customer.orders.find(
        (order: Order) => order.id === orderId,
      );
      setOrder(order || null);
      setInArrears(
        data.customer.orders.some((order: Order) =>
          checkInArrears(order.items[0].subscription),
        ),
      );
    },
  });

  const handleUpgrade = () => {
    if (inArrears) {
      setUpgradeModalOpen(true);
      return;
    }

    setDomainCookie('checkoutContext', CheckoutMode.UPGRADE);
    setDomainCookie('subscriptionId', order!.items[0].subscription.id);
    redirectToProductsApp(
      pluralize.plural(
        order!.items[0].variant.product.category.displayName.toLowerCase(),
      ),
    );
  };

  const handleCancel = () => {
    if (inArrears) {
      setCancelModalOpen(true);
      return;
    }

    window.location.assign(
      `/account/cancel-lease/${order!.items[0].subscription.id}`,
    );
  };

  const subscription = order?.items[0].subscription;
  const formattedMonthlyAmount =
    subscription?.costSummary.recurring.totalAmount.valueAfterTax
      .formattedValue || '';
  const monthlyPaymentDate =
    subscription?.nextPaymentScheduleEvent?.paymentOn || '';
  const finalPaymentDate = subscription?.upgrade?.dueOn || '';
  const productName = order?.items[0].variant.product.displayName || '';

  const optionsContents = useMemo(() => {
    if (!order) return [];

    return endOfLeaseOptionsContent({
      formattedMonthlyAmount,
      monthlyPaymentDate,
      finalPaymentDate,
      productName,
      handleUpgrade,
      handleCancel,
    }).map((content) => ({
      ref: createRef<HTMLDivElement>(),
      ...content,
    }));
  }, [order, handleUpgrade, handleCancel]);

  if (!order) return null;

  const currentLeaseInArrears = checkInArrears(subscription);

  const handlePayArrears = () => {
    window.location.assign(`/account/payment/${subscription!.id}`);
  };

  return (
    <StyledEndOfLeaseOptions>
      <EndOfLeaseOptionsBanner
        productDisplayName={order.items[0].variant.product.displayName}
        endOfLeaseDate={finalPaymentDate}
        contents={optionsContents}
      />
      <BannerFooter
        formattedMonthlyAmount={formattedMonthlyAmount}
        endOfLeaseDate={finalPaymentDate}
        onClick={() =>
          optionsContents[0].ref.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <StyledEndOfLeaseOptionsContentContainer>
        {optionsContents.map((optionContent, index) => (
          <EndOfLeaseOptionsCard
            key={`end-of-lease-options-card-${index}`}
            content={optionContent}
            ref={optionContent.ref}
          />
        ))}
      </StyledEndOfLeaseOptionsContentContainer>
      <StyledQuestionsContainer>
        <Header level={2} fontSize={20} centered>
          Still have questions?
        </Header>
        <StyledQuestionsLinksContainer>
          <StyledQuestionsLink
            onClick={() =>
              window.location.assign(
                'https://help.raylo.com/en/articles/8224647-start-a-chat',
              )
            }
          >
            <span>Contact us via chat</span>
            <StyledArrowContainer>
              <RightLineArrowIcon
                color={primaryColors.b01}
                height={15}
                width={16}
              />
            </StyledArrowContainer>
          </StyledQuestionsLink>

          <StyledQuestionsLink
            onClick={() =>
              window.location.assign(
                'https://help.raylo.com/en/articles/8316963-my-device-has-been-lost-or-stolen-what-should-i-do',
              )
            }
          >
            <span>My device is lost or stolen</span>
            <StyledArrowContainer>
              <RightLineArrowIcon
                color={primaryColors.b01}
                height={15}
                width={16}
              />
            </StyledArrowContainer>
          </StyledQuestionsLink>

          <StyledQuestionsLink
            onClick={() =>
              window.location.assign(
                'https://help.raylo.com/en/articles/3330261-our-repair-charge-grades-explained',
              )
            }
          >
            <span>My device is damaged</span>
            <StyledArrowContainer>
              <RightLineArrowIcon
                color={primaryColors.b01}
                height={15}
                width={16}
              />
            </StyledArrowContainer>
          </StyledQuestionsLink>
        </StyledQuestionsLinksContainer>
      </StyledQuestionsContainer>
      <RayloModal
        modalOpen={upgradeModalOpen}
        handleCloseModal={() => setUpgradeModalOpen(false)}
        title="Sorry, you aren’t eligible to upgrade right now"
        copy={[
          'Upgrade not available when you have an overdue balance.',
          'Once you pay your overdue balance you will be able to apply to upgrade.',
        ]}
        primaryButtonText={currentLeaseInArrears ? 'PAY NOW' : undefined}
        primaryButtonOnClick={handlePayArrears}
        secondaryButtonText="CLOSE"
        secondaryButtonOnClick={() => setUpgradeModalOpen(false)}
      />
      <RayloModal
        modalOpen={cancelModalOpen}
        handleCloseModal={() => setCancelModalOpen(false)}
        title="Sorry, you can't cancel right now"
        copy={[
          'You need to pay your overdue bill first, then you will be able to cancel.',
        ]}
        primaryButtonText={currentLeaseInArrears ? 'PAY NOW' : undefined}
        primaryButtonOnClick={handlePayArrears}
        secondaryButtonText="CLOSE"
        secondaryButtonOnClick={() => setCancelModalOpen(false)}
      />
    </StyledEndOfLeaseOptions>
  );
}
