import React from 'react';
import Container from '../../elements/Container';
import Header from '../../elements/Header';
import Copy from '../../elements/Copy';
import Spacer from '../../elements/Spacer';
import { StyledTrustbuilderBox } from './TrustbuilderBox.styles';
import { ITrustbuilderBox } from './TrustbuilderBox.types';
import { palette } from '../../colors';
import Icon from '../../elements/Icon';

export const TrustbuilderBox = ({ dataTestId, icon, title, copy }: ITrustbuilderBox) => {
  return (
    <StyledTrustbuilderBox data-testid={dataTestId}>
      <Container flexRow>
        <Container>
          <Header level={3} fontSize={16} color={palette.charcoal[500]}>
            {title}
          </Header>
          <Spacer height={8} />
          <Copy fontSize={16} lineHeight={22}>
            {copy}
          </Copy>
        </Container>
        <Container alignRight width="fit-content" margin="0 0 0 20px" center>
          <Icon name={icon} $width={72} />
        </Container>
      </Container>
    </StyledTrustbuilderBox>
  );
};
