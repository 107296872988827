import { useHistory, useParams } from 'react-router';

import { QL_IMEI } from 'screens/Account/queries';
import * as A from 'styles/account';
import * as T from 'styles/type';

import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link/Link';
import { Modal } from 'components/common/Modal';

import { Helmet } from 'react-helmet';
import useInsurance from './useInsuranceContext';

export const InsuranceCrime = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const { orderItem, loading } = useInsurance({
    query: QL_IMEI,
    isLostOrStolen: false,
    orderId,
  });

  if (loading || !orderItem.id) return <Modal visible text="Loading..." />;

  const asset = orderItem?.subscription?.activeAsset;

  return (
    <>
      <Helmet>
        <title>Obtain a Crime Reference Number - Raylo</title>
      </Helmet>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}>
                <BackButton
                  withText
                  onClick={() =>
                    history.push(`/account/insurance/stolen/block/${orderId}`)
                  }
                />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 0 }}>
                  Obtain a Crime Reference Number
                </T.RayloBodyHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.RayloBodyType>
                Before making a claim, you must report this incident to the
                police. You should do this by calling the non-emergency police
                number, 101, or by going into a station in person. This should
                be done within 48 hours of the incident.
              </T.RayloBodyType>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <T.RayloBodyType style={{ marginBottom: 20 }}>
                Give the station your phone’s IMEI number — it’s{' '}
                <span className="highlight">{asset?.imeiNumber}</span> — and
                make a note of the crime reference number they give you. You
                will be asked or it during the claims process.
              </T.RayloBodyType>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <Button
                buttonStyle="primaryBlue"
                onClick={() =>
                  history.push(`/account/insurance/stolen/claim/${orderId}`)
                }
              >
                I have a Crime Reference Number
              </Button>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>
                  Any questions?{' '}
                  <Link href="" onClick={() => window.Intercom('show')}>
                    Contact Us
                  </Link>
                </T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  );
};
