import {
  palette,
  SectionMessageWithIcon,
  Typography,
} from '@raylo-tech/raylopay-ui';
import FormCollection from 'components/FormCollection';
import { Button } from 'elements';
import { ButtonTypes } from 'elements/Button/types';
import { px2Rem } from 'utils/px2Rem';
import { fields, hiddenFields } from './constants';
import { useMemo } from 'react';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { StyledLink } from './StepBusinessType.styles';
import { FormValues } from '../CheckoutStepsContainer/types';

const StepBusinessType = ({
  presetValues,
  setPresetValues,
  onSuccess,
}: {
  presetValues?: FormValues;
  setPresetValues: (vals?: any) => void;
  onSuccess: () => void;
}) => {
  const { checkout } = useCheckoutContext();
  const fieldsToShow = useMemo(() => {
    const businessType = presetValues?.businessType;
    if (!businessType) return fields;

    const hiddenFieldsForBusinessType =
      hiddenFields[businessType as keyof typeof hiddenFields] || [];
    return [...fields, ...hiddenFieldsForBusinessType];
  }, [presetValues]);

  const handleSubmit = (values: any) => {
    setPresetValues(values);
    onSuccess();
  };

  const item = checkout?.items?.[0];
  const termLength =
    item?.costSummary?.termLength === 1
      ? 'monthly-rolling'
      : `${item?.costSummary?.termLength}-months`;
  const productSlug = `${item?.variant?.slug}-${termLength}`;
  const tooManyPartners =
    presetValues?.businessType === 'PARTNERSHIP' &&
    presetValues?.numberOfPartners === '4+';
  const notADirector =
    presetValues?.businessType === 'LIMITED_COMPANY' &&
    presetValues?.roleWithinBusiness === 'OTHER';
  const buttonDisabled =
    !presetValues?.businessType || tooManyPartners || notADirector;

  return (
    <>
      <Typography
        bold
        variant="h2"
        element="h2"
        style={{ paddingBottom: px2Rem(24) }}
      >
        Business Details
      </Typography>
      <FormCollection
        formId="step-business-type"
        fields={fieldsToShow}
        presetValues={presetValues}
        handleSubmit={handleSubmit}
        setPresetValues={setPresetValues}
      />
      {presetValues?.businessType === 'OTHER' && (
        <SectionMessageWithIcon
          iconLeftAlign
          backgroundColor={palette.warning[100]}
          borderColor={palette.warning[200]}
          icon="RayloDesignWarning"
          content={
            <>
              <Typography variant="body2" bold color="charcoal.500">
                Currently we can't support your business type.
              </Typography>
              <StyledLink
                href={`${process.env.REACT_APP_PRODUCTS_DOMAIN}/products/${productSlug}`}
                title="Checkout as consumer"
              >
                Checkout as a consumer instead
              </StyledLink>
            </>
          }
        />
      )}
      {(tooManyPartners || notADirector) && (
        <SectionMessageWithIcon
          iconLeftAlign
          backgroundColor={palette.error[100]}
          borderColor={palette.error[200]}
          icon="ErrorCircle"
          content={
            <Typography variant="body2" bold color="charcoal.500">
              {tooManyPartners
                ? 'Too many partners for this application'
                : 'Not a director'}
            </Typography>
          }
        />
      )}
      {presetValues?.businessType !== 'OTHER' && (
        <Button
          styles={`margin-top: ${px2Rem(24)}`}
          type="submit"
          size="large"
          text="Next"
          fullWidth
          buttonColor={ButtonTypes.b01}
          formId="step-business-type"
          disabled={buttonDisabled}
        />
      )}
    </>
  );
};

export default StepBusinessType;
