import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import store from 'store';
import { CREATE_MERCHANT_CHECKOUT } from 'graphql/mutations/checkout/createMerchantCheckout.graphql';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { setSessionStorage } from 'utils/handleSessionStorage';
import { useSharedCustomer } from 'utils/useSharedCustomer';

export const MerchantCheckoutCreation = () => {
  const {
    merchantId,
    merchantCheckoutJWT,
  }: { merchantId: string; merchantCheckoutJWT: string } = useParams();
  const history = useHistory();
  const { setDomainCookie } = useSharedCustomer();

  const [createMerchantCheckout] = useMutation(CREATE_MERCHANT_CHECKOUT, {
    onCompleted: (data) => {
      const checkoutToken = data?.createMerchantCheckout?.checkout?.token;
      if (checkoutToken) {
        store.set('checkoutToken', checkoutToken);
        setDomainCookie('checkoutToken', checkoutToken);
        setSessionStorage('merchantCheckout', 'true');
        history.push('/checkout');
      }
    },
    onError: (error) => {
      Sentry.captureMessage(error.message);
      history.push('/errors/invalid-choice');
    },
  });

  useEffect(() => {
    createMerchantCheckout({
      variables: {
        merchantId,
        payload: merchantCheckoutJWT,
      },
    });
  }, [merchantId, merchantCheckoutJWT, createMerchantCheckout]);

  return null;
};
