import { QL_CUSTOMER_SIMPLE } from 'screens/Account/queries';
import { AccountFooter } from 'components/Account/AccountFooter';
import { Modal } from 'components/common/Modal';
import useRayloQuery from 'utils/useRayloQuery';
import { Helmet } from 'react-helmet';
import Container from 'elements/Container';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { px2Rem } from 'utils/px2Rem';
import { Copy } from '@raylo-tech/raylopay-ui';
import TextLink from 'elements/TextLink';
import useMedia from 'use-media';
import { PIICopy } from 'elements/PIICopy/PIICopy';
import { LEGACY_BREAKPOINTS } from '../../styles/LEGACY_BREAKPOINTS';

export const YourAccount = () => {
  const {
    data: { customer },
    loading,
  } = useRayloQuery(QL_CUSTOMER_SIMPLE);
  const isTablet = useMedia({ maxWidth: LEGACY_BREAKPOINTS.tablet });

  const LabelHeader = ({ children }) => (
    <Header level={3} color={primaryColors.c01} fontSize={18}>
      {children}
    </Header>
  );

  const ContentCopy = ({ children }) => (
    <PIICopy color={primaryColors.c01} lineHeight={22} fontSize={18}>
      {children}
    </PIICopy>
  );

  if (loading) return <Modal visible text="Loading..." />;

  const address = customer?.address;

  return (
    <>
      <Helmet>
        <title>Your Account - Raylo</title>
      </Helmet>
      <Container backgroundColor={secondaryColors.c05} noPadding alignCenter>
        <Spacer height={24} />
        <Header level={2} color={primaryColors.c01} fontSize={24}>
          Account details
        </Header>
        <Spacer height={24} />
        <Container
          noPadding
          backgroundColor="#fff"
          maxWidth={px2Rem(1220)}
          boxShadow={!isTablet}
          alignCenter
        >
          <Container alignCenter maxWidth={px2Rem(571)}>
            <Spacer height={32} />
            <Container flexRow noPadding>
              <Container noPadding width="50%">
                <LabelHeader>First name</LabelHeader>
                <Spacer height={16} />
                <ContentCopy>{customer?.firstName}</ContentCopy>
                <Spacer height={56} />
                <LabelHeader>Email address</LabelHeader>
                <Spacer height={16} />
                <ContentCopy>{customer?.email}</ContentCopy>
                <Spacer height={56} />
                <LabelHeader>Account address</LabelHeader>
                <Spacer height={16} />
                <ContentCopy>{address?.line1}</ContentCopy>
                <ContentCopy>{address?.line2}</ContentCopy>
                <ContentCopy>{address?.line3}</ContentCopy>
                <ContentCopy>{address?.city}</ContentCopy>
                <ContentCopy>{address?.region}</ContentCopy>
                <ContentCopy>{address?.postcode}</ContentCopy>
              </Container>
              <Container noPadding width="50%">
                <LabelHeader>Last name</LabelHeader>
                <Spacer height={16} />
                <ContentCopy>{customer?.lastName}</ContentCopy>
              </Container>
            </Container>
            <Spacer height={56} />
            <Container noPadding>
              <Copy color={primaryColors.c01} bold>
                To change your contact details, please contact{' '}
                <TextLink onClick={() => window.Intercom('show')}>
                  customer support
                </TextLink>
              </Copy>
            </Container>
            <Spacer height={32} />
            <Container noPadding>
              <Copy color={primaryColors.c01} bold>
                If your circumstances change unexpectedly or you're experiencing
                issues,{' '}
                <TextLink href="https://help.raylo.com/en/articles/9626484-supporting-the-needs-of-all-our-customers">
                  get in touch with us
                </TextLink>
              </Copy>
            </Container>
            <Spacer height={32} />
          </Container>
        </Container>
        {!isTablet && <Spacer height={32} />}
      </Container>
      <AccountFooter />
    </>
  );
};
