import { useHistory } from 'react-router';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import NumberedSteps from 'components/NumberedSteps';
import Icon from 'elements/Icon';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { CHECKOUT_PAYMENT_INFO } from 'graphql/queries/checkout/paymentInfo/checkoutPaymentInfo.graphql';
import ITestDataId from 'types/ITestDataId.type';
import { Button } from '@raylo-tech/raylopay-ui';
import { StyledSecurityDepositView } from './styles';

export const StepSecurityDepositContent = ({ dataTestId }: ITestDataId) => {
  const history = useHistory();

  const [securityDeposit, setSecurityDeposit] = useState(null);

  const handleSubmit = () => {
    history.push('/checkout/decision/continuous-payment-authority');
  };

  const listItems = [
    {
      title: 'Pay your deposit ',
      text: 'Click below to securely pay by card.',
    },
    {
      title: 'We’ll ship your order',
      text: 'Once you’ve paid your deposit we’ll ship your device.',
    },
    {
      title: 'We’ll refund you',
      text: 'We’ll automatically refund you within one month. No need to get in touch with us.',
    },
  ];

  const { checkoutToken } = useCheckoutContext();
  useQuery(CHECKOUT_PAYMENT_INFO, {
    variables: {
      token: checkoutToken,
    },
    onCompleted(data: any) {
      setSecurityDeposit(
        data.checkout.paymentInfo.securityDepositAmount.formattedValue,
      );
    },
  });

  return (
    <StyledSecurityDepositView data-testid={dataTestId}>
      <Container center>
        <Icon
          name="SecurityDeposit"
          size={px2Rem(124)}
          color={secondaryColors.p02}
        />
      </Container>
      <Header level={3} fontSize={24} color={primaryColors.c01}>
        Based on your credit score we need to take a {securityDeposit} security
        deposit before we can ship your order.
      </Header>
      <Spacer height={24} />
      <Copy>
        We think it&apos;s better to give you this option rather than saying no.
        <span style={{ fontWeight: '700', marginLeft: px2Rem(3) }}>
          This deposit will be refunded to you within one month of your device
          being delivered.
        </span>
      </Copy>
      <Spacer height={40} />
      <Header level={4} color={primaryColors.c01}>
        Next Steps
      </Header>
      <Spacer height={24} />
      <NumberedSteps listItems={listItems} />
      <Spacer height={24} />
      <Button dataTestId="security-deposit-continue" onClick={handleSubmit}>
        Continue
      </Button>
    </StyledSecurityDepositView>
  );
};
