import React, { useEffect, useState } from 'react';
import { each, merge, isEmpty } from 'lodash-es';
import { activateOptimize } from 'utils/optimize';

// HOOKS
import useFormal from '@kevinwolf/formal-web';
import { useMutation } from '@apollo/client';
import useForceUpdate from 'use-force-update';

// STATE
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as I from 'styles/inputs';
import * as L from 'styles/layout';
import { RadioButton } from 'components/common/RadioButton';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { Modal } from 'components/common/Modal';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

// FORM VALIDATION
import * as yup from 'yup';
import useRayloQuery from 'utils/useRayloQuery';

const schema = yup.object().shape({
  marketingOptIn: yup.boolean().required('Please choose an option'),
});

export const StepTerms = ({ checkoutToken, onBack, onSuccess }) => {
  // HOOKS
  const [formErrors, setFormErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFields, setShowFields] = useState(true);
  const [updateMutation] = useMutation(CheckoutSteps.step7.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { checkout, errors },
        },
      },
    ) => {
      if (errors?.length > 0) {
        setFormErrors(errors);
        setIsSubmitting(false);
      } else {
        setShowFields(false);
        //setTimeout(() => {
        setFormErrors(null);
        onSuccess();
        //}, 800)
      }
    },
  });

  const [initialValues, setInitialValues] = useState({
    marketingOptIn: undefined,
  });
  const forceUpdate = useForceUpdate();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.step7.query, {
    variables: {
      token: checkoutToken,
    },
  });

  useEffect(() => {
    activateOptimize();
  });

  // HANDLE FORM ERRORS
  useEffect(() => {
    if (formErrors) {
      let errors = {};
      each(formErrors, (error) => {
        switch (error.field) {
          case 'base':
            errors.base = error.message;
            break;
          case 'marketing_opt_in':
            errors.marketingOptIn = error.message;
            break;
          default:
        }
      });
      formal.setErrors(errors);
    } else {
      formal.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  useEffect(() => {
    if (checkout) {
      setInitialValues(
        merge(initialValues, {
          marketingOptIn:
            checkout.customerInfo &&
            !isEmpty(checkout.customerInfo.marketingOptInAt)
              ? true
              : undefined,
        }),
      );
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  // FORM HANDLER
  const formal = useFormal(initialValues, {
    schema,
    onSubmit: (values) => {
      const blackboxData = window.IGLOO.getBlackbox();
      setIsSubmitting(true);
      updateMutation({
        variables: {
          ...values,
          deviceInfoFingerprint: blackboxData?.blackbox,
          checkoutToken: checkout?.token,
        },
      });
    },
  });

  const marketingRadio = (value) => {
    formal.change('marketingOptIn', value);
  };

  if (loading) return <Modal visible text="Loading..." />;

  const costSummary = checkout?.items[0]?.costSummary;

  return (
    <div className="checkout--step">
      <FadeIn visible={showFields}>
        <T.AlmostThere>
          <h1 className="almost">Almost there.</h1>
          <h3 className="next">Here’s what happens next ...</h3>
        </T.AlmostThere>
      </FadeIn>

      <form {...formal.getFormProps()}>
        <SlideDownFadeIn visible={showFields}>
          <T.Steps>
            <li>
              Please review the subscription Terms and Conditions. When you
              click ‘Place Order’, you consent for us to carry out a credit
              check. It won’t take a minute. It’s the responsible thing to do
              for you, and for us.
            </li>
            <li>
              When your {costSummary.termLength} month term is up you will have
              the opportunity to:
              <T.UnnumberedSteps>
                <li>
                  Apply for an upgrade, with no upgrade fees - we'll send you a
                  new device and you have 14 days to return the old one.
                </li>
                <li>
                  Continue - keep your Raylo device on a monthly rolling
                  subscription or by paying a one-off fee.
                </li>
                <li>Cancel by returning your Raylo device.</li>
              </T.UnnumberedSteps>
            </li>
          </T.Steps>
        </SlideDownFadeIn>

        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow>
            <p
              style={{
                fontSize: '0.875em',
                color: '#FFF',
                lineHeight: 1.625,
                marginBottom: 18,
              }}
            >
              Would you like to opt into marketing emails? We’ll only send you
              things we think are useful, promise.
            </p>

            <L.MarketingRadioButtons>
              <div style={{ marginRight: 28 }}>
                <RadioButton
                  type="marketing"
                  onClick={() => marketingRadio(false)}
                  checked={
                    formal.values.marketingOptIn == null
                      ? false
                      : !formal.values.marketingOptIn
                  }
                  id="marketingOptOut"
                  name="marketingOptOut"
                  value={0}
                  label="No"
                />
              </div>
              <div>
                <RadioButton
                  type="marketing"
                  onClick={() => marketingRadio(true)}
                  checked={formal.values.marketingOptIn}
                  id="marketingOptIn"
                  name="marketingOptIn"
                  value={1}
                  label="Yes"
                />
              </div>
            </L.MarketingRadioButtons>

            <I.RayloError visible={formal.errors.marketingOptIn}>
              {formal.errors.marketingOptIn || <span>&nbsp;</span>}
            </I.RayloError>
          </I.RayloFormRow>
        </SlideDownFadeIn>

        {formal.errors.base && (
          <FadeIn visible={showFields}>
            <div style={{ marginBottom: 48 }}>
              <I.RayloError visible={formal.errors.base}>
                {formal.errors.base || <span>&nbsp;</span>}
              </I.RayloError>
            </div>
          </FadeIn>
        )}

        <div style={{ marginTop: '20px' }}>
          <SlideDownFadeIn visible={showFields}>
            <I.RayloFormRow>
              <BackForwardGroup
                submit={{
                  ...formal.getSubmitButtonProps(),
                  disabled: isSubmitting,
                }}
                onBack={onBack}
              />
            </I.RayloFormRow>
          </SlideDownFadeIn>
        </div>
      </form>
    </div>
  );
};
