import Currency from 'react-currency-formatter';
import React from 'react';
import {
  Container,
  NumberStepContainer,
  SectionMessage,
  Spacer,
  StyledSpan,
  Copy,
} from '@raylo-tech/raylopay-ui';
import { useCheckoutDecisionContext } from '../../hooks/useCheckoutDecisionContext';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors } from 'styles/variables/colors';

export const DecisionAcceptedUpgrade = () => {
  const { oldDevice, costSummary } = useCheckoutDecisionContext();

  return (
    <Container
      dataTestId="upgradeReturnInstructions"
      maxWidth={px2Rem(414)}
      padding={`0 ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)}`}
    >
      <Container maxWidth={px2Rem(414)}>
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer step={1} small />
          </Container>
          <Container margin={`0 0 0 ${px2Rem(20)}`}>
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              We&#39;ll send you an email letting you know how to return your
              current <StyledSpan boldFont>{oldDevice}</StyledSpan>
            </Copy>
            <Spacer height={16} />
          </Container>
        </Container>
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer step={2} small />
          </Container>
          <Container margin={`0 0 0 ${px2Rem(20)}`}>
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              Click on the link in the email and choose your preferred return
              courier.
            </Copy>
            <Spacer height={16} />
          </Container>
        </Container>
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer step={3} small />
          </Container>
          <Container margin={`0 0 0 ${px2Rem(20)}`}>
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              Create the returns label (paperless options and collection options
              are available). It&apos;s crucial to use our label, otherwise the
              device is likely to get lost and you will be charged.
            </Copy>
            <Spacer height={16} />
          </Container>
        </Container>
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer step={4} small />
          </Container>
          <Container margin={`0 0 0 ${px2Rem(20)}`}>
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              Turn off &ldquo;Find My&rdquo; and take photos of the condition of
              the device.
            </Copy>
            <Spacer height={16} />
          </Container>
        </Container>
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer step={5} small />
          </Container>
          <Container margin={`0 0 0 ${px2Rem(20)}`}>
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              Package securely and drop off at your chosen location.
            </Copy>
          </Container>
        </Container>
        <Spacer height={16} />
        <SectionMessage
          content={
            <Copy color={primaryColors.c01} fontSize={14} lineHeight={22}>
              You should return your{' '}
              <StyledSpan boldFont>{oldDevice}</StyledSpan> as soon as possible{' '}
              as you’ll be charged
              <StyledSpan boldFont>
                {' '}
                <Currency
                  quantity={costSummary?.value as number}
                  currency={costSummary?.currencyCode}
                />
              </StyledSpan>
              /mo until we receive it.
            </Copy>
          }
          icon="RayloDesignInfo"
          iconSize={16}
        />
      </Container>
    </Container>
  );
};
