import { useEffect, useState } from 'react';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import FormErrorMessages from 'components/FormErrorMessages';
import Container from 'elements/Container';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import CheckBox from 'elements/CheckBox';
import Copy from 'elements/Copy';
import Divider from 'elements/Divider';
import Grid from 'elements/Grid';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import SelectComponent from 'elements/SelectComponent';
import { px2Rem } from 'utils/px2Rem';
import { useStepSignContext } from 'screens/Checkout/containers/StepSign/hooks/useStepSignContext';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useUiContext } from 'utils/useUiContext';
import {
  getSessionStorage,
  setSessionStorage,
} from 'utils/handleSessionStorage';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { ITermsAndConditions } from './types';
import { StyledTermsAndConditions } from './styles';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { IValue } from '@raylo-tech/raylopay-ui/build/cjs/components/SelectDropdown/SelectDropdown.types';

export const TermsAndConditions = ({
  dataTestId,
  children,
}: ITermsAndConditions) => {
  const [optInTrue, setOptInTrue] = useState(false);
  const [optInError, setOptInError] = useState(false);
  const [optInFalse, setOptInFalse] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTradeInTerms, setAcceptTradeInTerms] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [referral, setReferral] = useState<{ label: string; value: string }>();
  const { checkoutMode } = useCheckoutContext();
  const {
    loading: checkoutLoading,
    submitCheckout,
    submitCheckoutLoading,
    formErrors,
    tradeInExists,
  } = useStepSignContext();
  const { handleShowTradeInTermsModal } = useUiContext();

  const isNewCustomer = checkoutMode === CheckoutMode.NEW_CUSTOMER;

  const referralOptions = [
    { label: 'Search engine (Google, Bing, etc)', value: 'search_engine' },
    { label: 'Social Media', value: 'social_media' },
    {
      label: 'From a friend, family member or colleague',
      value: 'personal_recommendation',
    },
    { label: 'Television', value: 'television' },
    { label: 'On another website', value: 'another_website' },
  ];

  const handleOptIn = (value: string) => {
    if (value === 'in') {
      setOptInFalse(false);
      setOptInTrue(true);
    } else {
      setOptInFalse(true);
      setOptInTrue(false);
    }
  };

  useEffect(() => {
    const referralObject = getSessionStorage('referral');
    if (referralObject) {
      setReferral(JSON.parse(referralObject));
    }
  }, []);

  useEffect(() => {
    if (tradeInExists) {
      if (acceptTerms && acceptTradeInTerms) {
        setSubmitReady(true);
      }
      if (!acceptTerms || !acceptTradeInTerms) {
        setSubmitReady(false);
      }
    } else {
      if (acceptTerms) {
        setSubmitReady(true);
      }
      if (!acceptTerms) {
        setSubmitReady(false);
      }
    }
  }, [acceptTerms, acceptTradeInTerms, tradeInExists]);

  useEffect(() => {
    if (optInTrue || optInFalse) {
      setOptInError(false);
    }
  }, [optInTrue, optInFalse]);

  const handleReferralSelect = (option: IValue) => {
    setReferral(option);
    setSessionStorage('referral', option);
  };

  const handleSubmit = () => {
    if (!optInTrue && !optInFalse) {
      setOptInError(true);
    } else {
      setOptInError(false);
      submitCheckout({
        marketingOptIn: optInTrue,
        termsAndConditionsAccepted: true,
        tradeInTermsAndConditionsAccepted: tradeInExists,
        marketingReferralSource: referral?.value || 'not_selected',
      });
    }
  };

  return (
    <Container dataTestId={dataTestId} padding="0">
      <Container backgroundColor={secondaryColors.c05}>
        <StyledTermsAndConditions
          height={px2Rem(500)}
          data-testid="termsAndConditions"
        >
          {children}
        </StyledTermsAndConditions>
      </Container>

      <SlideDownFadeIn>
        <Container noPadding>
          <Container padding="0">
            <Spacer height={24} />
            <Copy color={primaryColors.c01} lineHeight={22}>
              Would you like to opt into marketing emails? <br />
              We’ll only send you things we think are useful, promise.
            </Copy>
            <Spacer height={16} />
            <Container padding="0">
              <Grid>
                <Container padding="0" width="50%">
                  <CheckBox
                    dataTestId="marketingOptOut"
                    id="testCheck"
                    label="No"
                    onClick={() => handleOptIn('out')}
                    active={optInFalse}
                  />
                </Container>
                <Container padding="0" width="50%">
                  <CheckBox
                    id="testCheck"
                    label="Yes"
                    onClick={() => handleOptIn('in')}
                    active={optInTrue}
                  />
                </Container>
              </Grid>
              <Spacer height={12} />
              {optInError && (
                <Copy color={uiStateColors.error} fontSize={12}>
                  Please select an option
                </Copy>
              )}
            </Container>
          </Container>
          <Spacer height={16} />
          <Container padding="0">
            <CheckBox
              dataTestId="acceptTerms"
              id="testCheck"
              label="I accept the Subscription T&Cs and agree to a credit check"
              onChange={() => {}}
              onClick={() => setAcceptTerms(!acceptTerms)}
              active={acceptTerms}
            />
          </Container>
          <Spacer height={16} />
          {tradeInExists && (
            <Container padding="0" flexRow>
              <Container noPadding width={px2Rem(36)}>
                <CheckBox
                  dataTestId="acceptTradeIn"
                  id="testCheck"
                  onChange={() => {}}
                  onClick={() => setAcceptTradeInTerms(!acceptTradeInTerms)}
                  active={acceptTradeInTerms}
                />
              </Container>
              <Container noPadding width="90%">
                <Copy>
                  I accept the{' '}
                  <TextLink onClick={handleShowTradeInTermsModal}>
                    Trade-in T&amp;Cs
                  </TextLink>
                </Copy>
              </Container>
            </Container>
          )}
          {isNewCustomer && (
            <Container padding="0">
              <Divider width="100%" color={secondaryColors.c04} />
              <Spacer height={16} />
              <SelectComponent
                dataTestId="marketingReferralSelect"
                label="How did you first hear about Raylo?"
                options={referralOptions}
                value={referral}
                isSearchable={false}
                onChange={handleReferralSelect}
              />
            </Container>
          )}
        </Container>
      </SlideDownFadeIn>
      <FormErrorMessages formErrors={formErrors} />
      <Spacer height={tradeInExists ? 64 : 32} />
      <Button
        dataTestId="placeOrder"
        disabled={!submitReady || checkoutLoading || submitCheckoutLoading}
        size="large"
        fullWidth
        text="place order"
        buttonColor={ButtonTypes.b01}
        onClick={handleSubmit}
      />
    </Container>
  );
};
