import React from 'react';
import styled from 'styled-components';
import * as B from 'styles/buttons';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const BackButton = ({ onClick, withText }) => {
  return (
    <BackButtonContainer>
      <B.RayloBackButton data-testid="back-button" onClick={onClick} />
      {withText && <p className="back-text">Back</p>}
    </BackButtonContainer>
  );
};

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;

  .back-text {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.75em;
    color: #fff;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 14px;
    cursor: pointer;
  }
`;
