import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

// Use a button element so it can be tabbed/selected using the keyboard
const StyledRadioSelectButton = styled.button<{
  $active: boolean;
}>`
  ${({ $active }) => `
    border-radius: 50%;
    width: ${px2Rem(24)};
    min-width: ${px2Rem(24)};
    height: ${px2Rem(24)};
    position: relative;
    background-color: ${palette.white};
    border: ${px2Rem(1)} solid ${palette.charcoal[400]};
    @media (min-width: 600px) {
      &:hover {
        background-color: ${palette.charcoal[100]};
      }
    };
    ${
      $active &&
      `
      background-color: ${palette.charcoal[100]};
    `
    }
  `};
`;

const StyledActiveElement = styled.div`
  border-radius: 50%;
  height: ${px2Rem(18)};
  width: ${px2Rem(18)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: ${palette.blue[500]};
`;

const StyledRadioSelectContainer = styled.div`
  display: flex;
  gap: ${px2Rem(12)};
  flex-direction: row;
  position: relative;
`;

export { StyledRadioSelectButton, StyledRadioSelectContainer, StyledActiveElement };
