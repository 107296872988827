import styled from 'styled-components';
import colors from './colors';
import { AccountWrapper } from './account';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const OuterWrapper = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // min-height: 100vh;
  // background-color: #000;
  // @media (max-width: 540px) {
  //   display: block;
  // }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50vw;
  min-height: 100vh;
  // width: 100%;
  // min-height: 100vh;
  // display: flex;
  // justify-content: flex-start;
  // position: relative;

  // background: green;

  // @media (max-width: 540px) {
  //   display: block;
  //   height: auto;
  // }
`;

export const PushDown = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > ${AccountWrapper} {
    flex: 1;
  }

  @media (max-height: 850px) {
    height: auto;
  }

  @media (max-width: 375px) and (min-height: 850px) {
    height: auto;
  }
`;

export const SignupFeedbackContainer = styled.div`
  padding: 75px 20px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  // @media (min-width: 1025px) and (max-width: 1330px) {
  //   width: 100%;
  // }

  // @media (max-width: 1024px) {
  //   padding: 20px;
  // }
  // @media (max-width: 768px) {
  //   width: 90%;
  // }

  // @media (max-width: 540px) {
  //   width: 100%;
  //   max-width: unset;
  //   padding: 20px;
  // }
`;

export const SignupAddressActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (max-width: 374px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;

    div {
      margin-bottom: 12px;
      margin-right: 0;
    }

    button {
      width: 100%;
    }
  }
`;

export const SignupFeedbackActions = styled.div`
  display: flex;

  div {
    margin-left: 18px;
  }

  div:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    div {
      width: 100%;
      margin-left: 0px;
      margin-top: 18px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
`;

const RayloSection = styled.div`
  border-bottom: ${(props) => (props.noBorder ? '0' : '1px solid #7277FF')};
  padding-bottom: 30px;
  margin-bottom: 20px;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .cell-lease {
    flex: 1;
    display: flex;
    justify-content: space-between;

    h5,
    p {
      flex: 1;
    }

    h5 {
      @media (min-width: 1331px) {
        margin-right: 50px;
      }
      @media (max-width: 1330px) {
        padding-right: 20px;
      }
    }
  }

  .cell {
    flex: 1;

    @media (min-width: 1331px) {
      &:not(:last-of-type) {
        margin-right: 50px;
      }
    }

    @media (max-width: 1330px) {
      &:not(:last-of-type) {
        padding-right: 20px;
      }
    }

    @media (max-width: 1200px) and (min-width: 1025px) {
      margin-bottom: 20px;

      &:only-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ConfigureSection = styled(RayloSection)`
  padding-bottom: 20px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
`;

export const MarketingRadioButtons = styled.div`
  display: flex;

  @media (max-width: 540px) {
    margin-bottom: 36px;
  }
`;

export const AcceptedCards = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #434343;
  border-bottom: 1px solid #434343;
  margin-bottom: 24px;

  span {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    font-size: 0.8125em;
    color: ${colors.rayloPink};
    line-height: 1.875em;
    display: inline-block;
    margin-right: 18px;
  }

  ul {
    margin: 0;
    padding: 0;
    & > * + * {
      margin-left: 16px;
    }

    li {
      display: inline-block;
    }
  }
`;
const TradeInBlock = styled.div`
  margin-bottom: 23px;
  p {
    margin-bottom: 16px;
  }
`;

const TradeInQuestion = styled.div`
  width: 100%;
  margin-bottom: 46px;
  p {
    margin-bottom: 16px;
  }
`;

const TrustPilotScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #404040;
  margin-bottom: 46px;
`;

const InsuranceSignup = styled.div`
  margin-bottom: 60px;

  .intro {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 1.85;
    margin-bottom: 40px;
  }

  .features {
    list-style-type: none;
    padding: 0;
    margin: 0 0 40px;

    li {
      display: flex;
      align-items: center;

      img {
        display: inline-block;
        flex-basis: 10%;
        margin-right: 12px;
      }

      span {
        flex-basis: 90%;
      }
    }
  }

  p,
  li {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 1.625em;
    margin-bottom: 18px;
  }
`;

const NewCheckoutWrapper = styled.div``;
const NewWrapper = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 75px 0;
  width: 50vw;
`;
const NewMain = styled.div`
  flex: 1;
`;
const NewContent = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
`;
const NewSidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 50vw;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.rayloBlue};

  ::-webkit-scrollbar {
    width: 9px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.rayloPink};
    border: 0;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  }

  scrollbar-color: ${colors.rayloPink} ${colors.rayloBlue};
  scrollbar-width: thin;
`;
const NewFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50vw;
  z-index: 20;
`;
const NewHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  height: 75px;
  padding: 0 40px;
  background-color: transparent; // ${colors.rayloBlue};
  display: flex;
  cursor: pointer;
`;
