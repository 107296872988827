// GQL
import gql from 'graphql-tag';
import { Checkout } from 'screens/Checkout/graphQL/fragments';

export const CheckoutSteps = {
  step0: {
    customer: gql`
      query CheckoutStepStartQuery {
        customer {
          id
          preApproval {
            successful
            checkout {
              token
            }
          }
          orders {
            id
            items {
              id
              subscription {
                id
                upgrade {
                  checkout {
                    id
                    token
                  }
                }
              }
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutCreateMutation(
        $costSummaryId: ID!
        $variantId: ID!
        $originSubscriptionId: ID
        $merchantId: ID!
      ) {
        createCheckout(
          input: {
            privacyPolicyAccepted: true
            merchantId: $merchantId
            items: [
              {
                variantId: $variantId
                costSummaryId: $costSummaryId
                originSubscriptionId: $originSubscriptionId
              }
            ]
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            initialPaymentMethodRequired
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepUpgrade: {
    query: gql`
      query CheckoutStepUpgradeQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            firstName
            middleName
            lastName
            dateOfBirth
            email
          }
          customer {
            id
            firstName
            middleName
            lastName
            dateOfBirth
            email
          }
          items {
            id
            originSubscription {
              id
              feeSummary {
                currentNonReturnFee {
                  value
                  currencyCode
                }
              }
              activeAsset {
                id

                variant {
                  id
                  product {
                    id
                    displayName
                  }
                }
              }
            }
            variant {
              id

              product {
                id
                displayName
              }
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStepUpgradeMutation(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $email: String!
        $dateOfBirth: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: {
              firstName: $firstName
              middleName: $middleName
              lastName: $lastName
              email: $email
              dateOfBirth: $dateOfBirth
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              firstName
              lastName
              dateOfBirth
              email
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepEmail: {
    mutation: gql`
      mutation CheckoutStep1Mutation($email: String!, $checkoutToken: String!) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: { customerInfo: { email: $email } }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              firstName
              lastName
              email
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
    query: gql`
      query CheckoutStepEmailQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          customer {
            id
            email
          }
          customerInfo {
            id
            email
          }
        }
      }
    `,
  },
  stepNameEmail: {
    query: gql`
      query CheckoutStepNameEmailQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            firstName
            middleName
            lastName
            email
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStepNameEmailMutation(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $email: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: {
              firstName: $firstName
              middleName: $middleName
              lastName: $lastName
              email: $email
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              firstName
              middleName
              lastName
              email
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepEmail: {
    mutation: gql`
      mutation CheckoutStepEmailMutation(
        $email: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: { customerInfo: { email: $email } }
        ) {
          errors {
            field
            code
            message
          }
        }
      }
    `,
  },
  step2: {
    query: gql`
      query CheckoutStep2Query($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            dateOfBirth
          }
          address {
            id
            country {
              isoAlpha2
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStep2Mutation(
        $dateOfBirth: String!
        $countryIsoAlpha2: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: { dateOfBirth: $dateOfBirth }
            address: { countryIsoAlpha2: $countryIsoAlpha2 }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              dateOfBirth
            }
            address {
              id
              country {
                isoAlpha2
              }
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepAddressCheck: {
    query: gql`
      query CheckoutStepAddressCheckQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          recentChangeOfAddress
          recentChangeOfCountry
          previousAddress {
            id
            line1
            line2
            line3
            city
            region
            postcode
          }
          address {
            id
            line1
            line2
            line3
            city
            region
            postcode
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStepAddressCheckMutation(
        $recentChangeOfAddress: Boolean
        $recentChangeOfCountry: Boolean
        $line1: String
        $line2: String
        $line3: String
        $city: String
        $region: String
        $postcode: String
        $countryIsoAlpha2: String
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            recentChangeOfAddress: $recentChangeOfAddress
            recentChangeOfCountry: $recentChangeOfCountry
            previousAddress: {
              line1: $line1
              line2: $line2
              line3: $line3
              city: $city
              region: $region
              postcode: $postcode
              countryIsoAlpha2: $countryIsoAlpha2
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            recentChangeOfAddress
            recentChangeOfCountry
            previousAddress {
              id
              line1
              line2
              line3
              city
              region
              postcode
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepAddress: {
    query: gql`
      query CheckoutStepAddressQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          recentChangeOfAddress
          recentChangeOfCountry
          items {
            id
            originSubscription {
              id
            }
          }
          customer {
            id
            address {
              id
              line1
              line2
              line3
              city
              region
              postcode
              country {
                id
                isoAlpha2
              }
            }
          }
          address {
            id
            line1
            line2
            line3
            city
            region
            postcode
            country {
              id
              isoAlpha2
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStep3Mutation(
        $line1: String
        $line2: String
        $line3: String
        $city: String
        $region: String
        $postcode: String
        $countryIsoAlpha2: String
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            address: {
              line1: $line1
              line2: $line2
              line3: $line3
              city: $city
              region: $region
              postcode: $postcode
              countryIsoAlpha2: $countryIsoAlpha2
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            address {
              id
              line1
              line2
              line3
              city
              region
              postcode
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  StepPersonalDetails: {
    mutation: gql`
      mutation CheckoutPersonalDetailsMutation(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $mobilePhoneNumber: String!
        $dateOfBirth: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: {
              firstName: $firstName
              middleName: $middleName
              lastName: $lastName
              mobilePhoneNumber: $mobilePhoneNumber
              dateOfBirth: $dateOfBirth
            }
          }
        ) {
          errors {
            field
            code
            message
          }
        }
      }
    `,
  },
  stepMobile: {
    query: gql`
      query CheckoutMobileQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            mobilePhoneNumber
            dateOfBirth
          }
          customer {
            id
            mobilePhoneNumber
          }
          items {
            id
            originSubscription {
              id
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutMobileMutation(
        $mobilePhoneNumber: String!
        $dateOfBirth: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: {
              mobilePhoneNumber: $mobilePhoneNumber
              dateOfBirth: $dateOfBirth
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              mobilePhoneNumber
              dateOfBirth
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
    mutation2: gql`
      mutation CheckoutMobileMutationTwo(
        $mobilePhoneNumber: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: { customerInfo: { mobilePhoneNumber: $mobilePhoneNumber } }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              mobilePhoneNumber
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepInsurance: {
    query: gql`
      query CheckoutStepInsuranceQuery($token: String!, $merchantId: ID!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          items {
            id
            ...CheckoutItemCostSummary
            variant {
              id
              ...VariantCostSummaries
            }
          }
        }
      }
      ${Checkout.fragments.checkoutItemCostSummary}
      ${Checkout.fragments.variantCostSummaries}
    `,

    mutation: gql`
      mutation CheckoutStepInsuranceMutation(
        $mobilePhoneNumber: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: { customerInfo: { mobilePhoneNumber: $mobilePhoneNumber } }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              mobilePhoneNumber
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepStripe: {
    query: gql`
      query CheckoutStepStripQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            firstName
            lastName
            email
          }
          address {
            id
            line1
            line2
            city
            postcode
            region
          }
          paymentInfo {
            id
            cardDetails {
              brand
              last4
            }
          }
          items {
            id
          }
        }
      }
    `,
  },
  stepBank: {
    query: gql`
      query CheckoutStepBankQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          items {
            id
            ...CheckoutItemCostSummary
            originSubscription {
              id
              paymentInfo {
                id
                bankAccountName
                bankAccountType
                bankAccountNumber
                bankBranchIdentifier
              }
            }
          }
          paymentInfo {
            id
            bankAccountName
            bankAccountType
            bankAccountNumber
            bankBranchIdentifier
            directDebitMandateAuthorised
          }
        }
      }
      ${Checkout.fragments.checkoutItemCostSummary}
    `,
    mutation: gql`
      mutation CheckoutStepBankMutation(
        $bankAccountName: String!
        $bankAccountNumber: String!
        $bankBranchIdentifier: String!
        $directDebitMandateAuthorised: Boolean!
        $checkoutToken: String!
        $bankAccountType: BankAccountType
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            paymentInfo: {
              bankAccountName: $bankAccountName
              bankAccountNumber: $bankAccountNumber
              bankBranchIdentifier: $bankBranchIdentifier
              directDebitMandateAuthorised: $directDebitMandateAuthorised
              bankAccountType: $bankAccountType
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            paymentInfo {
              id
              bankAccountName
              bankAccountNumber
              bankBranchIdentifier
              bankAccountType
              directDebitMandateAuthorised
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  step7: {
    query: gql`
      query CheckoutStep7Query($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          termsAndConditionsAcceptedAt
          customerInfo {
            id
            marketingOptInAt
          }
          items {
            id
            costSummary {
              termLength
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStep7Mutation(
        $marketingOptIn: Boolean!
        $deviceInfoFingerprint: String
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            customerInfo: { marketingOptIn: $marketingOptIn }
            deviceInfo: { fingerprint: $deviceInfoFingerprint }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              id
              marketingOptInAt
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
  stepTradeIn: {
    query: gql`
      query CheckoutStepTradeIn($token: String!) {
        checkout(token: $token) {
          id
          items {
            id
            costSummary {
              id
              termLength
              country {
                id
              }
            }
          }
          tradeIns {
            id
            grade {
              id
              slug
            }
            costSummary {
              displayName
              total {
                value
                formattedValue
                currencyCode
              }
              recurring {
                amount {
                  value
                  formattedValue
                  currencyCode
                }
                termLength
              }
            }
          }
        }
      }
    `,
    queryMax: gql`
      query CheckoutStepTradeInMax(
        $token: String!
        $costSummaryInput: TradeInMaxCostSummaryInput!
      ) {
        checkout(token: $token) {
          id
          items {
            id
            variant {
              id
              headlineTradeInMaxCostSummary(input: $costSummaryInput) {
                total {
                  value
                  formattedValue
                  currencyCode
                }
                recurring {
                  amount {
                    value
                    formattedValue
                    currencyCode
                  }
                  termLength
                }
              }
            }
          }
        }
      }
    `,
    queryManufacturers: gql`
      query queryManufacturersForTradeIn(
        $merchantId: ID!
        $productCategoryIds: [ID!]
      ) {
        manufacturers(
          forTradeIn: true
          forCheckout: false
          merchantId: $merchantId
          productCategoryIds: $productCategoryIds
        ) {
          id
          displayName
          products(
            forTradeIn: true
            forCheckout: false
            merchantId: $merchantId
            productCategoryIds: $productCategoryIds
          ) {
            id
            displayName
            position
          }
        }
      }
    `,
  },
  step8: {
    query: gql`
      query CheckoutStep8SignQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          reference
          termsAndConditionsAcceptedAt
          tradeInTermsAndConditionsAcceptedAt
          tradeIns {
            id
          }
          items {
            id
            hireAgreement {
              nobodySigned {
                htmlContent
              }
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutStep8SignMutation(
        $termsAndConditionsAccepted: Boolean!
        $tradeInTermsAndConditionsAccepted: Boolean
        $checkoutToken: String!
        $marketingOptIn: Boolean!
        $marketingReferralSource: String!
        $deviceInfoFingerprint: String
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken
          input: {
            termsAndConditionsAccepted: $termsAndConditionsAccepted
            tradeInTermsAndConditionsAccepted: $tradeInTermsAndConditionsAccepted
            customerInfo: {
              marketingOptIn: $marketingOptIn
              marketingReferralSource: $marketingReferralSource
            }
            deviceInfo: { fingerprint: $deviceInfoFingerprint }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            reference
            termsAndConditionsAcceptedAt
            tradeInTermsAndConditionsAcceptedAt
            customerInfo {
              id
              marketingOptInAt
            }
          }
          errors {
            field
            code
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}
    `,
  },
};

export const QL_HEADER = gql`
  query CheckoutHeaderQuery($token: String!) {
    checkout(token: $token) {
      id
      items {
        id
        ...CheckoutItemVariant
      }
    }
  }
  ${Checkout.fragments.variant}
`;

export const QL_CHECKOUT_STATE = gql`
  query CheckoutStateQuery($token: String!) {
    checkout(token: $token) {
      id
      token
      state
      decision {
        id
        outcome
        outcomeReason
      }
    }
  }
`;

export const QL_CHECKOUT_BASE = gql`
  query CheckoutBaseQuery($token: String!) {
    checkout(token: $token) {
      ...CheckoutBase
      merchant {
        id
        brandingTheme
        features {
          enabled
          slug
        }
      }
      customer {
        id
        subscriptions {
          startedOn
          paymentInfo {
            bankAccountName
            bankAccountNumber
            bankAccountType
            bankBranchIdentifier
          }
        }
      }
      customerInfo {
        id
        email
      }
      items {
        id
        originSubscription {
          id
        }
        variant {
          id
          slug
          condition
          product {
            id
            displayName
            category {
              id
              slug
              displayName
            }
          }
        }
        costSummary {
          id
          includesInsurance
          termLength
          recurringPeriod
        }
      }
      experiments {
        name
        variation
      }
      knownDeliveryDates
      canAddTradeIn
    }
  }
  ${Checkout.fragments.base}
`;

export const QL_SUMMARY = gql`
  query CheckoutPhoneSummaryQuery($token: String!, $merchantId: ID!) {
    checkout(token: $token) {
      id
      state
      decision {
        id
        outcome
        outcomeReason
      }
      items {
        id
        variant {
          id
          displayName
          slug
          condition
          optionValues {
            id
            displayName
            raw
            optionType {
              id
              slug
            }
          }
          deliveryExpectedBetween(merchantId: $merchantId) {
            max
            min
          }
          product {
            id
            displayName
            preOrder
            releasedAt
            category {
              displayName
              id
            }
            manufacturer {
              id
              displayName
            }
          }
          images {
            alt
            position
            tag
            title
            url
          }
        }
        ...CheckoutItemCostSummary
      }
      tradeIns {
        id
        grade {
          id
          slug
        }
        costSummary {
          displayName
          recurring {
            amount {
              value
              formattedValue
              currencyCode
            }
          }
          total {
            value
            formattedValue
            currencyCode
          }
        }
      }
      referrer {
        firstName
      }
      ...CheckoutCustomerInfo
      ...CheckoutAddress
      ...CheckoutPaymentInfo
    }
    referralProgram {
      reward {
        referralDescription
      }
    }
  }
  ${Checkout.fragments.checkoutItemCostSummary}
  ${Checkout.fragments.customerInfo}
  ${Checkout.fragments.address}
  ${Checkout.fragments.paymentInfo}
`;

export const QL_DECISION = gql`
  query CheckoutDecisionQuery($checkoutToken: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      redirectUrl
      customer {
        id
        userToken
        preApproval {
          successful
          recurringTaxableAmount {
            valueAfterTax {
              formattedValue
              value
            }
          }
        }
      }
      merchant {
        id
        displayName
        id
      }
      decision {
        id
        outcome
        outcomeReason
      }
      ...CheckoutAddress
      items {
        id
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
        originSubscription {
          id
          costSummary {
            recurring {
              totalAmount {
                valueAfterTax {
                  currencyCode
                  value
                }
              }
            }
          }
          feeSummary {
            currentNonReturnFee {
              value
              currencyCode
            }
          }
          activeAsset {
            id

            variant {
              id
              product {
                id
                displayName
              }
            }
          }
        }
        deliveryEstimatedBetween {
          min
          max
        }
        deliveryExpectedBetween {
          min
          max
        }
        variant {
          id
          images {
            alt
            url
            tag
          }
          preOrder
          product {
            id
            displayName
            preOrder
          }
        }
      }
    }
  }
  ${Checkout.fragments.address}
`;

export const QL_RISK_PRICING = gql`
  query CheckoutRiskPricingQuery($checkoutToken: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        id
        outcome
        outcomeReason
      }
      customerInfo {
        id
        email
      }
      items {
        id
        ...CheckoutItemCostSummary
        ...CheckoutItemVariant
      }
    }
  }
  ${Checkout.fragments.checkoutItemCostSummary}
  ${Checkout.fragments.variant}
`;

export const QL_OPEN_BANKING = gql`
  query CheckoutBankingConnectionQuery(
    $checkoutToken: String!
    $callbackUri: String!
  ) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        id
        outcome
        outcomeReason
      }
      bankingConnection {
        hasProviderErrors
        state
        provider
        oauthRedirectUri(callbackUri: $callbackUri)
      }
      address {
        id
        postcode
      }
      customerInfo {
        id
        email
        firstName
        lastName
        mobilePhoneNumber
      }
      paymentInfo {
        bankName
        bankAccountNumber
        bankBranchIdentifier
      }
      incompleteAttributes {
        code
        field
        message
      }
    }
  }
`;

export const GET_MERCHANT_ID = gql`
  query MerchantQuery($domain: String!) {
    merchant(domain: $domain) {
      id
    }
  }
`;

export const CHECKOUT_LEASE_TERM_QUERY = gql`
  query CheckoutLeaseTermQuery($token: String!) {
    checkout(token: $token) {
      redirectUrl
      merchant {
        id
        displayName
        id
      }
      items {
        id
        variant {
          displayName
          images {
            url
          }
          id
        }
        costSummary {
          id
        }
        availableCostSummaries {
          termLength
          id
          recurring {
            totalAmount {
              valueAfterTax {
                formattedValue
              }
            }
          }
          includesInsurance
        }
      }
    }
  }
`;
