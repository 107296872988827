import React from 'react';
import styled from 'styled-components';

import { OverduePayment } from 'components/Account/OverduePayment';
import { NextPayment } from 'components/Account/NextPayment';
import { PreviousPayments } from 'components/Account/PreviousPayments';
import { Button, Spacer } from '@raylo-tech/raylopay-ui';
import { IPaymentOverviewProps } from './PaymentOverview.types';
import { checkInArrears } from '../../../helpers/subscriptions/checkInArrears';
import { px2Rem } from '../../../utils/px2Rem';

export const PaymentOverview = ({
  subscription,
  dataTestId,
}: IPaymentOverviewProps) => {
  if (subscription) {
    return (
      <PaymentOverviewOuter data-testid={dataTestId}>
        <PaymentOverviewInner>
          {subscription.nextPaymentScheduleEvent?.payable &&
            !checkInArrears(subscription) && (
              <div style={{ margin: 'auto', maxWidth: px2Rem(380) }}>
                <Button
                  dataTestId="make-payment-button"
                  onClick={() =>
                    window.location.assign(
                      `/account/payment/${subscription.id}`,
                    )
                  }
                  small
                >
                  Make a Payment
                </Button>
                <Spacer height={24} />
              </div>
            )}
          <OverduePayment subscription={subscription} />
          <NextPayment
            nextPaymentScheduleEvent={subscription.nextPaymentScheduleEvent}
          />
          <PreviousPayments transactions={subscription.transactions} />
        </PaymentOverviewInner>
      </PaymentOverviewOuter>
    );
  } else {
    return null;
  }
};

const PaymentOverviewOuter = styled.section`
  background-color: #fff;
`;

const PaymentOverviewInner = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: ${px2Rem(24)};
`;
