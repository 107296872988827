import { useMedia } from 'use-media';
import { Link, useHistory } from 'react-router-dom';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { primaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { track } from 'integrations/segment/events';
import { IDeviceCard } from './types';
import { StyledDeviceCard, UpgradeIconWrapper } from './styles';
import Button from 'elements/Button';
import { navigateToReturnUrl } from 'utils/subscription/navigateToReturnUrl';
import { ButtonTypes } from 'elements/Button/types';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import pluralize from 'pluralize';
import ImgixImage from 'elements/ImgixImage';
import { isTV } from 'screens/Account/utils/parseOrder.helper';
import { tvsHelpLinkURL } from 'utils/constants';

export const DeviceCard = ({ dataTestId, order, isInArrears }: IDeviceCard) => {
  const {
    model,
    color,
    storage,
    payMonthly,
    image,
    orderId,
    status,
    subscription,
    orderItem,
  } = order;

  const url = image?.url;

  const productImage = `${url}?trim=color&auto=format&fm=webp`;

  const { setDomainCookie, redirectToProductsApp } = useSharedCustomer();
  const history = useHistory();
  const isSmallMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.smallMobile });
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });
  const lineHeight = isMobile ? 22 : 30;

  const upgradeAvailable = status === YourTechStatus.upgradeAvailable;
  const overPayable = subscription?.nextPaymentScheduleEvent?.payable;

  const cancelled = [
    YourTechStatus.orderCancelled,
    YourTechStatus.leaseCancelled,
  ].includes(status);

  const handleUpgrade = () => {
    history.push(`/account/${orderId}`);
  };

  const handlePaymentClick = () => {
    track('My Account Pay Now Clicked', {
      screen: 'account overview',
      where: 'account overview product tile',
      when: "on clicking the 'Pay Now' button on the product tile",
      url: window.location.href,
    });
    history.push(`/account/payment/${subscription.id}`);
  };

  const handleUpgradeClick = () => {
    track('My Account Upgrade Now Clicked', {
      screen: 'account overview',
      where: 'account overview product tile',
      when: "on clicking the 'Upgrade Now' button on the product tile",
      url: window.location.href,
    });

    setDomainCookie('checkoutContext', CheckoutMode.UPGRADE);
    setDomainCookie('subscriptionId', subscription.id);
    redirectToProductsApp(
      pluralize.plural(
        orderItem.variant.product.category.displayName.toLowerCase(),
      ),
    );
  };

  const isStatusBold = (): boolean => {
    switch (status) {
      case YourTechStatus.arrears:
      case YourTechStatus.upgradeAvailable:
        return true;
      default:
        return false;
    }
  };

  const statusColour = (): string => {
    switch (status) {
      case YourTechStatus.arrears:
        return uiStateColors.error;
      case YourTechStatus.upgradeAvailable:
        return uiStateColors.success;
      default:
        return primaryColors.c01;
    }
  };

  const statusText = (): string => {
    switch (status) {
      case YourTechStatus.arrears:
        return `${subscription?.arrearsAmount?.formattedValue} overdue`;
      default:
        return status;
    }
  };

  const renderButton = () => {
    const ButtonContainer = ({
      dataTestId,
      text,
      onClick,
    }: {
      dataTestId: string;
      text: string;
      onClick: () => void;
    }) => {
      return (
        <Container
          padding="0"
          width={isMobile ? '100%' : px2Rem(193)}
          dataTestId={dataTestId}
        >
          <Spacer height={4} />
          <Button
            dataTestId={`${dataTestId}--button`}
            buttonColor={ButtonTypes.b01}
            size="small"
            text={text}
            onClick={onClick}
          />
          <Spacer height={4} />
        </Container>
      );
    };

    switch (status) {
      case YourTechStatus.arrears:
        return (
          <ButtonContainer
            dataTestId="pay-now-button"
            text="pay now"
            onClick={handlePaymentClick}
          />
        );

      case YourTechStatus.upgradeAvailable:
        if (isInArrears) return null;

        return (
          <ButtonContainer
            dataTestId="upgrade-now-button"
            text="upgrade now"
            onClick={handleUpgradeClick}
          />
        );

      case YourTechStatus.returnDue:
        return isTV(orderItem.variant.product.category) ? (
          <>
            See our <TextLink href={tvsHelpLinkURL}>help article</TextLink> for
            more info on returning TVs.
          </>
        ) : (
          <ButtonContainer
            dataTestId="start-return-button"
            text="start return"
            onClick={() => {
              window.location.assign(
                `/account/start-return/${subscription.id}`,
              );
            }}
          />
        );

      case YourTechStatus.returnStarted:
        return isTV(orderItem.variant.product.category) ? (
          <>
            See our <TextLink href={tvsHelpLinkURL}>help article</TextLink> for
            more info on returning TVs.
          </>
        ) : (
          <ButtonContainer
            dataTestId="view-return-label-button"
            text="view return label"
            onClick={() => navigateToReturnUrl(subscription)}
          />
        );

      default:
        if (overPayable) {
          return (
            <ButtonContainer
              dataTestId="overpayment-button"
              text="make a payment"
              onClick={handlePaymentClick}
            />
          );
        }

        return null;
    }
  };

  const showCTA =
    overPayable ||
    [
      YourTechStatus.arrears,
      YourTechStatus.upgradeAvailable,
      YourTechStatus.returnDue,
      YourTechStatus.returnStarted,
    ].includes(status);

  const DeviceCardComponent = () => (
    <div>
      <Container
        flexRow
        backgroundColor={primaryColors.w01}
        dataTestId={dataTestId}
        boxShadow
        height={isMobile ? 'auto' : px2Rem(300)}
        width={!isMobile ? '598px' : 'initial'}
        padding={isMobile ? `${px2Rem(16)}` : `${px2Rem(32)}`}
        styles={{
          position: 'relative',
        }}
      >
        <Container
          center
          width={isMobile ? '30%' : '40%'}
          styles={{ position: 'relative', flexShrink: 0 }}
          noPadding={isMobile}
        >
          <ImgixImage
            alt={model}
            src={productImage}
            width={182}
            height={256}
            imageWidths={[98, 100, 120, 140, 160, 182]}
            sizes={[
              `(max-width: ${LEGACY_BREAKPOINTS.smallMobile}px) calc((100vw - 64px) * 0.3)`,
              `(max-width: ${LEGACY_BREAKPOINTS.mobile}px) calc((100vw - 96px) * 0.3)`,
              '182px',
            ].join(', ')}
          />
          {upgradeAvailable && !isInArrears && (
            <UpgradeIconWrapper onClick={handleUpgrade}>
              <Icon
                name="RoundArrowBack"
                color="white"
                size={isSmallMobile ? px2Rem(16) : px2Rem(22)}
              />
            </UpgradeIconWrapper>
          )}
        </Container>
        <Container
          padding={`0 ${px2Rem(16)}`}
          width={isMobile ? '70%' : '60%'}
          styles={{
            marginLeft: px2Rem(16),
          }}
          justifyCenter
        >
          <Container padding="0">
            <Copy
              color={primaryColors.c01}
              bold
              lineHeight={lineHeight}
              fontSize={isSmallMobile ? 14 : 16}
            >
              {model}
            </Copy>
          </Container>
          <Copy
            color={primaryColors.c01}
            lineHeight={lineHeight}
            fontSize={isSmallMobile ? 14 : 16}
          >
            {color}, {storage}
          </Copy>
          <Spacer height={12} />

          <Copy
            color={primaryColors.c01}
            bold
            lineHeight={lineHeight}
            fontSize={isSmallMobile ? 14 : 16}
          >
            Status
          </Copy>
          <Container padding="0" flexRow alignCenter>
            {status === YourTechStatus.arrears ? (
              <Icon
                name="RedAlert"
                styles={`
                      display: inline-block;
                      margin-right: ${px2Rem(8)};
                      vertical-align: middle;
                      `}
              />
            ) : null}
            <Copy
              color={statusColour()}
              lineHeight={lineHeight}
              bold={isStatusBold()}
              fontSize={isSmallMobile ? 14 : 16}
            >
              {statusText()}
            </Copy>
          </Container>
          {showCTA && renderButton()}
          {!isMobile && (
            <>
              <Spacer height={12} />
              <Copy color={primaryColors.c01} bold lineHeight={lineHeight}>
                Monthly Payments
              </Copy>
              <Copy
                color={primaryColors.c01}
                lineHeight={lineHeight}
                dataTestId="monthly-payments"
              >
                {cancelled ? 'None' : `£${formatDisplayPrice(payMonthly)}`}
              </Copy>
            </>
          )}
        </Container>
        {!isMobile && !!orderId && (
          <div
            style={{
              position: 'absolute',
              bottom: px2Rem(36),
              right: px2Rem(32),
            }}
          >
            <TextLink link={`/account/${orderId}`}>
              <span data-testid="viewUpgrades">View details</span>
            </TextLink>
          </div>
        )}
      </Container>
    </div>
  );
  if (isMobile) {
    return (
      <StyledDeviceCard data-testid="deviceCard">
        {orderId ? (
          <Link
            to={`/account/${orderId}`}
            onClick={(event) => {
              if ((event.target as HTMLElement).tagName === 'BUTTON') {
                event.preventDefault();
              }
            }}
            replace
          >
            <DeviceCardComponent />
          </Link>
        ) : (
          <DeviceCardComponent />
        )}
      </StyledDeviceCard>
    );
  }
  return <DeviceCardComponent />;
};
