import { useEffect, useMemo, useState } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { merge, pick } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { BankDetails } from 'components/BankDetailsContainer/types';

interface IUseStepBank {
  onSuccess: () => void;
}

const useStepBank = ({ onSuccess }: IUseStepBank) => {
  const [formErrors, setFormErrors] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmDetails, setConfirmDetails] = useState(false);
  const [values, setValues] = useState<BankDetails | undefined>();
  const [directDebitConfirm, setDirectDebitConfirm] = useState<boolean>(false);
  const [confirmNewDetails, setConfirmNewDetails] = useState<boolean>(false);
  const [directDebitModalOpen, setDirectDebitModalOpen] =
    useState<boolean>(false);
  const [directDebitMandateAuthorised, setDirectDebitMandateAuthorised] =
    useState<boolean>(false);

  const { checkoutToken, customerDetails, checkoutMode } = useCheckoutContext();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepBank.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [updateMutation] = useMutation(CheckoutSteps.stepBank.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        onSuccess();
      }
    },
  });

  const initialValues: BankDetails | undefined = useMemo(() => {
    const accountInfo = [
      'bankAccountName',
      checkout?.paymentInfo?.bankAccountType ? 'bankAccountType' : false,
      'bankAccountNumber',
      'bankBranchIdentifier',
    ].filter(Boolean) as string[];

    if (!loading) {
      if (checkout?.paymentInfo?.bankAccountName) {
        return merge({
          ...pick(
            checkout.paymentInfo,
            ...accountInfo,
            'directDebitMandateAuthorised',
          ),
        });
      } else if (customerDetails) {
        return customerDetails;
      } else if (checkoutMode === CheckoutMode.UPGRADE) {
        return merge({
          ...pick(
            checkout.items[0].originSubscription.paymentInfo,
            ...accountInfo,
            'bankBranchIdentifier',
          ),
        });
      }
    }
  }, [loading, checkout, checkoutMode]);

  const monthlyPrice = useMemo(
    () =>
      checkout?.items[0]?.costSummary.recurring.totalAmount.valueAfterTax
        .value ?? 0,
    [checkout],
  );

  useEffect(() => {
    if (initialValues) {
      setConfirmDetails(true);
    }
  }, [initialValues]);

  useEffect(() => {
    if (formSubmitted) {
      if (values?.bankAccountNumber?.includes('*')) {
        onSuccess();
      } else {
        updateMutation({
          variables: {
            ...values,
            directDebitMandateAuthorised,
            checkoutToken,
          },
        });
      }
    }
  }, [formSubmitted]);

  return {
    checkout,
    onSuccess,
    initialValues,
    loading,
    formSubmitted,
    confirmDetails,
    directDebitConfirm,
    values,
    directDebitMandateAuthorised,
    directDebitModalOpen,
    monthlyPrice,
    formErrors,
    confirmNewDetails,
    setValues,
    setFormSubmitted,
    setFormErrors,
    setDirectDebitConfirm,
    setConfirmDetails,
    setDirectDebitMandateAuthorised,
    setDirectDebitModalOpen,
    setConfirmNewDetails,
  };
};

const [StepBankProvider, useStepBankContext] = constate(useStepBank);
export { StepBankProvider, useStepBankContext };
