import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { Button } from 'components/common/Button';
import { FadeIn } from 'components/animations/Transitions';

import { Helmet } from 'react-helmet';
import { useUiContext } from 'utils/useUiContext';
import Container from 'elements/Container';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import Spacer from 'elements/Spacer';
import Header from 'elements/Header';
import { primaryColors } from 'styles/variables/colors';
export const InvalidChoice = ({ buttonStyle = 'primaryBlue', onClick }) => {
  const { lightMode } = useUiContext();
  useEffect(() => {
    activateOptimize();
  });

  if (lightMode) {
    return (
      <Container backgroundColor={primaryColors.w01}>
        <Header level={2} color={primaryColors.c01}>
          We’re sorry, but that's not a valid choice
        </Header>
        <Spacer height={40} />
        <DoubleButtonFullWidth
          singleButton
          topButtonText="Pick Another"
          topButtonOnClick={onClick}
        />
      </Container>
    );
  }
  return (
    <L.ErrorContainer>
      <Helmet>
        <title>Sorry - Raylo</title>
      </Helmet>
      <FadeIn>
        <T.RayloBodyHeading>
          <strong>We’re sorry</strong>, but that's not a valid choice
        </T.RayloBodyHeading>
      </FadeIn>

      <FadeIn>
        <Button
          buttonStyle={buttonStyle}
          onClick={onClick}
          style={{ height: '44px' }}
        >
          Try again
        </Button>
      </FadeIn>
    </L.ErrorContainer>
  );
};
