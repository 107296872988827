import { BankDetails } from 'components/BankDetailsContainer/types';
import gql from 'graphql-tag';
import { subscriptionNextPaymentScheduleEvent } from 'graphql/fragments/subscriptionNextPaymentScheduleEvent.fragment';

export enum SubscriptionCancellationCategory {
  COOLING_OFF = 'COOLING_OFF',
  WITHIN_LEASE = 'WITHIN_LEASE',
  END_OF_INITIAL_LEASE_TERM = 'END_OF_INITIAL_LEASE_TERM',
}

export interface IGetCustomerDeviceOrder {
  id: string;
  knownDeliveryDates: string;
  directCancellationSupported: boolean;
  state: string;
  merchant: {
    id: string;
    displayName: string;
  };
  merchantReference: string;
  items: IGetCustomerDeviceOrderItem[];
}

export interface IGetCustomerDeviceOrderItem {
  id: string;
  timeline: {
    happenedAt: string;
    message: string;
    stateDescription: string;
  }[];
  deliveryEstimatedBetween: {
    min: string;
    max: string;
  };
  costSummary: {
    includesInsurance: boolean;
    currencyCode: string;
    initial: {
      totalAmount: {
        valueAfterTax: {
          value: number;
        };
      };
    };
    recurring: {
      totalAmount: {
        valueAfterTax: {
          value: number;
        };
      };
    };
  };
  fulfillmentItem: IGetCustomerDeviceOrderFulfillmentItem | null;
  subscription: IGetCustomerDeviceOrderSubscription | null;
  variant: {
    id: string;
    product: {
      id: string;
      displayName: string;
      preOrder: boolean;
      category: {
        id: string;
        displayName: string;
      };
    };
    images: {
      id: string;
      tag: string;
      url: string;
    }[];
  };
}

export interface IGetCustomerDeviceOrderFulfillmentItem {
  id: string;
  deliveredAt: string;
  dispatchedAt: string;
  expectedDeliveryAt: string;
  trackingUrl: string;
  trackingReference: string;
  courierName: string;
}
export interface IGetCustomerDeviceOrderSubscription {
  id: string;
  state: string;
  cancellationPeriodCategory: SubscriptionCancellationCategory;
  createdAt: string;
  upgrade: {
    canStartAssetReturn: boolean;
    dueOn: string;
    eligible: boolean;
    checkout: {
      id: string;
      token: string;
      state: string;
    };
  };
  activeAsset: {
    id: string;
    blockState: string;
  };
  assetReturns: {
    id: string;
    customerCollectionUrl: string | null;
    state: string;
  }[];
  arrearsAmount: {
    formattedValue: string;
    valueInSubunit: number;
  };
  startedOn: string;
  lastScheduledPaymentOn: string;
  nextPaymentScheduleEvent: {
    amount: {
      formattedValue: string;
    };
    paymentOn: string;
    payable: boolean;
  };
  paymentInfo: BankDetails;
  paymentSchedule: {
    amount: {
      formattedValue: string;
    };
    paymentOn: string;
  };
  transactions: {
    amountIncludingTax: {
      formattedValue: string;
      value: number;
    };
    description: string;
    happenedAt: string;
  }[];
}

export const GET_CUSTOMER_DEVICE = gql`
  ${subscriptionNextPaymentScheduleEvent}
  query GetCustomerDevice {
    customer {
      id
      firstName
      address {
        id
        line1
        line2
        line3
        postcode
        region
        city
        country {
          id
          name
        }
      }
      orders {
        id
        knownDeliveryDates
        directCancellationSupported
        state
        merchant {
          id
          displayName
        }
        merchantReference
        items {
          id
          timeline {
            happenedAt
            message
            stateDescription
          }
          deliveryEstimatedBetween {
            min
            max
          }
          costSummary {
            includesInsurance
            currencyCode
            initial {
              totalAmount {
                valueAfterTax {
                  value
                }
              }
            }
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                }
              }
            }
          }
          fulfillmentItem {
            id
            deliveredAt
            dispatchedAt
            expectedDeliveryAt
            trackingUrl
            trackingReference
            courierName
          }
          subscription {
            id
            state
            cancellationPeriodCategory
            createdAt
            upgrade {
              canStartAssetReturn
              dueOn
              eligible
              checkout {
                id
                token
                state
              }
            }
            activeAsset {
              id
              blockState
            }
            assetReturns {
              id
              customerCollectionUrl
              state
            }
            arrearsAmount {
              formattedValue
              valueInSubunit
            }
            startedOn
            lastScheduledPaymentOn
            ...SubscriptionPaymentScheduleEvent
            paymentInfo {
              bankAccountName
              bankAccountNumber
              bankBranchIdentifier
            }
            paymentSchedule {
              amount {
                formattedValue
              }
              paymentOn
            }
            transactions {
              amountIncludingTax {
                formattedValue
                value
              }
              description
              happenedAt
            }
          }
          variant {
            id
            product {
              id
              displayName
              preOrder
              category {
                id
                displayName
              }
            }
            images {
              id
              tag
              url
            }
          }
        }
      }
    }
  }
`;
