import React from 'react';
import {
  StyledRadioSelectBorderBox,
  StyledRadioSelectBorderBoxBody,
} from './RadioSelectBorderBox.styles';
import { RadioSelectBorderBoxProps } from './RadioSelectBorderBox.types';
import RadioSelect from '../RadioSelect';
import Typography from '../../foundations/Typography';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

export const RadioSelectBorderBox = ({
  active,
  onClick,
  label,
  header,
  body,
  dataTestId,
}: RadioSelectBorderBoxProps) => {
  return (
    <StyledRadioSelectBorderBox
      data-testid={dataTestId}
      $active={active}
      $hasLabel={!!label}
      onClick={onClick}
    >
      {!!label && (
        <Typography
          variant="body2"
          medium
          color="white"
          style={{
            margin: 0,
            textTransform: 'uppercase',
            position: 'absolute',
            bottom: '100%',
            left: 0,
            backgroundColor: palette.blue[500],
            padding: `${px2Rem(5)} ${px2Rem(8)}`,
            letterSpacing: '0.05em',
            borderTopLeftRadius: px2Rem(4),
            borderTopRightRadius: px2Rem(4),
          }}
          data-testid={dataTestId ? `${dataTestId}--label` : undefined}
        >
          {label}
        </Typography>
      )}
      <RadioSelect dataTestId={dataTestId ? `${dataTestId}--select` : undefined} active={active}>
        {header}
      </RadioSelect>
      <StyledRadioSelectBorderBoxBody>{body}</StyledRadioSelectBorderBoxBody>
    </StyledRadioSelectBorderBox>
  );
};
