import rayloLogo from 'public/raylo-logo.svg';
import { DownsellContainerWrapper } from './styles';
import { useCheckoutDecisionContext } from 'screens/Checkout/components/CheckoutDecision/hooks/useCheckoutDecisionContext';
import { Spacer } from 'elements';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import {
  NumberStepContainer,
  SectionMessageWithIcon,
  TextAccordion,
  Container,
  Copy,
} from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';
import Divider from 'elements/Divider';
import { productCardDownsellData } from './constants';
import ProductCard from 'screens/Checkout/components/ProductCard';
import { Fragment, useEffect, useState } from 'react';
import { track } from 'integrations/segment/events';
import a from 'indefinite';

const DownsellContainer = () => {
  const { productDisplayName, checkoutId } = useCheckoutDecisionContext();
  const [trackingSent, setTrackingSent] = useState(false);

  useEffect(() => {
    if (checkoutId && !trackingSent) {
      track('Downsell Page Viewed', {
        checkoutId,
      });
      setTrackingSent(true);
    }
  }, [checkoutId]);

  return (
    <DownsellContainerWrapper data-testid="downsell-container">
      <header>
        <img src={rayloLogo} alt="Raylo Logo" />
      </header>
      <div className="downsell-content">
        <Copy
          fontSize={24}
          lineHeight={30}
          bold
          color={primaryColors.c01}
          dataTestId="downsell-header"
        >
          We&apos;d like to help you get {a(productDisplayName)}
        </Copy>
        <Spacer height={24} />
        <Copy fontSize={16} lineHeight={20} color={primaryColors.c01} bold>
          Based on the credit check we cannot offer you {a(productDisplayName)}{' '}
          today, however it can still be yours.
        </Copy>
        <Spacer height={24} />
        <SectionMessageWithIcon
          icon="RayloDesignInfo"
          content={
            <Copy fontSize={16} lineHeight={20}>
              <b>Make 6 payments on-time</b> for one of our featured devices and
              you’ll be pre-approved for any device you’d like, including an{' '}
              {productDisplayName}. All on flexible monthly rolling, cancel
              anytime plan.
            </Copy>
          }
        />
        <Spacer height={48} />
        <TextAccordion
          primaryColour={primaryColors.b01}
          boldHeaders
          data={[
            {
              title: 'How does it work?',
              body: (
                <>
                  <Container flexRow>
                    <NumberStepContainer step={1} small />
                    <div style={{ paddingLeft: px2Rem(16) }}>
                      <Copy
                        fontSize={14}
                        lineHeight={20}
                        color={primaryColors.c01}
                      >
                        Choose a device from the list below
                      </Copy>
                    </div>
                  </Container>
                  <Spacer height={16} />
                  <Container flexRow>
                    <NumberStepContainer step={2} small dataTestId="step-2" />
                    <div style={{ paddingLeft: px2Rem(16) }}>
                      <Copy
                        fontSize={14}
                        lineHeight={20}
                        color={primaryColors.c01}
                      >
                        <b>Make 6 monthly payments on time</b> with the option
                        to cancel and return at any time
                      </Copy>
                    </div>
                  </Container>
                  <Spacer height={16} />
                  <Container flexRow>
                    <NumberStepContainer step={3} small dataTestId="step-3" />
                    <div style={{ paddingLeft: px2Rem(16) }}>
                      <Copy
                        fontSize={14}
                        lineHeight={20}
                        color={primaryColors.c01}
                      >
                        After your sixth payment you can upgrade to the device
                        of your choice
                      </Copy>
                    </div>
                  </Container>
                  <Spacer height={16} />
                  <Container flexRow>
                    <NumberStepContainer step={4} small dataTestId="step-4" />
                    <div style={{ paddingLeft: px2Rem(16) }}>
                      <Copy
                        fontSize={14}
                        lineHeight={20}
                        color={primaryColors.c01}
                      >
                        After you receive your new device return your old device
                      </Copy>
                    </div>
                  </Container>
                  <Spacer height={16} />
                </>
              ),
            },
          ]}
        />
        <Divider
          width="100%"
          color={secondaryColors.c04}
          styles={`margin: ${px2Rem(16)} 0`}
        />
        <Spacer height={24} />
        {productCardDownsellData.map(
          ({ img, displayName, condition, price }) => (
            <Fragment key={displayName}>
              <ProductCard
                img={img}
                displayName={displayName}
                condition={condition}
                price={price}
              />
              <Spacer height={24} />
            </Fragment>
          ),
        )}
      </div>
    </DownsellContainerWrapper>
  );
};

export default DownsellContainer;
