import { FC, useEffect, useRef } from 'react';
import { useIMask } from 'react-imask';
import { Input } from '@raylo-tech/raylopay-ui';
import { IMoneyInputProps } from './MoneyInput.types';

export const MoneyInput: FC<IMoneyInputProps> = ({
  onAccept,
  initialValue,
  focusOnLoad,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current && focusOnLoad) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useIMask(
    { mask: Number, scale: 2, radix: '.', padFractionalZeros: true, min: 0 },
    { onAccept, ref: inputRef, defaultValue: initialValue },
  );

  function blurOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  }

  return (
    <Input
      onKeyDown={blurOnEnter}
      inputRef={inputRef}
      type="number"
      inputMode="decimal"
      {...props}
    />
  );
};
