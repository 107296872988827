import {
  Button,
  Header,
  SelectDropdown,
  Spacer,
  TextArea,
} from '@raylo-tech/raylopay-ui';
import { IReason } from '../../../hooks/useSubscriptionCancellationReasons';
import { IValue } from '@raylo-tech/raylopay-ui/build/cjs/components/SelectDropdown/SelectDropdown.types';
import { useState } from 'react';
import shuffle from 'utils/shuffle/shuffle';

interface IReasonsForCancellation {
  onNext: (params: { customerNotes: string; reason: string }) => void;
  onClose: () => void;
  reasons: IReason[];
  randomizationSeed: number;
}
export const ReasonsForCancellation = ({
  onNext,
  onClose,
  reasons,
  randomizationSeed,
}: IReasonsForCancellation) => {
  const [selectedReason, setSelectedReason] = useState<IValue | undefined>(
    undefined,
  );
  const [selectedSubreason, setSelectedSubreason] = useState<
    IValue | undefined
  >(undefined);
  const [subreasons, setSubreasons] = useState<string[] | undefined>(undefined);
  const [customerNotes, setCustomerNotes] = useState<string>('');

  const onSelectedReasonChange = (reason: IValue) => {
    setSelectedReason(reason);
    setSelectedSubreason(undefined);

    const subreasons = reasons.find(
      (r) => r.title === reason.value,
    )?.subreasons;
    if (subreasons && subreasons.length > 0) {
      setSubreasons(subreasons);
    } else {
      setSubreasons(undefined);
    }
  };

  const isNextDisabled =
    !selectedReason || (!!subreasons && !selectedSubreason?.value);

  const reason =
    (subreasons ? selectedSubreason?.value : selectedReason?.value) || '';

  return (
    <>
      <Header
        dataTestId="reasonsForCancellationHeader"
        fontSize={24}
        lineHeight={28}
      >
        Help us improve
      </Header>
      <Spacer height={24} />
      <SelectDropdown
        dataTestId="cancel-subscription-reason"
        isSearchable={false}
        options={mapReasonsToDropdown(reasons, randomizationSeed)}
        onChange={(reason) => onSelectedReasonChange(reason)}
        value={selectedReason}
        label="Why are you cancelling?"
      />
      {subreasons && (
        <>
          <Spacer height={24} />
          <SelectDropdown
            dataTestId="cancel-subscription-subreason"
            isSearchable={false}
            options={mapSubreasonsToDropdown(subreasons, randomizationSeed)}
            onChange={(subreason) => setSelectedSubreason(subreason)}
            value={selectedSubreason}
            label="Can you tell us more?"
          />
        </>
      )}
      <Spacer height={12} />{' '}
      {/* this is 24px minus the text input margin top (12px) */}
      <TextArea
        height={104}
        floatingLabel="What can we improve?"
        onChange={(notes) => setCustomerNotes(notes)}
      />
      <Spacer height={36} />{' '}
      {/* this is 24px plus the text input margin top (12px) */}
      <Button
        onClick={() => onNext({ customerNotes, reason })}
        dataTestId="form-primary-button"
        disabled={isNextDisabled}
      >
        Next
      </Button>
      <Spacer height={24} />
      <Button onClick={onClose} secondary dataTestId="form-secondary-button">
        Don&apos;t Cancel
      </Button>
    </>
  );
};

const mapReasonsToDropdown = (reasons: IReason[], seed: number): IValue[] => {
  return shuffle(
    reasons.map((reason) => ({
      label: reason.title,
      value: reason.title,
    })),
    seed,
  ) as IValue[];
};

const mapSubreasonsToDropdown = (
  subreasons: string[],
  seed: number,
): IValue[] => {
  return shuffle(
    subreasons.map((subreason) => ({
      label: subreason,
      value: subreason,
    })),
    seed,
  ) as IValue[];
};
