import { useState, useEffect } from 'react';
import store from 'store';
import styled from 'styled-components';
import _ from 'lodash-es';
import dayjs from 'dayjs';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

import iconUpgrade from 'public/icon--upgrade.svg';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import StatusBanner from 'components/StatusBanner';
import Spacer from 'elements/Spacer';
import useMedia from 'use-media';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import pluralize from 'pluralize';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const PhoneStatusUpgrade = ({ phone }) => {
  let dueOn, checkout, subscription;
  let eligible = false;

  if (phone.subscription) {
    subscription = phone.subscription;
    dueOn = subscription.upgrade.dueOn;
    eligible = subscription.upgrade.eligible;
    checkout = subscription.upgrade.checkout;
  }

  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  const { removeDomainCookie, setDomainCookie, redirectToProductsApp } =
    useSharedCustomer();
  const dueOnIsInFuture =
    dueOn &&
    dayjs(dueOn).isBefore(dayjs().add(30, 'd')) &&
    dayjs(dueOn).isAfter(dayjs());
  const dueOnIsInPast = dueOn && dayjs(dueOn).isBefore(dayjs());
  const hasActiveCheckout =
    checkout &&
    checkout.token &&
    checkout.state &&
    ['STARTED', 'ABANDONED'].includes(checkout.state);
  const isInArrears = checkInArrears(subscription);

  const showTimer = dueOnIsInFuture && !isInArrears;
  const showButton =
    dueOnIsInPast && (eligible || hasActiveCheckout) && !isInArrears;

  const calculateTimeLeft = () => {
    if (!dueOn) {
      return;
    }
    const difference = dayjs(dueOn).diff(dayjs(), 'milliseconds');
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (dueOnIsInFuture) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [timeLeft, dueOnIsInFuture]);

  const onClick = () => {
    store.remove('checkoutToken');
    removeDomainCookie('checkoutToken'); // why are we doing this?
    setDomainCookie('checkoutContext', CheckoutMode.UPGRADE);
    setDomainCookie('subscriptionId', subscription.id);
    redirectToProductsApp(
      pluralize.plural(
        phone?.orderItem.variant.product.category.displayName.toLowerCase(),
      ),
    );
  };

  if (showButton) {
    return (
      <StatusBanner
        buttonText={checkout ? 'Resume upgrade' : 'View upgrades'}
        buttonOnClick={onClick}
        showButton={showButton}
        flexRow={!isMobile}
      >
        <>
          {isMobile ? (
            <>
              <div
                style={{
                  marginLeft: px2Rem(12),
                  cursor: 'pointer',
                }}
                onClick={() => onClick()}
              >
                <img
                  src={iconUpgrade}
                  className="icon"
                  alt="Upgrade icon"
                  style={{
                    width: px2Rem(36),
                    height: px2Rem(36),
                  }}
                />
              </div>
              <h2>It's good news. It’s time for your FREE upgrade!</h2>
            </>
          ) : (
            <>
              <h2>It's good news. It’s time for your FREE upgrade!</h2>
              <div
                style={{
                  marginLeft: px2Rem(12),
                  cursor: 'pointer',
                }}
                onClick={() => onClick()}
              >
                <img src={iconUpgrade} className="icon" alt="Upgrade icon" />
              </div>
            </>
          )}
        </>
      </StatusBanner>
    );
  } else if (showTimer) {
    return (
      <StatusBanner
        buttonText={checkout ? 'Resume upgrade' : 'View upgrades'}
        buttonOnClick={onClick}
      >
        <>
          <h2>
            Not long now. You'll soon be eligible for a free upgrade on your{' '}
            {phone.model}.
          </h2>
          <Spacer height={24} />
          <UpgradeTimers>
            <UpgradeTimer>
              {timeLeft.days}
              <small>Days</small>
            </UpgradeTimer>
            <UpgradeTimer>
              {timeLeft.hours}
              <small>Hours</small>
            </UpgradeTimer>
            <UpgradeTimer>
              {timeLeft.minutes}
              <small>Mins</small>
            </UpgradeTimer>
            <UpgradeTimer>
              {timeLeft.seconds}
              <small>Secs</small>
            </UpgradeTimer>
          </UpgradeTimers>
        </>
      </StatusBanner>
    );
  } else {
    return null;
  }
};

const UpgradeOuterContainer = styled.div`
  background-color: ${primaryColors.b01};
  padding: 75px 0;

  @media (max-width: 540px) {
    padding: 40px 0;
  }
`;

const UpgradeInnerContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`;

const UpgradePhoneImage = styled.div`
  position: relative;
`;

const UpgradePhoneIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
}`;

const UpgradeInner = styled.div`
  width: 100%;
  max-width: 595px;
  display: flex;
  justify-contents: flex-start;
  align-items: flex-start;
`;

const UpgradeDetails = styled.div`
  margin-left: 20px;
`;

const UpgradeHeader = styled.div`
  margin-bottom: 20px;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  color: white;
  font-size: 20px;
  line-height: 39px;
`;

const UpgradeTimers = styled.div`
  display: inline-block;
  border: 1px solid rgba(255, 206, 199, 0.43);
  padding: 20px 10px;
`;

const UpgradeTimer = styled.div`
  display: inline-block;
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: ${px2Rem(16)};
  padding: 0 15px;
  color: #ffffff;
  letter-spacing: -0.23px;
  small {
    margin-top: 10px;
    display: block;
    font-size: ${px2Rem(16)};
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    color: ${primaryColors.p01};
    letter-spacing: -0.16px;
  }
`;
