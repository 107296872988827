import { Tooltip } from 'react-tooltip';
import useMedia from 'use-media';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { Input, InputWrapper, StyledTextInput, IconWrapper } from './styles';
import { ITextInput } from './types';
import { forwardRef } from 'react';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

/**
 * @deprecated This component has been replaced with the `<Input />` component in `@raylo-tech/raylopay-ui`.
 * Note: some features maybe missing such as the tooltip and will need to be re-implemented.
 */

export const TextInput = forwardRef(
  (
    {
      dataTestId,
      id,
      labelText,
      placeHolderText,
      error,
      success,
      onBlur,
      onChange,
      onClick,
      onFocus,
      value,
      height,
      maxLength,
      tooltip,
      fieldName,
      pattern,
      type,
      displayOptionalText,
    }: ITextInput,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

    return (
      <StyledTextInput
        data-testid={dataTestId}
        error={error}
        height={height}
        isMobile={isMobile}
      >
        <Container noPadding flexRow>
          <Container noPadding width="90%">
            {labelText && (
              <Copy bold>
                {labelText}
                {displayOptionalText && (
                  <span
                    style={{
                      fontWeight: CSS_FONT_WEIGHT.vars.regular,
                      fontSize: px2Rem(14),
                      color: secondaryColors.c02,
                      lineHeight: px2Rem(18),
                      paddingLeft: px2Rem(12),
                    }}
                  >
                    Optional
                  </span>
                )}
              </Copy>
            )}
          </Container>
          {!!tooltip && (
            <Container noPadding alignRight width="10%">
              <div data-tooltip-id="registerTip" data-tooltip-content={tooltip}>
                <Icon
                  name="InfoCircle"
                  color={secondaryColors.c03}
                  size="20px"
                />
              </div>
              <Tooltip
                id="registerTip"
                place={isMobile ? 'top-end' : 'top'}
                style={{
                  backgroundColor: secondaryColors.p04,
                  color: primaryColors.c01,
                  maxWidth: px2Rem(240),
                  cursor: 'pointer',
                  padding: px2Rem(16),
                  zIndex: 1000,
                  fontSize: px2Rem(14),
                }}
              />
            </Container>
          )}
        </Container>
        <Spacer height={tooltip ? 8 : 12} />
        <InputWrapper>
          <Input
            ref={ref}
            data-testid={`${dataTestId}-input`}
            id={id}
            autoComplete={type}
            min={type === 'number' ? 0 : undefined}
            placeholder={placeHolderText}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            inputMode={type === 'number' ? 'numeric' : undefined}
            value={value}
            maxLength={maxLength}
            name={fieldName}
            pattern={pattern}
            type={type}
          />
          <IconWrapper>
            {error && !success && (
              <Icon
                name="AlertCircle"
                dataTestId="input-error-icon"
                size={px2Rem(16)}
                color={uiStateColors.error}
              />
            )}
            {success && !error && (
              <Icon
                name="CheckCircleFill"
                dataTestId="input-success-icon"
                size={px2Rem(16)}
                color={uiStateColors.success}
              />
            )}
          </IconWrapper>
        </InputWrapper>
        <Spacer height={4} />
        {error && (
          <Copy fontSize={14} color={uiStateColors.error}>
            {error}
          </Copy>
        )}
      </StyledTextInput>
    );
  },
);

TextInput.displayName = 'TextInput';
