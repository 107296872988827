// Environment variables
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

// Growthbook Feature Flags
export const API_MAINTENANCE_MODE = 'api-maintenance-mode';
export const SHOW_DOWNSELL = 'show-downsell';
export const SHOW_UPDATED_CHECKOUT_STEPS = 'show-updated-checkout-steps';

export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri:
    process.env.REACT_APP_DEPLOY_CONTEXT === 'deployPreview'
      ? `https://deploy-preview-${process.env.REACT_APP_REVIEW_ID}.app.early-access.raylo.com/login/callback`
      : process.env.REACT_APP_AUTH0_REDIRECT_URI,
  realm: process.env.REACT_APP_AUTH0_REALM,
  stateToken: process.env.REACT_APP_AUTH0_STATE_TOKEN,
};

export const OPEN_BANKING_CONFIG = {
  callbackUri: process.env.REACT_APP_CREDIT_OPEN_BANKING_CALLBACK_URI,
};

export const tvsHelpLinkURL =
  'https://help.raylo.com/en/articles/9740048-your-guide-to-leasing-a-tv-with-raylo';
