import gql from 'graphql-tag';
import { subscriptionNextPaymentScheduleEvent } from 'graphql/fragments/subscriptionNextPaymentScheduleEvent.fragment';

export const CUSTOMER_ORDERS = gql`
  ${subscriptionNextPaymentScheduleEvent}
  query CustomerOrdersQuery {
    customer {
      id
      orders {
        id
        items {
          id
          variant {
            id
            product {
              displayName
            }
          }
          subscription {
            id
            arrearsAmount {
              formattedValue
              valueInSubunit
            }
            startedOn
            lastScheduledPaymentOn
            ...SubscriptionPaymentScheduleEvent
            paymentInfo {
              id
              bankAccountName
              bankAccountNumber
              bankBranchIdentifier
            }
            transactions {
              amountIncludingTax {
                formattedValue
                value
              }
              description
              happenedAt
            }
          }
          fulfillmentItem {
            id
            dispatchedAt
          }
        }
      }
    }
  }
`;
