import { useEffect, useState } from 'react';
import constate from 'constate';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { OperationVariables, useMutation } from '@apollo/client';
import { MU_SUBMIT_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { useErrorNavigator } from 'utils/useErrorNavigator';
import { find } from 'lodash-es';

const useStepSign = () => {
  const [formErrors, setFormErrors] = useState<
    | null
    | {
        code?: string | undefined;
        field?: string | undefined;
        message: string;
      }[]
  >(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errorType, setErrorType] = useState<string | undefined>(undefined);
  const [tradeInExists, setTradeInExists] = useState<boolean>(false);
  const { checkoutToken } = useCheckoutContext();

  const errorNav = useErrorNavigator();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.step8.query, {
    fetchPolicy: 'network-only',
    variables: {
      token: checkoutToken,
    },
  });

  const [submitCheckoutMutation, { loading: submitCheckoutLoading }] =
    useMutation(MU_SUBMIT_CHECKOUT, {
      update: (
        proxy,
        {
          data: {
            submitCheckout: { errors },
          },
        },
      ) => {
        if (errors && errors.length > 0) {
          if (find(errors, { field: 'payment_info.initial_payment_method' })) {
            setErrorType('card');
          } else {
            errorNav('GraphQL submit validation error', { errors });
          }
        } else {
          setOrderPlaced(true);
        }
      },
    });

  const [updateMutation] = useMutation(CheckoutSteps.step8.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      },
    ) => {
      if (errors?.length > 0) {
        setFormErrors(errors);
        setOrderPlaced(false);
      } else {
        setFormErrors(null);
        submitCheckoutMutation({
          variables: {
            checkoutToken: checkout.token,
          },
        });
      }
    },
  });

  const submitCheckout = (
    termsAcceptedData: OperationVariables | undefined,
  ) => {
    const blackboxData = window?.IGLOO?.getBlackbox();
    updateMutation({
      variables: {
        ...termsAcceptedData,
        checkoutToken: checkout.token,
        deviceInfoFingerprint: blackboxData && blackboxData.blackbox,
      },
    });
  };

  const [hireAgreementDetails, setHireAgreementDetails] = useState({});

  useEffect(() => {
    if (checkout?.items) {
      const { hireAgreement } = checkout.items[0];

      setHireAgreementDetails({
        htmlHireAgreement: hireAgreement?.nobodySigned?.htmlContent,
      });
      setTradeInExists(!!checkout?.tradeIns?.[0]?.id);
    }
  }, [checkout]);

  return {
    hireAgreementDetails,
    checkout,
    loading,
    formSubmitted,
    formErrors,
    errorType,
    orderPlaced,
    tradeInExists,
    setErrorType,
    setFormSubmitted,
    submitCheckout,
    submitCheckoutLoading,
  };
};

const [StepSignProvider, useStepSignContext] = constate(useStepSign);
export { StepSignProvider, useStepSignContext };
