import { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Auth0Provider } from '@auth0/auth0-react';

import * as L from 'styles/layout';
import { Modal } from 'components/common/Modal';

import { GlobalUIStyles } from '@raylo-tech/raylopay-ui';

// ROUTING
import { Route, Router, Switch } from 'react-router-dom';

// GRAPHQL
import { apolloClient } from 'utils/graphql';
import { ApolloProvider } from '@apollo/client';

// GLOBAL STATE
import { StateProvider } from 'utils/StateProvider';
import { MerchantProvider } from 'utils/useMerchantContext';
import { history } from 'utils/history';
import { enableErrorTracking } from 'utils/errorTracking';
import { CheckoutRouter } from 'screens/Checkout/components/CheckoutRouter';
import { Go } from 'screens/Checkout/Go';
import { ProductSelector } from 'screens/Checkout/components/ProductSelector';
import MerchantCheckoutCreation from 'checkout/containers/Merchant/MerchantCheckoutCreation';
import Products from 'screens/Products';
import { ErrorRouter } from 'screens/Errors';
import { LoginRouter } from 'screens/Login/LoginRouter';
import { AccountRouter } from 'screens/Account';
import { CookiesProvider } from 'react-cookie';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { rayloDark } from 'styles/themes/rayloDark';
import { GlobalAppStyles } from 'styles/globalAppStyles';

import { setExperimentVariables } from 'utils/setExperimentVariables';
import { createRoot } from 'react-dom/client';
import { AUTH_CONFIG } from 'utils/constants';
import { AnalyticsProvider } from 'utils/AnalyticsProvider';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { initialiseGrowthBook } from 'utils/initialiseGrowthbook';
import { IntercomProvider } from 'components/Intercom/useIntercom';
import Logout from 'screens/Login/Logout';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

enableErrorTracking();

const domNode = document.getElementById('root');
const root = createRoot(domNode);

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname + `?return-to=${appState?.returnTo || '/account'}`,
  );
};

const providerConfig = {
  domain: AUTH_CONFIG.domain,
  clientId: AUTH_CONFIG.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: AUTH_CONFIG.redirectUri,
    ...(AUTH_CONFIG.audience ? { audience: AUTH_CONFIG.audience } : null),
  },
};

const growthbook = initialiseGrowthBook();

const interval = setInterval(function () {
  if (analytics?.user && growthbook) {
    growthbook.setAttributes({
      ...growthbook.context.attributes,
      id: analytics.user().anonymousId(),
      customerAnalyticsId: analytics.user().id(),
    });
    clearInterval(interval);
  }
}, 100);

root.render(
  <GrowthBookProvider growthbook={growthbook}>
    <ApolloProvider client={apolloClient}>
      <Auth0Provider {...providerConfig}>
        <AnalyticsProvider>
          <IntercomProvider>
            <StyleSheetManager shouldForwardProp={isPropValid}>
              <ThemeProvider theme={rayloDark}>
                <GlobalUIStyles />
                <GlobalAppStyles />
                <StateProvider>
                  <CookiesProvider>
                    <MerchantProvider>
                      <Suspense
                        fallback={
                          <L.MainWrapper>
                            <Modal visible text="Loading..." />
                          </L.MainWrapper>
                        }
                      >
                        <Router history={history}>
                          <Switch>
                            <SentryRoute
                              exact
                              path="/go/:checkoutToken"
                              component={() => <Go />}
                            />
                            <SentryRoute
                              exact
                              path="/pick/:variantId/:costSummaryId/:subscriptionId"
                              component={() => {
                                return <ProductSelector />;
                              }}
                            />
                            <SentryRoute
                              exact
                              path="/pick/:variantId/:costSummaryId"
                              component={() => {
                                setExperimentVariables(window?.location?.href);
                                return <ProductSelector />;
                              }}
                            />
                            <SentryRoute
                              exact
                              path="/merchant/:merchantId/create-checkout/:merchantCheckoutJWT"
                              component={() => {
                                return <MerchantCheckoutCreation />;
                              }}
                            />
                            <SentryRoute
                              path="/account"
                              component={() => <AccountRouter />}
                            />
                            <SentryRoute
                              path="/checkout"
                              component={() => <CheckoutRouter />}
                            />
                            <SentryRoute
                              path="/login"
                              component={() => <LoginRouter />}
                            />
                            <SentryRoute
                              path="/logout"
                              component={() => <Logout />}
                            />

                            <SentryRoute
                              path="/errors"
                              component={() => <ErrorRouter />}
                            />
                            <SentryRoute
                              path="/"
                              component={() => <Products />}
                            />
                          </Switch>
                        </Router>
                      </Suspense>
                    </MerchantProvider>
                  </CookiesProvider>
                </StateProvider>
              </ThemeProvider>
            </StyleSheetManager>
          </IntercomProvider>
        </AnalyticsProvider>
      </Auth0Provider>
    </ApolloProvider>
  </GrowthBookProvider>,
);
