import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { Button } from 'components/common/Button';
import { FadeIn } from 'components/animations/Transitions';
import { EndpointForm } from 'components/EndpointForm';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { FRONTEND_URL } from 'utils/constants';
import { useHistory } from 'react-router';

import { Helmet } from 'react-helmet';
export const NotReady = ({ buttonStyle = 'primaryBlue' }) => {
  const { checkoutToken } = useCheckoutContext();
  const history = useHistory();

  useEffect(() => {
    activateOptimize();
  });

  const onClick = () => {
    if (checkoutToken) {
      history.push(`/go/${checkoutToken}`);
    } else {
      window.location = FRONTEND_URL;
    }
  };

  return (
    <L.ErrorContainer>
      <Helmet>
        <title>Sorry - Raylo</title>
      </Helmet>
      <FadeIn>
        <T.RayloBodyHeading>
          <strong>We’re sorry</strong>, but something went wrong.
        </T.RayloBodyHeading>
      </FadeIn>
      <FadeIn>
        <Button
          buttonStyle="primaryBlue"
          onClick={onClick}
          style={{ height: '44px' }}
        >
          Try again
        </Button>
      </FadeIn>
      {process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED === 'true' ? (
        <EndpointForm />
      ) : null}
    </L.ErrorContainer>
  );
};
