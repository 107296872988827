import dayjs from 'dayjs';
import { RefObject } from 'react';
import {
  StyledEndOfLeaseOptionsBanner,
  StyledEndOfLeaseOptionsBannerContent,
  StyledOptionButton,
  StyledOptionCircle,
  StyledOptionsContainer,
} from './EndOfLeaseOptionsBanner.styles';
import BrowserBackArrow from 'components/BrowserBackArrow';
import { primaryColors } from 'styles/variables/colors';
import { Typography } from '@raylo-tech/raylopay-ui';
import { formatDate } from 'utils/formatDate';
import { px2Rem } from 'utils/px2Rem';
import { EndOfLeaseOptionsContent } from '../utils/endOfLeaseOptionsContent';

type IEndOfLeaseOptionsBannerContent = EndOfLeaseOptionsContent & {
  ref: RefObject<HTMLDivElement>;
};

type IEndOfLeaseOptionsBanner = {
  productDisplayName: string;
  contents: IEndOfLeaseOptionsBannerContent[];
  endOfLeaseDate?: string;
};

export function EndOfLeaseOptionsBanner({
  productDisplayName,
  contents,
  endOfLeaseDate,
}: IEndOfLeaseOptionsBanner) {
  const ReachedEndOfLease = dayjs(endOfLeaseDate).isBefore(dayjs());

  return (
    <StyledEndOfLeaseOptionsBanner>
      <BrowserBackArrow
        color={primaryColors.w01}
        text={productDisplayName}
        onClick={() => window.history.back()}
      />
      <StyledEndOfLeaseOptionsBannerContent>
        <Typography
          variant="h2"
          style={{
            color: primaryColors.w01,
            textAlign: 'center',
            marginBottom: px2Rem(12),
          }}
        >
          End-of-lease options
        </Typography>
        <Typography
          style={{
            color: primaryColors.w01,
            textAlign: 'center',
            fontSize: px2Rem(16),
            marginBottom: px2Rem(24),
          }}
        >
          {ReachedEndOfLease
            ? 'At any time you can'
            : `From ${formatDate(endOfLeaseDate)}, you can`}
        </Typography>
        <StyledOptionsContainer>
          {contents.map((content) => {
            return (
              <StyledOptionButton
                key={content.bannerTitle}
                onClick={() =>
                  content.ref?.current?.scrollIntoView({ behavior: 'smooth' })
                }
              >
                <StyledOptionCircle>
                  <content.icon
                    style={{ width: px2Rem(24), color: primaryColors.w01 }}
                  />
                </StyledOptionCircle>
                {content.bannerTitle}
              </StyledOptionButton>
            );
          })}
        </StyledOptionsContainer>
      </StyledEndOfLeaseOptionsBannerContent>
    </StyledEndOfLeaseOptionsBanner>
  );
}
