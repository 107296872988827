import { useEffect, useCallback } from 'react';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { useLoginAuthentication } from '../hooks/useLoginAuthentication';
import Layout from '../Layout';
import { getUserEmail, setUserEmail } from 'utils/users/userEmail';
import { useHistory } from 'react-router';
import { track } from 'integrations/segment/events';
import { captureException } from 'utils/errorTracking';

type ErrorState = {
  error: {
    message: string;
  };
};

const Error = () => {
  const { auth0Logout } = useLoginAuthentication();
  const history = useHistory();

  const loginErrorState = history.location.state as ErrorState;
  const loginError = loginErrorState?.error;
  const userEmail = getUserEmail() || 'supplied';

  useEffect(() => {
    track('Login Event', {
      page: 'Error',
      event: 'Page viewed',
      error: loginErrorState?.error,
    });
  }, [loginErrorState]);

  let loginErrorCopy = '';
  let doCaptureException = false;

  switch (loginErrorState?.error?.message) {
    case 'account-not-found':
      loginErrorCopy =
        'We cannot find a Raylo account with the email you provided. Please check and try again. If you have recently completed your checkout, please wait for your confirmation email before logging in.';
      break;
    case 'customer-not-found':
      loginErrorCopy = `We can't find a Raylo account with the email ${userEmail}. Please check and try again.`;
      break;
    default:
      loginErrorCopy =
        'Apologies, we could not log you in. We have tracked this issue and will investigate. Please try again later.';
      doCaptureException = true;
  }

  useEffect(() => {
    if (doCaptureException && loginError) {
      track('Login Event', {
        page: 'Error',
        event: 'Exception captured',
        error: loginError,
      });

      captureException(loginError);
    }
  }, [doCaptureException, loginError]);

  return (
    <Layout
      heading="Unable to login"
      copy={loginErrorCopy}
      buttonLabel="Login"
      buttonOnClick={auth0Logout}
    />
  );
};

const Login = () => {
  const { auth0Login, isLoading } = useLoginAuthentication();

  useEffect(() => {
    if (isLoading) return;

    track('Login Event', {
      page: 'Login',
      event: 'Page viewed',
    });

    auth0Login();
  }, [isLoading, auth0Login]);

  return <LoadingScreen darkMode />;
};

const Callback = () => {
  const { getAuth0Token, error, user, isAuthenticated, isLoading } =
    useLoginAuthentication();
  const history = useHistory();

  const pushToHistory = useCallback(
    (error: Error) => {
      track('Login Event', {
        page: 'Callback',
        event: 'User redirected to /error',
        error,
      });

      history.push('/login/error', { error });
      return;
    },
    [history],
  );

  useEffect(() => {
    if (isLoading) return;

    if (error) {
      track('Login Event', {
        page: 'Callback',
        event: 'Error received',
        error,
      });

      pushToHistory(error);
    }
    if (isAuthenticated && user) {
      if (user.email) {
        setUserEmail(user.email);
      }

      track('Login Event', {
        page: 'Callback',
        event: 'Token fetch attempted',
      });
      getAuth0Token();
    }
  }, [error, isAuthenticated, user, isLoading, pushToHistory, getAuth0Token]);

  return <LoadingScreen darkMode />;
};

const NotFound = () => {
  const { auth0Logout } = useLoginAuthentication();
  const userEmail = getUserEmail() || 'supplied';

  track('Login Event', {
    page: 'Not found',
    event: 'Page viewed',
  });

  return (
    <Layout
      heading="Unable to verify your account"
      copy={`We can't find a Raylo account with the email ${userEmail}. Please check and try again.`}
      buttonLabel="Login"
      buttonOnClick={auth0Logout}
    />
  );
};

export { Callback, Login, Error, NotFound };
