import React from 'react';
import { palette } from '../../../colors';
import Container from '../../../elements/Container';
import Copy from '../../../elements/Copy';
import Spacer from '../../../elements/Spacer';
import { px2Rem } from '../../../utils/px2Rem';
import Button from '../Button';
import { StyledLeaseTotalBar } from './LeaseTotalBar.styles';
import { ILeaseTotalBar } from './LeaseTotalBar.types';

export const LeaseTotalBar = ({
  dataTestId,
  formattedMonthlyPrice,
  formattedInitialAmount,
  onSubmit,
}: ILeaseTotalBar) => {
  return (
    <StyledLeaseTotalBar data-testid={dataTestId}>
      <Container boxShadowElevate centerHorizontal padding="0 20px" width="initial">
        <Spacer height={12} />
        <Container flexRow maxWidth={px2Rem(414)} height="fit-content">
          <Container>
            <Copy color={palette.charcoal[500]} fontSize={14} letterSpacing={-0.2}>
              Pay monthly
            </Copy>
            <Container alignBottom height="100%">
              <Copy bold color={palette.charcoal[500]} fontSize={16} letterSpacing={-0.2}>
                {formattedMonthlyPrice}
              </Copy>
            </Container>
          </Container>
          <Container>
            <Copy color={palette.charcoal[500]} fontSize={14} letterSpacing={-0.2}>
              Pay today
            </Copy>
            <Container alignBottom height="100%">
              <Copy bold color={palette.charcoal[500]} fontSize={16} letterSpacing={-0.2}>
                {formattedInitialAmount}
              </Copy>
            </Container>
          </Container>
          <Container maxWidth={px2Rem(122)} alignRight>
            <Button xsmall onClick={onSubmit}>
              Confirm
            </Button>
          </Container>
        </Container>
        <Spacer height={24} />
      </Container>
    </StyledLeaseTotalBar>
  );
};
