import BackArrow from 'components/BackArrow';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import FormErrorMessages from 'components/FormErrorMessages';
import PhoneFeatureCard from 'components/PhoneFeatureCard';
import TradeInCopy from '../TradeInCopy';
import Divider from 'elements/Divider';
import DualToneHeader from 'elements/DualToneHeader';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { secondaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledTradeInJourneyDecision } from './styles';

export const TradeInJourneyDecision = ({ dataTestId }: ITestDataId) => {
  const {
    gradeSlug,
    tradeInValues,
    productName,
    formErrors,
    tradeInExists,
    setFormSubmitted,
    setStepIndex,
    commit,
    removeTradeIn,
    tradeInCreationInProgress,
  } = useStepTradeInContext();

  const faulty = gradeSlug === 'quality-f';

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'trade in show offer',
    when: 'on page load',
  });

  const handleBottomClick = () => {
    if (tradeInExists) {
      removeTradeIn();
    } else {
      setFormSubmitted(true);
    }
  };

  return (
    <StyledTradeInJourneyDecision data-testid={dataTestId}>
      <Spacer height={40} />
      {!tradeInExists && <BackArrow onClick={() => setStepIndex(1)} />}
      <DualToneHeader>
        {faulty ? (
          <>
            It sounds like your old device is faulty. Don&apos;t worry, you can
            still earn{' '}
            <span>{tradeInValues?.recurring?.amount?.formattedValue}/mo</span>{' '}
            cashback
          </>
        ) : (
          <>
            Good news, get {tradeInValues?.total?.formattedValue} cashback over{' '}
            {tradeInValues?.recurring?.termLength} months
          </>
        )}
      </DualToneHeader>
      <Spacer height={24} />
      <Header level={4}>Here&apos;s the easy process:</Header>
      <Spacer height={24} />
      <TradeInCopy tradeInValues={tradeInValues} tradeInAssessed />
      <Divider width="100%" color={secondaryColors.c04} />
      <Spacer height={24} />
      <Header level={4}>Trade-in cashback</Header>
      <Spacer height={12} />
      <PhoneFeatureCard
        iconName="PhoneRefresh"
        details={`${tradeInValues?.recurring?.amount?.formattedValue}/mo`}
        title={productName || ''}
        detailWidth="10%"
      />
      <Spacer height={12} />
      <Divider width="100%" color={secondaryColors.c04} />
      <Spacer height={32} />
      <Header level={4}>Sound good?</Header>
      <Spacer height={24} />
      <FormErrorMessages formErrors={formErrors} />
      <DoubleButtonFullWidth
        dataTestId="tradeInConfirmationButton"
        disabled={tradeInCreationInProgress}
        topButtonText="Yes, add trade in to checkout"
        bottomButtonText="No, back to checkout"
        topButtonOnClick={commit}
        bottomButtonOnClick={handleBottomClick}
      />
    </StyledTradeInJourneyDecision>
  );
};
