import React from 'react';
import useMedia from 'use-media';
import { FadeIn } from 'components/animations/Transitions';
import Spacer from '../../../../elements/Spacer';
import { px2Rem } from '../../../../utils/px2Rem';
import { LEGACY_BREAKPOINTS } from '../../../../styles/LEGACY_BREAKPOINTS';
import {
  primaryColors,
  secondaryColors,
} from '../../../../styles/variables/colors';
import Header from '../../../../elements/Header';
import Icon from '../../../../elements/Icon';
import Button from '../../../../elements/Button';
import { ButtonTypes } from '../../../../elements/Button/types';
import IPaymentSuccessful from './PaymentOutcome.interface';
import { Card } from '../../../../components/common/Card';

export const PaymentOutcomeScreen = ({
  title,
  preContent,
  mainContent,
  subContent,
  onClick,
  buttonText,
  iconName,
}: IPaymentSuccessful) => {
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  return (
    <FadeIn>
      <div>
        {!isMobile && <Spacer height={48} />}
        <Card
          width={1200}
          styles={`
            margin: auto;
            min-height: ${px2Rem(800)};
            box-shadow: none;
            
            @media (max-width: ${LEGACY_BREAKPOINTS.mobile}px) {
              padding: ${px2Rem(36)} ${px2Rem(30)} ${px2Rem(20)} ${px2Rem(30)};
              max-width: 100%;
            `}
        >
          <div style={{ maxWidth: px2Rem(362), margin: 'auto' }}>
            <Icon
              name={iconName}
              styles={`
          width: fit-content;
          display: block;
          margin: ${px2Rem(29)} auto ${px2Rem(29)};
        `}
            />
            {preContent}
            <Spacer height={24} />
            <Header level={1} color={primaryColors.c01} fontSize={24} centered>
              {title}
            </Header>
            <Spacer height={24} />
            <div
              style={{
                padding: px2Rem(20),
                backgroundColor: secondaryColors.c05,
              }}
            >
              {mainContent}
            </div>
            {subContent}
            <Spacer height={24} />
            {onClick && buttonText && (
              <Button
                text={buttonText}
                buttonColor={ButtonTypes.b01}
                fullWidth
                size="large"
                onClick={onClick}
                dataTestId="paymentOutcomeCTA"
              />
            )}
          </div>
        </Card>
        {!isMobile && <Spacer height={256} />}
      </div>
    </FadeIn>
  );
};
