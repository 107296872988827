import { useMutation } from '@apollo/client';
import { MU_TRACKING_CHECKOUT } from '../screens/Checkout/graphQL/mutations';
import { captureErrorEvent } from './errorTracking';

export const useTrackCheckout = () => {
  const [trackCheckoutMutation] = useMutation(MU_TRACKING_CHECKOUT);

  const trackCheckout = (checkoutToken, trackingData, callback) => {
    trackCheckoutMutation({
      variables: {
        token: checkoutToken,
        inputs: trackingData,
      },
      onCompleted: ({ trackCheckout: { success, errors } }) => {
        if (!success) {
          captureErrorEvent('Error updating trackings', {
            trackings: trackingData,
            errors: errors,
          });
        }
        callback();
      },
    });
  };
  return { trackCheckout };
};
