import { CheckoutStep } from '../screens/Checkout/enums/checkoutSteps';
import { compact, concat } from 'lodash-es';
import { CheckoutMode } from '../screens/Checkout/enums/checkoutModes';
import { isMerchantCheckout } from './handleGetSessionStorageValues';
import { CheckoutBaseQueryQuery } from 'graphql/generated/graphql';
import { ConsumerTypesEnum } from 'screens/Checkout/enums/consumerTypes';

export const stepsOrder = ({
  checkout,
  checkoutMode,
  merchantHasInsuranceOption,
  showUpdatedCheckoutSteps,
  consumerType,
}: {
  checkout: CheckoutBaseQueryQuery['checkout'];
  checkoutMode: CheckoutMode;
  merchantHasInsuranceOption: boolean;
  showUpdatedCheckoutSteps: boolean;
  consumerType: ConsumerTypesEnum;
}) => {
  const showStepStripe = () => {
    return checkout?.initialPaymentMethodRequired;
  };

  const showStepTradeIn = () => {
    return !!checkout?.canAddTradeIn;
  };

  const showStepLeaseTerms = () => {
    return isMerchantCheckout();
  };
  const stepStripe = showStepStripe() ? [CheckoutStep.STEP_STRIPE] : [];

  if (checkoutMode === CheckoutMode.HIRE_AGREEMENT_ONLY) {
    return [CheckoutStep.STEP_SIGN];
  }

  if (checkoutMode === CheckoutMode.ADDITIONAL_TECH) {
    return compact(
      concat(
        showStepTradeIn() ? [CheckoutStep.STEP_TRADE_IN] : [],
        merchantHasInsuranceOption ? [CheckoutStep.STEP_INSURANCE] : [],
        [CheckoutStep.STEP_ADDRESS, CheckoutStep.STEP_MOBILE_JOB],
        stepStripe,
        [CheckoutStep.STEP_BANK, CheckoutStep.STEP_SIGN],
      ),
    );
  }
  if (checkoutMode === CheckoutMode.UPGRADE) {
    return compact(
      concat(
        [CheckoutStep.STEP_UPGRADE],

        merchantHasInsuranceOption ? [CheckoutStep.STEP_INSURANCE] : [],
        [CheckoutStep.STEP_ADDRESS, CheckoutStep.STEP_MOBILE_JOB],
        stepStripe,
        [CheckoutStep.STEP_BANK, CheckoutStep.STEP_SIGN],
      ),
    );
  }
  if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
    const isBusiness =
      showUpdatedCheckoutSteps && consumerType === ConsumerTypesEnum.BUSINESS;
    return compact(
      concat(
        showStepLeaseTerms() ? [CheckoutStep.STEP_LEASE] : [],
        showStepTradeIn() ? [CheckoutStep.STEP_TRADE_IN] : [],
        merchantHasInsuranceOption ? [CheckoutStep.STEP_INSURANCE] : [],
        [
          showUpdatedCheckoutSteps
            ? CheckoutStep.STEP_EMAIL
            : CheckoutStep.STEP_NAME_EMAIL,
          isBusiness ? CheckoutStep.STEP_LEASE_TYPE : [],
          showUpdatedCheckoutSteps ? CheckoutStep.STEP_PERSONAL_DETAILS : [],
          isBusiness ? CheckoutStep.STEP_BUSINESS_TYPE : [],
          CheckoutStep.STEP_ADDRESS,
          showUpdatedCheckoutSteps ? [] : CheckoutStep.STEP_MOBILE_JOB,
        ].flat(),
        stepStripe,
        [CheckoutStep.STEP_BANK, CheckoutStep.STEP_SIGN],
      ),
    ).flat();
  }
};
