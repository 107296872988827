import React from 'react';

import * as A from 'styles/account';
import * as T from 'styles/type';
import { primaryColors } from 'styles/variables/colors';

export const DirectDebitSummary = ({ subscription }) => {
  if (subscription && subscription.paymentInfo) {
    const { paymentInfo } = subscription;
    return (
      <A.DirectDebitDetails>
        <ul className="details-table">
          <li>
            <span>Account Name</span>
            <span className="sentry-mask">{paymentInfo.bankAccountName}</span>
          </li>

          <li>
            <span>Sort Code</span>
            <span className="sentry-mask">
              {paymentInfo.bankBranchIdentifier}
            </span>
          </li>

          <li>
            <span>Account Number</span>
            <span className="sentry-mask">{paymentInfo.bankAccountNumber}</span>
          </li>
        </ul>

        <p className="update-details">
          To update your Direct Debit details, please{' '}
          <T.RayloLink
            color={primaryColors.b01}
            onClick={() => window.Intercom('show')}
          >
            contact support
          </T.RayloLink>
        </p>
      </A.DirectDebitDetails>
    );
  } else {
    return null;
  }
};
