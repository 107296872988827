import React from 'react';
import { StyledRadioItem } from './RadioButton.styles';
import { RadioButtonProps } from './RadioButton.types';
import Copy from '../../elements/Copy';
import { palette } from '../../colors';

export const RadioButton = ({
  dataTestId,
  label,
  name,
  onChange,
  errorMessage,
  marginBottom,
  checked,
}: RadioButtonProps) => (
  <StyledRadioItem $error={!!errorMessage} data-testid={dataTestId} $marginBottom={marginBottom}>
    <input
      type="radio"
      value={label}
      onChange={onChange}
      id={label}
      name={name}
      checked={checked}
    />
    <label data-testid={`${dataTestId}-label`} htmlFor={label}>
      {label}
    </label>
    <span data-testid={`${dataTestId}-radio-circle`} className="radio-circle" />
    {errorMessage && (
      <Copy fontSize={14} lineHeight={20} color={palette.error[500]}>
        {errorMessage}
      </Copy>
    )}
  </StyledRadioItem>
);
