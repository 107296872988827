import React from 'react';

import * as A from '../../../styles/account';
import * as T from '../../../styles/type';
import dayjs from 'dayjs';
import { filter, orderBy, take } from 'lodash-es';
import { Header } from '@raylo-tech/raylopay-ui';

export const RecentPayments = ({ transactions }) => {
  const orderedTransactions = orderBy(
    filter(transactions, (o) => o.amountIncludingTax.value >= 0),
    ['happenedAt'],
    ['desc'],
  );

  const recentTransactions = take(orderedTransactions, 3);

  if (recentTransactions.length > 0) {
    return (
      <A.PaymentRow>
        {recentTransactions.map((transaction, index) => {
          return (
            <A.PaymentRowData key={`previous-transaction-${index}`}>
              <div>
                <Header level={4} fontSize={16}>
                  {transaction.description}
                </Header>
                <T.Payment>
                  {dayjs(transaction.happenedAt).format('DD/MM/YY')}
                </T.Payment>
              </div>

              <div>
                <T.Payment>
                  {transaction.amountIncludingTax.formattedValue}
                </T.Payment>
              </div>
            </A.PaymentRowData>
          );
        })}
      </A.PaymentRow>
    );
  } else {
    return null;
  }
};
