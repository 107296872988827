import { CheckoutMode } from '../Checkout/enums/checkoutModes';
import { useSharedCustomer } from '../../utils/useSharedCustomer';
import { useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { useAccountContext } from './hooks/useAccountContext';
import store from 'store';

export const RedirectToRayloUrl = ({
  checkoutContext,
}: {
  checkoutContext: CheckoutMode;
}) => {
  const { setDomainCookie, redirectToRayloUrl, removeDomainCookie } =
    useSharedCustomer();
  const { subscriptionId } = useParams<{ subscriptionId?: string }>();
  const { pathname, search } = useLocation();
  const { customer, canAddTech, canUpgrade } = useAccountContext();

  useEffect(() => {
    if (!customer) return;

    // validations
    if (
      checkoutContext === CheckoutMode.UPGRADE &&
      !canUpgrade(subscriptionId)
    ) {
      window.location.replace('/account');
      return;
    }
    if (checkoutContext === CheckoutMode.ADDITIONAL_TECH && !canAddTech) {
      window.location.replace('/account');
      return;
    }

    // set cookies and redirect
    store.remove('checkoutToken');
    removeDomainCookie('checkoutToken');
    if (subscriptionId) {
      setDomainCookie('subscriptionId', subscriptionId);
    } else {
      removeDomainCookie('subscriptionId');
    }
    setDomainCookie('checkoutContext', checkoutContext);

    const ignorePartNumber =
      checkoutContext === CheckoutMode.ADDITIONAL_TECH ? 3 : 4;
    const targetPath = pathname.split('/').slice(ignorePartNumber).join('/');

    redirectToRayloUrl(`${targetPath}${search}`);
  }, [
    customer,
    setDomainCookie,
    removeDomainCookie,
    pathname,
    search,
    redirectToRayloUrl,
    subscriptionId,
    canUpgrade,
    canAddTech,
  ]);

  return <LoadingScreen darkMode />;
};
